import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import { useEffect, useMemo, useState } from 'react';
import PreferencesBodyHeaderComponent from '../../components/outreach/preferences-body-header/preferences-body-header.component';
import STRING_KEYS from '../../../../language/keys';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import MessageSpices from '../../components/message-spices/message-spices.component';
import ToneOfVoice from '../../components/tone-of-voice/tone-of-voice.component';
import { useUpdatePositionSequencePreferencesMutation } from '../../../../store/rtk-query/apis/sequence/hooks/update-position-sequesnce-preferences.mutation-hook';
import { useCreatePositionSequencePreferencesMutation } from '../../../../store/rtk-query/apis/sequence/hooks/create-position-sequesnce-preferences.mutation-hook';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import { sequenceQuery } from '../../../../store/rtk-query/apis/sequence/sequence.toolkit-api';
import MessagePreviewSidePanel from '../../components/message-preview-side-panel/message-preview-side-panel.component';
import { ConversationPageSizeEnum, useConversationPageSize } from '../../hooks/conversations-page-size.hook';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { usePageFilters } from '../../../../hooks/use-page-filters.hooks';
import PreferencesPageContentComponent from '../../components/outreach/preferences-page-content/preferences-page-content.component';
import { OutreachPageBodyContent } from '../../components/outreach/outreach-layout/outreach.styled';
import { useCreateMessagePreviewMutation } from '../../../../store/rtk-query/apis/sequence/hooks/create-message-preview.mutation-hook';
import { useGetPositionSequencePreferencesSelector } from '../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import PageLoader from '../../components/page-loader/page-loader.component';
import AgencyPreferences from '../../components/outreach-content-preferences-sections/agency-preferences/agency-preferences.component';
import AiInstructions from '../../components/outreach-content-preferences-sections/ai-instructions/ai-instructions.component';
import MoreOptions from '../../components/outreach-content-preferences-sections/more-options/more-options.component';
import RoleSection from '../../components/outreach-content-preferences-sections/role-section/role-section.component';
import useUnsavedChanges from '../../../../hooks/unsaved-changes-hook/unsaved-changes.hooks';
import {
  ButtonsContainer,
  PreviewButton,
  SaveButton,
  TitleIcon,
} from './outreach-content-preferences.styled';
import { getMessageContent, getMessageSpices, useOutreachContentPreferencesFormik } from './outreach-content-preferences.formik';
import { OutreachContentPreferencesFormikKeys } from './outreach-content-preferences.enums';
import GenAiActivationBanner from './gen-ai-activation-banner/gen-ai-activation-banner.component';
import OutreachContentPreferencesSection
  from './outreach-content-preferences-section/outreach-content-preferences-section.component';
import OutreachContentPreferencesStatus
  from './outreach-content-preferences-status/outreach-content-preferences-status.component';

const OutreachContentPreferencesPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const { positionId } = useParams<MatchUrlParams>();
  const { data: position } = useGetPositionByIdQuery(positionId);
  const pageSize = useConversationPageSize();
  const { data: { id: accountId, displayName: accountDisplayName } } = useGetMyAccountSelector();
  const [shouldTypeMessagePreview, setShouldTypeMessagePreview] = useState(false);
  const {
    getFiltersByKey,
    isFilterExists,
    deleteFiltersByKey,
    setFilterByKey,
  } = usePageFilters();
  const showDetails = !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW).length;
  const [snackbarErrorMessage, setSnackbarErrorMessage] = useState<string | undefined>(undefined);
  const {
    data: preferences, isLoading: loadingSequencePreferences,
  } = useGetPositionSequencePreferencesSelector(positionId);
  const [getPositionSequencePreferencesLazy] = sequenceQuery.useLazyGetPositionSequencePreferencesQuery();
  const [
    createMessagePreview,
    {
      isLoading: isCreateMessagePreviewLoading,
      isError: isCreateMessagePreviewError,
    },
  ] = useCreateMessagePreviewMutation();
  const [
    updatePositionSequencePreferences,
    {
      isLoading: isUpdateSequencePreferencesLoading,
      isError: isUpdateSequencePreferencesError,
    },
  ] = useUpdatePositionSequencePreferencesMutation();
  const [
    createPositionSequencePreferences,
    {
      isSuccess: isCreateSequencePreferencesSuccess,
      isLoading: isCreateSequencePreferencesLoading,
      isError: isCreateSequencePreferencesError,
    },
  ] = useCreatePositionSequencePreferencesMutation();

  useEffect(() => {
    setShouldTypeMessagePreview(true);
  }, [isCreateMessagePreviewLoading]);

  const isSmallScreen = pageSize === ConversationPageSizeEnum.Small;
  const { contentPreferences } = preferences;
  const shouldCreatePreferences = useMemo(() => {
    if (!contentPreferences) {
      return true;
    }

    return !preferences.id;
  }, [preferences, contentPreferences]);
  const onSubmitHandler = shouldCreatePreferences ? createPositionSequencePreferences : updatePositionSequencePreferences;
  const {
    toneOfVoice,
    aboutTheCompany,
    preferredCompanyName,
    preferredJobTitle,
    preferredPositionLocation,
    aboutTheRole,
    extras,
    insiderNetwork,
    likelyhoodToMove,
    relevantSkills,
    relevantEducation,
    similarCompanies,
    agencyName,
    aboutAgency,
    agencyModeEnabled,
    hiringCompanyNameConfidential,
    messageCharactersLength,
    includeEmailSignature,
    includeEmailTracking,
    setValueByKey,
    save,
    dirty,
  } = useOutreachContentPreferencesFormik(
    {
      onSubmitHandler,
      contentPreferences,
      accountName: accountDisplayName,
      jobTitle: position.jobTitle,
    },
  );

  useUnsavedChanges({ isDirty: dirty });

  const generateMessagePreview = () => {
    createMessagePreview({
      positionId,
      accountId,
      data: {
        toneOfVoice: toneOfVoice || undefined,
        messageContent: getMessageContent({
          [OutreachContentPreferencesFormikKeys.AboutTheCompany]: aboutTheCompany,
          [OutreachContentPreferencesFormikKeys.PreferredCompanyName]: preferredCompanyName,
          [OutreachContentPreferencesFormikKeys.PreferredJobTitle]: preferredJobTitle,
          [OutreachContentPreferencesFormikKeys.PreferredPositionLocation]: preferredPositionLocation,
          [OutreachContentPreferencesFormikKeys.AboutTheRole]: aboutTheRole,
          [OutreachContentPreferencesFormikKeys.Extras]: extras,
        }),
        spices: getMessageSpices({
          [OutreachContentPreferencesFormikKeys.InsiderNetwork]: insiderNetwork,
          [OutreachContentPreferencesFormikKeys.LikelyhoodToMove]: likelyhoodToMove,
          [OutreachContentPreferencesFormikKeys.RelevantSkills]: relevantSkills,
          [OutreachContentPreferencesFormikKeys.RelevantEducation]: relevantEducation,
          [OutreachContentPreferencesFormikKeys.SimilarCompanies]: similarCompanies,
        }),
      },
    });
  };

  useEffect(() => {
    if (isCreateSequencePreferencesSuccess) {
      getPositionSequencePreferencesLazy({ positionId });
    }
  }, [getPositionSequencePreferencesLazy, isCreateSequencePreferencesSuccess, positionId]);

  useEffect(() => {
    if (isUpdateSequencePreferencesError || isCreateSequencePreferencesError || isCreateMessagePreviewError) {
      setSnackbarErrorMessage(translate(STRING_KEYS.CREATE_POSITION.ERRORS.TITLE));
    }
  }, [isUpdateSequencePreferencesError, isCreateSequencePreferencesError, isCreateMessagePreviewError, translate]);

  const onSaveButtonClick = async () => {
    await save();
  };

  const showPreviewPanel = () => {
    if (!isFilterExists(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW, 'true')) {
      setFilterByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW, 'true', false);
    } else {
      deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW);
    }
  };

  const onClosePreviewPanel = () => {
    if (isFilterExists(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW, 'true')) {
      deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_PREVIEW);
    }
  };

  const ActionButtons = (
    <ButtonsContainer>
      <>
        {isSmallScreen && (
          <PreviewButton
            variant={ PerfectButtonVariants.Outline }
            hoverVariant={ PerfectButtonHoverVariants.Grow }
            onClick={ showPreviewPanel }
          >
            { translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.MESSAGE_PREVIEW.SHOW_PREVIEW_BUTTON) }
          </PreviewButton>
        )}
      </>
      <SaveButton
        disabled={ !shouldCreatePreferences && !dirty }
        isLoading={ isUpdateSequencePreferencesLoading || isCreateSequencePreferencesLoading }
        onClick={ onSaveButtonClick }
        hoverVariant={ PerfectButtonHoverVariants.Grow }
        isActivated={ !shouldCreatePreferences }
      >
        { !shouldCreatePreferences ? translate(STRING_KEYS.SAVE) : translate(STRING_KEYS.ACTIVATE) }
      </SaveButton>
    </ButtonsContainer>
  );

  const renderBody = () => {
    if (loadingSequencePreferences) {
      return <PageLoader />;
    }

    return (
      <>
        <PreferencesBodyHeaderComponent
          title={ translate(STRING_KEYS.OUTREACH_CONTENT_PREFERENCES_PAGE.TITLE) }
          titleAdornment={ <OutreachContentPreferencesStatus isActive={ !shouldCreatePreferences } /> }
          Icon={ TitleIcon }
          ActionButtons={ ActionButtons }
        />
        <OutreachPageBodyContent disabled={ isUpdateSequencePreferencesLoading || isCreateMessagePreviewLoading }>
          { shouldCreatePreferences && (
            <OutreachContentPreferencesSection>
              <GenAiActivationBanner />
            </OutreachContentPreferencesSection>
          ) }
          <ToneOfVoice
            selectedValue={ toneOfVoice }
            changeHandler={ (value) => setValueByKey(OutreachContentPreferencesFormikKeys.ToneOfVoice, value) }
            disableDivider={ shouldCreatePreferences }
          />
          <AgencyPreferences
            agencyName={ agencyName }
            aboutAgency={ aboutAgency }
            agencyModeEnabled={ agencyModeEnabled }
            hiringCompanyName={ preferredCompanyName }
            hiringCompanyNameConfidential={ hiringCompanyNameConfidential }
            aboutHiringCompany={ aboutTheCompany }
            onChangeHandler={ setValueByKey }
          />
          <RoleSection
            preferredJobTitle={ preferredJobTitle }
            preferredPositionLocation={ preferredPositionLocation }
            aboutTheRole={ aboutTheRole }
            onChangeHandler={ setValueByKey }
          />
          <AiInstructions
            aiInstructions={ extras }
            onChangeHandler={ setValueByKey }
          />
          <MoreOptions
            positionId={ positionId }
            messageCharactersLength={ messageCharactersLength }
            includeEmailSignature={ includeEmailSignature }
            includeEmailTracking={ includeEmailTracking }
            onChangeHandler={ setValueByKey }
          />
          <MessageSpices
            insiderNetwork={ insiderNetwork }
            likelyhoodToMove={ likelyhoodToMove }
            relevantEducation={ relevantEducation }
            relevantSkills={ relevantSkills }
            similarCompanies={ similarCompanies }
            onChangeHandler={ setValueByKey }
          />
        </OutreachPageBodyContent>
      </>
    );
  };

  return (
    <>
      <PreferencesPageContentComponent>
        { renderBody() }
      </PreferencesPageContentComponent>
      <MessagePreviewSidePanel
        shouldType={ shouldTypeMessagePreview }
        isPreferencesLoading={ loadingSequencePreferences }
        isCreateMessagePreviewLoading={ isCreateMessagePreviewLoading }
        isOpen={ showDetails || !isSmallScreen }
        onClose={ onClosePreviewPanel }
        subject={ contentPreferences?.preview?.subject }
        message={ contentPreferences?.preview?.message }
        generateMessageHandler={ generateMessagePreview }
      />
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        anchor="top-center"
        description={ snackbarErrorMessage }
        isOpen={ !!snackbarErrorMessage }
        shouldAutoDismiss
        onClose={ () => setSnackbarErrorMessage(undefined) }
      />
    </>
  );
};

export default OutreachContentPreferencesPage;
