import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import {
  setUserRoleWasChanged,
  socketUserConnected, socketUserDisconnected,
} from '../../../slices/app-state/app-state.toolkit-slice';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import {
  assignUserRole,
  deleteUserById,
  fillUserInfoQuery,
  getAccountUsersQuery,
  getMyUserQuery,
  inviteColleagueQuery,
  resendInviteColleagueQuery,
} from './user.toolkit-queries';
import { REDUCER_PATH } from './user.consts';
import { UserInfoResponse } from './dto/response/user-info.response';
import { errorObjectToStringTransformer } from './user.toolkit-error-transformers';
import { UpdateUserQueryArguments } from './dto/query-arguments/update-user.query-arguments';
import { UserResponse } from './dto/response/invited-users.response';
import { ResendInviteColleagueQueryArguments } from './dto/query-arguments/resend-invite-colleague.query-arguments';
import { InviteColleagueQueryArguments } from './dto/query-arguments/invite-colleague.query-arguments';
import { AssignUserRoleQueryArguments } from './dto/query-arguments/assign-user-role.query-arguments';

export const userQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getAccountUsers'],
  endpoints: (builder) => ({
    getMyUser: builder.query<UserInfoResponse, void>({
      query: getMyUserQuery,
      async onCacheEntryAdded(
        arg,
        { cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.User,
          onSocketConnected: () => {
            dispatch(socketUserConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketUserDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.AssignRoleToUser,
            () => dispatch(setUserRoleWasChanged(true)),
          );
        }
      },
    }),
    getAccountUsers: builder.query<UserResponse[], void>({
      query: getAccountUsersQuery,
      transformErrorResponse: errorObjectToStringTransformer,
      providesTags: ['getAccountUsers'],
    }),
    inviteColleague: builder.mutation<UserResponse[], InviteColleagueQueryArguments>({
      query: inviteColleagueQuery,
      transformErrorResponse: errorObjectToStringTransformer,
    }),
    resendInviteColleague: builder.mutation<UserResponse[], ResendInviteColleagueQueryArguments>({
      query: resendInviteColleagueQuery,
    }),
    deleteUserById: builder.mutation<void, string>({
      query: deleteUserById,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Only invalidate the cache if the call was successful
          dispatch(userQuery.util.invalidateTags(['getAccountUsers']));
        } catch (error) {
          // Do nothing
        }
      },
    }),
    assignUserRole: builder.mutation<void, AssignUserRoleQueryArguments>({
      query: assignUserRole,
      async onQueryStarted(
        { userId, userRoleName }, { dispatch, queryFulfilled },
      ) {
        const patchResult = dispatch(
          userQuery.util.updateQueryData('getAccountUsers', undefined, (draft: UserResponse[]) => {
            const relevantUser = draft.find((user) => user.id === userId);
            if (relevantUser) {
              relevantUser.userRoleName = userRoleName;
            }
          }),
        );
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
    updateUser: builder.mutation<UserResponse[], UpdateUserQueryArguments>({
      query: fillUserInfoQuery,
      transformErrorResponse: errorObjectToStringTransformer,
      async onQueryStarted(
        { requestedCustomOutreachCampaign }, { dispatch, queryFulfilled },
      ) {
        let patchResult;
        if (requestedCustomOutreachCampaign !== undefined) {
          patchResult = dispatch(
            userQuery.util.updateQueryData('getMyUser', undefined, (draft) => {
              draft.requestedCustomOutreachCampaign = requestedCustomOutreachCampaign;
            }),
          );
        }
        try {
          await queryFulfilled;
        } catch {
          patchResult?.undo();
        }
      },
    }),
  }),
});
