/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from 'react';
import {
  Redirect, Route, Switch, useHistory, useLocation,
} from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { PRODUCT_AREA } from '../../consts/analytics';
import { AnalyticsProvider } from '../../contexts/analytics';
import { onboardingQuery } from '../../store/rtk-query/apis/onboarding/onboarding.toolkit-api';
import { removeInitialLoadings } from '../../store/slices/app-state/app-state.toolkit-slice';
import { useOnboardingProgress } from '../../store/slices/onboarding-state/selectors/onboarding-selectors.hooks';
import { useAppState } from '../../store/selectors';
import { LOGIN_STATE } from '../../store/slices/app-state/app-state.consts';
import { FIXED_CACHE_KEYS } from '../../store/rtk-query/apis/onboarding/onboarding.consts';
import { LetsBeginScreen } from './pages/onboarding-lets-begin-page/onboarding-lets-begin.page';
import OnboardingLayout from './components/onboarding-layout/onboarding-layout.component';
import { RegistrationScreen } from './pages/onboarding-registration-page/onboarding-registration.page';
import { ONBOARDING_ROUTES } from './consts/onboarding.const';
import OnboardingStepsLayout from './components/onboarding-steps-layout/onboarding-layout.component';
import { OnboardingExperiencePage } from './pages/onboarding-experience-page/onboarding-experience.page';
import { OnboardingEducationPage } from './pages/onboarding-education-page/onboarding-education.page';
import EmailVerificationScreen from './pages/onboarding-email-verification-page/email-verification.page';
import { OnboardingStep } from './onboarding.enums';
import { AllDoneTransitionScreen } from './pages/onboarding-all-done-transition-page/all-done-transition.page';
import { prepareOnboardingStepForNavigating } from './services/onboarding.routing-resolver';
import { OnboardingBackgroundPage } from './pages/onboarding-background-page/onboarding-background.page';

type OnboardingRouterProps = {
  requiredApisLoading: boolean,
}

const OnboardingRouter = ({ requiredApisLoading }: OnboardingRouterProps): JSX.Element | null => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { search: queryParams } = useLocation();
  const { loginState } = useAppState();
  const [disableWelcomeScreen, setDisableWelcomeScreen] = useState(false);
  const [loadOnboarding] = onboardingQuery.useLoadOnboardingMutation({ fixedCacheKey: FIXED_CACHE_KEYS.LOAD_ONBOARDING });
  const { step } = useOnboardingProgress();

  useEffect(() => {
    if (!requiredApisLoading && loginState === LOGIN_STATE.LOGGED_IN) {
      dispatch(removeInitialLoadings());
      loadOnboarding();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [requiredApisLoading, loginState]);

  useEffect(() => {
    if (step) {
      history.replace(
        prepareOnboardingStepForNavigating(step),
      );
    }
  }, [step]);

  if (requiredApisLoading) {
    return null;
  }

  return (
    <AnalyticsProvider productArea={ PRODUCT_AREA.ONBOARDING_PAGE }>
      <OnboardingLayout>
        <Switch>
          <Route path={ ONBOARDING_ROUTES.REGISTRATION } exact>
            <RegistrationScreen
              onScreenCompleted={ () => {
                loadOnboarding();
              } }
            />
          </Route>

          <Route path="/">
            <OnboardingStepsLayout
              disabledAnimation={ disableWelcomeScreen }
              setDisableWelcomeScreen={ setDisableWelcomeScreen }
            >
              <Route path={ ONBOARDING_ROUTES.LETS_BEGIN } exact>
                <LetsBeginScreen
                  disabledAnimation={ disableWelcomeScreen }
                />
              </Route>
              <Route path={ ONBOARDING_ROUTES.EXPERIENCE } exact>
                <OnboardingExperiencePage />
              </Route>
              <Route path={ ONBOARDING_ROUTES.INDUSTRIES } exact>
                <Redirect to={ ONBOARDING_ROUTES.BACKGROUND } />
              </Route>
              <Route path={ ONBOARDING_ROUTES.BACKGROUND } exact>
                <OnboardingBackgroundPage />
              </Route>
              <Route path={ ONBOARDING_ROUTES.EDUCATION } exact>
                <OnboardingEducationPage />
              </Route>
              <Route path={ ONBOARDING_ROUTES.SKILLS } exact>
                <Redirect to={ ONBOARDING_ROUTES.BACKGROUND } />
              </Route>

              <Route path={ ONBOARDING_ROUTES.EMAIL_VERIFICATION } exact>
                <EmailVerificationScreen
                  onScreenCompleted={ () => {
                    history.push(
                      prepareOnboardingStepForNavigating(OnboardingStep.OnboardingCompleted),
                    );
                  } }
                />
              </Route>

              <Route path={ ONBOARDING_ROUTES.ONBOARDING_COMPLETED } exact>
                <AllDoneTransitionScreen />
              </Route>
            </OnboardingStepsLayout>
          </Route>

          <Route path="*">
            {/* keep query params while navigating */}
            <Redirect to={ `${ONBOARDING_ROUTES.REGISTRATION}${queryParams}` } />
          </Route>
        </Switch>
      </OnboardingLayout>
    </AnalyticsProvider>
  );
};

export default OnboardingRouter;
