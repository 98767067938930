import styled from 'styled-components';
import { ASSETS } from '../../../../../../../assets';
import { THEME } from '../../../../../../../themes';
import { hexToRgbA } from '../../../../../../../utils/colors';
import BaseMenu from '../../../../../../../components/Common/perfect-base-menu/perfect-base-menu.component';
import { Body1Font, HeadlineFont } from '../../../../../../../themes/fonts';
import MenuItem from '../../../../../../../components/Common/perfect-base-menu/menu-item/menu-item.component';

export const Container = styled.span`
  display: inline-flex;
  align-items: center;
  cursor: pointer;
`;

export const Text = styled.div<{ isMenuOpen: boolean }>`
  color: ${THEME.COLORS.ACCENT};
  color: ${({ isMenuOpen }) => isMenuOpen ? hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.LOW) : THEME.COLORS.ACCENT};
`;

export const ArrowDownIcon = styled(ASSETS.ARROW_FILLED_DOWN)`
  width: 24px;
  height: 24px;
  fill: ${THEME.COLORS.ACCENT};
`;

export const Menu = styled(BaseMenu)`
  width: 416px;

  & li {
    height: fit-content;
    max-width: unset;
  }
`;

export const MenuTitle = styled.div`
  ${HeadlineFont};
  height: 48px;
  display: flex;
  align-items: center;
  text-transform: capitalize;
`;

export const StyledMenuItem = styled(MenuItem)`
  height: 30px;
  padding: ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.BASE} ${THEME.SPACING.XLOOSE};

  :hover,
  :focus {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.GHOST)};
    color: inherit;
  }
  
`;

export const MenuItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${THEME.SPACING.MICRO};
`;

export const MenuItemTitle = styled.div`
  ${Body1Font};
  color: ${THEME.COLORS.CONTENT_A};
  font-weight: 600;
`;

export const MenuItemDescription = styled.div`
  font-weight: normal;
  line-height: normal;
  color: ${THEME.COLORS.PRIMARY_DARK};
  opacity: ${THEME.OPACITY.MED};
`;
