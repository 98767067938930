import { QueryMethods } from '../../enums/query-methods.enums';
import {
  PostQuery, GetQuery, PutQuery, DeleteQuery, PatchQuery,
} from '../../@types/rtk-query';
import { ROUTES } from './user.consts';
import { InviteColleagueRequest } from './dto/request/invite-colleaque.request';
import { ResendInviteColleagueQueryArguments } from './dto/query-arguments/resend-invite-colleague.query-arguments';
import { FillUserInfoRequest } from './dto/request/fill-user-info.request';
import { UpdateUserQueryArguments } from './dto/query-arguments/update-user.query-arguments';
import { InviteColleagueQueryArguments } from './dto/query-arguments/invite-colleague.query-arguments';
import { AssignUserRoleQueryArguments } from './dto/query-arguments/assign-user-role.query-arguments';
import { ResendInviteColleagueRequest } from './dto/request/resend-invite-colleague.request';

export const getMyUserQuery = (): GetQuery => ({
  url: ROUTES.USER_INFO,
  method: QueryMethods.Get,
});

export const getAccountUsersQuery = (): GetQuery => ({
  url: ROUTES.USERS,
  method: QueryMethods.Get,
});

export const inviteColleagueQuery = (queryArguments: InviteColleagueQueryArguments): PostQuery<InviteColleagueRequest> => ({
  url: ROUTES.USER_INVITE,
  method: QueryMethods.Post,
  data: {
    email: queryArguments.email,
    userRoleName: queryArguments.userRoleName,
  },
});

export const assignUserRole = (queryArguments: AssignUserRoleQueryArguments): PatchQuery<AssignUserRoleQueryArguments> => ({
  url: ROUTES.USER_ROLES,
  method: QueryMethods.Patch,
  data: queryArguments,
});

export const deleteUserById = (userId: string): DeleteQuery => ({
  url: `${ROUTES.USERS}/${userId}`,
  method: QueryMethods.Delete,
});

export const resendInviteColleagueQuery = ({ userId, email }: ResendInviteColleagueQueryArguments): PostQuery<ResendInviteColleagueRequest> => ({
  url: ROUTES.USER_INVITE_RESEND,
  method: QueryMethods.Post,
  data: {
    invitedUserId: userId,
    invitedEmail: email,
  },
});

export const fillUserInfoQuery = ({
  firstName,
  lastName,
  currentRole,
  requestedCustomOutreachCampaign,
}: UpdateUserQueryArguments): PutQuery<FillUserInfoRequest> => ({
  url: ROUTES.USERS,
  method: QueryMethods.Put,
  data: {
    firstName,
    lastName,
    role: currentRole,
    requestedCustomOutreachCampaign,
  },
});
