import { useTranslation } from 'react-i18next';
import { useMemo } from 'react';
import Dialog from '../base-dialog/base-dialog.component';
import StripePricingTable from '../../Common/stripe-pricing-table/stripe-pricing-table.component';
import STRING_KEYS from '../../../language/keys';
import { CONFIG } from '../../../config';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import {
  Container, Description, StyledBody, Subtitle, TextContainer, Title,
} from './upgrade-plan-dialog.styled';
import { UpgradePlanDialogProps } from './upgrade-plan-dialog';
import { UpgradePlanDialogVariation } from './upgrade-plan-dialog.enums';

export const UpgradePlanDialog = ({
  onCloseDialog,
  isDialogOpen,
  variation,
}: UpgradePlanDialogProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: { id: userId } } = useMyUserSelector();

  const [subtitle, title, description] = useMemo(() => {
    switch (variation) {
      case UpgradePlanDialogVariation.TrialEnded:
        return [
          STRING_KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.SUBTITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.TITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.TRIAL_ENDED.DESCRIPTION,
        ];
      case UpgradePlanDialogVariation.MatchDailyLimit:
        return [
          STRING_KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.SUBTITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.TITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.MATCH_DAILY_LIMIT.DESCRIPTION,
        ];
      case UpgradePlanDialogVariation.ExceedPositionLimit:
        return [
          STRING_KEYS.UPGRADE_PLAN_DIALOG.EXCEED_POSITION_LIMIT.SUBTITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.TITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.DESCRIPTION,
        ];
      case UpgradePlanDialogVariation.NeedMoreMatches:
        return [
          STRING_KEYS.UPGRADE_PLAN_DIALOG.NEED_MORE_MATCHES.SUBTITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.TITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.DESCRIPTION,
        ];
      default:
        return [
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.SUBTITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.TITLE,
          STRING_KEYS.UPGRADE_PLAN_DIALOG.DEFAULT.DESCRIPTION,
        ];
    }
  }, [variation]);

  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialog }
      widthSize={ DialogWidthSize.XXLarge }
      disableCloseButton={ variation === UpgradePlanDialogVariation.TrialEnded }
    >
      <StyledBody>
        <Container>
          <TextContainer>
            <Subtitle>{ translate(subtitle) }</Subtitle>
            <Title>{ translate(title) }</Title>
            <Description>{ translate(description) }</Description>
          </TextContainer>
          <StripePricingTable
            userId={ userId }
            pricingTableId={ CONFIG.STRIPE_PRICING_TABLE_ID }
            publishableKey={ CONFIG.STRIPE_PRICING_TABLE_PUBLISHABLE_KEY }
          />
        </Container>
      </StyledBody>
    </Dialog>
  );
};
