import { useRef, useState } from 'react';
import { PRIVACY_CLASS } from '../../../consts/fullstory';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import StyledAvatar from '../styled-avatar/styled-avatar.component';
import UserProfileMenu from './user-profile-menu/user-profile-menu.component';
import {
  Container,
  HamburgerIcon,
} from './user-profile.styled';
import { UserProfileProps } from './user-profile';

const UserProfile = ({ onPeopleItemClickHandler }: UserProfileProps): JSX.Element => {
  const { data: { firstName, profileImage, lastName } } = useMyUserSelector();

  const [userProfileAnchorElement, setUserProfileAnchorElement] = useState(null);
  const actionButtonRef = useRef(null);
  const onClickOpenUserProfileMenu = () => userProfileAnchorElement ? setUserProfileAnchorElement(null) : setUserProfileAnchorElement(actionButtonRef.current);
  const handleMenuClose = () => setUserProfileAnchorElement(null);

  return (
    <Container
      ref={ actionButtonRef }
      isShowUserMenu={ !!userProfileAnchorElement }
      onClick={ onClickOpenUserProfileMenu }
    >
      <HamburgerIcon />
      <StyledAvatar
        className={ PRIVACY_CLASS.EXCLUDE }
        name={ `${firstName}-${lastName}` }
        initialsFontSizePx={ 16 }
        image={ profileImage }
        borderWidthPx={ 0 }
        width={ 32 }
        height={ 32 }
      />
      <UserProfileMenu
        anchorElement={ userProfileAnchorElement || undefined }
        onCloseButtonClickHandler={ handleMenuClose }
        onPeopleItemClickHandler={ onPeopleItemClickHandler }
      />
    </Container>
  );
};

export default UserProfile;
