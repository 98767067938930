import styled, { css } from 'styled-components';
import { THEME } from '../../../../themes';
import { ASSETS } from '../../../../assets';
import { hexToRgbA } from '../../../../utils/colors';

export const HEADER_HEIGHT = 80;

export const HeaderContainer = styled.div`
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: ${THEME.Z_INDEX.HIGHEST};
`;

export const HeaderContentContainer = styled.div<{ isHeaderSticky?: boolean }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  height: ${HEADER_HEIGHT}px;
  align-items: center;
  justify-content: space-between;
  z-index: 120;
  background-color: ${({ isHeaderSticky }) => isHeaderSticky ? hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.MED) : undefined};
  backdrop-filter: ${({ isHeaderSticky }) => isHeaderSticky ? 'blur(20px)saturate(180%)' : undefined};
`;

export const PerfectIcon = styled(ASSETS.PERFECT_MARK)`
  width: 30px;
  height: 19px;
  fill: ${THEME.COLORS.ACCENT};
`;

const SideContainer = css`
  width: 100px;
  height: ${HEADER_HEIGHT}px;
  display: flex;
  align-items: center;
`;

export const LeftContainer = styled.div`
  ${SideContainer};
  justify-content: left;
  padding-left: ${THEME.SPACING.MEDIUM};
`;

export const RightContainer = styled.div`
  ${SideContainer};
  justify-content: right;
  padding-right: ${THEME.SPACING.MEDIUM};
  display: flex;
  gap: ${THEME.SPACING.BASE};
`;
