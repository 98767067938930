import { useEffect, useMemo, useState } from 'react';
import { isEqual } from 'lodash';
import { useParams } from 'react-router';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useEditPositionPreferencesFormik } from '../../../../components/dialogs/edit-position-dialog/edit-position-dialog.formik';
import { PositionRunTriggerTypeEnum } from '../../../../enums/position-run-trigger-type.enum';
import { useUpdatePositionMutation } from '../../../../store/rtk-query/apis/position/hooks/update-position.mutation-hook';
import { EditPositionPreferencesFormikKeys } from '../../../../components/dialogs/edit-position-dialog/edit-position-dialog.enums';
import { positionQuery } from '../../../../store/rtk-query/apis/position/position.toolkit-api';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import JobTitleStep from '../../components/match-tune/steps/job-title-step/job-title-step.component';
import SkillsStep from '../../components/match-tune/steps/skills-step/skills-step.component';
import IndustryExperienceStep
  from '../../components/match-tune/steps/industry-experience-step/industry-experience-step.component';
import CompanySizeStep from '../../components/match-tune/steps/company-size-step/company-size-step.component';
import LevelOfExperienceStep
  from '../../components/match-tune/steps/level-of-experience-step/level-of-experience-step.component';
import PositionFeedbackStep
  from '../../components/match-tune/steps/position-feedback-step/position-feedback-step.component';
import LocationsStep from '../../components/match-tune/steps/locations-step/locations-step.container';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PAGE_TITLES } from '../../../../consts';
import { setSnackbar } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import MatchTuneMainScreen from '../../components/match-tune/match-tune-main-screen/match-tune-main-screen.component';
import STRING_KEYS from '../../../../language/keys';
import {
  AnchorDirectionsEnum,
  AppSnackbarVariant,
} from '../../../../components/Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import EducationStep from '../../components/match-tune/steps/education-step/education-step.component';
import { MatchTuneStep } from './match-tune.enums';
import { PageContainer } from './match-tune.styled';

const getPageName = (hasReachedMatchTuneThreshold?: boolean) => {
  return hasReachedMatchTuneThreshold ? PAGE_NAMES.MATCH_TUNE : PAGE_NAMES.MATCH_TUNE_MANUAL;
};

const MatchTunePage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { positionId } = useParams<MatchUrlParams>();
  const [step, setStep] = useState<MatchTuneStep>();
  const { analyticsPageEvent, analyticsTrackEvent } = useAnalyticsContext();
  const { data: position } = useGetPositionByIdQuery(positionId);
  const [updatePosition, { isLoading: isEditPositionLoading, isSuccess: isEditPositionSuccess, isError: isEditPositionError }] = useUpdatePositionMutation();
  const [getPosition] = positionQuery.useLazyGetPositionByIdQuery();
  const [getPositions] = positionQuery.useLazyGetOpenPositionsQuery();
  const [touchedFields, setTouchedFields] = useState(new Map<EditPositionPreferencesFormikKeys, boolean>());
  const [isErrorSnackbarOpen, setIsErrorSnackbarOpen]  = useState(false);

  // Save the field so it wont update from store
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const hasReachedMatchTuneThreshold = useMemo(() => position.hasReachedMatchTuneThreshold, [position.id]);

  useEffect(() => {
    if (position.id) {
      document.title = `${position.jobTitle}${PAGE_TITLES.CUSTOM_PAGE_SUFFIX}`;
      analyticsPageEvent({
        eventName: ANALYTICS_EVENTS.MATCH_TUNE_PAGE_VIEWED,
        eventGroup: EVENT_GROUP.PAGE_VIEW,
        pageName: getPageName(position.hasReachedMatchTuneThreshold),
        productArea: PRODUCT_AREA.MATCH_PAGE,
        pagePositionId: positionId,
        pagePositionName: position.jobTitle,
        pagePositionStatus: position.status,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [position.id]);

  const {
    jobTitle,
    prioritizedTitles,
    excludedTitles,
    locations,
    remotePosition,
    levelsOfExperience,
    overallLevelsOfExperience,
    companyBackground,
    fieldsOfStudy,
    degrees,
    companySizes,
    skills,
    setValueByKey,
    save,
    dirty,
    isValid,
    getFieldMeta,
  } = useEditPositionPreferencesFormik({
    submitHandler: updatePosition,
    position,
    enableReinitialize: true,
    triggerType: hasReachedMatchTuneThreshold ? PositionRunTriggerTypeEnum.MatchTune : PositionRunTriggerTypeEnum.ManualUpdate,
  });

  useEffect(() => {
    if (isEditPositionError) {
      setIsErrorSnackbarOpen(true);
    }
  }, [isEditPositionError]);

  const isTouched = (...keys: EditPositionPreferencesFormikKeys[]) => {
    for (let i = 0; i < keys.length; i++) {
      if (touchedFields.get(keys[i])) {
        return true;
      }
    }

    return false;
  };

  useEffect(() => {
    if (isEditPositionSuccess) {
      Promise.all([getPosition(position.id), getPositions()]);
      history.push(AppRouting.buildMatchesPageUrl(positionId));
      // closeHandler?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isEditPositionSuccess]);

  const onContinueButtonClick = async () => {
    await save();

    const payload = {
      eventName: ANALYTICS_EVENTS.MATCH_TUNE_SAVE_BUTTON_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: getPageName(position.hasReachedMatchTuneThreshold),
      pagePositionId: position.id,
      pagePositionName: position.jobTitle,
      pagePositionStatus: position.status,
    };
    analyticsTrackEvent(payload);
  };

  const renderMainScreen = () => {
    return (
      <MatchTuneMainScreen
        positionId={ positionId }
        hasReachedMatchTuneThreshold={ !!hasReachedMatchTuneThreshold }
        cardClickHandler={ setStep }
        isTouched={ isTouched }
        continueButtonClickHandler={ onContinueButtonClick }
        isContinueButtonDisabled={ !dirty || !isValid }
        isLoading={ isEditPositionLoading }
      />
    );
  };

  const updateTouchedFields = (key: EditPositionPreferencesFormikKeys, touched: boolean) => {
    setTouchedFields((prevValue) => {
      const clonedMap = new Map(prevValue);
      clonedMap.set(key, touched);

      return clonedMap;
    });
  };

  const onChange = <T, >(key: EditPositionPreferencesFormikKeys, changedData: T) => {
    setValueByKey(key, changedData);
    const { initialValue } = getFieldMeta(key);
    const isChanged = !isEqual(changedData, initialValue);
    updateTouchedFields(key, isChanged);
    setStep(undefined);
  };

  const renderLocationsStep = () => {
    return (
      <LocationsStep
        locations={ locations }
        remotePosition={ remotePosition }
        changeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.Locations, changedData)
        }
        remotePositionChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.RemotePosition, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderLevelOfExperienceStep = () => {
    return (
      <LevelOfExperienceStep
        selectedRelevantLevelsOfExperience={ levelsOfExperience }
        selectedOverallLevelsOfExperience={ overallLevelsOfExperience }
        relevantLevelsOfExperienceChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.LevelsOfExperience, changedData)
        }
        overallLevelsOfExperienceChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.OverallLevelsOfExperience, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderIndustryExperienceStep = () => {
    return (
      <IndustryExperienceStep
        companyBackground={ companyBackground }
        changeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.CompanyBackground, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderCompanySizeStep = () => {
    return (
      <CompanySizeStep
        selectedCompanySizes={ companySizes }
        changeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.CompanySizes, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderSkillsStep = () => {
    return (
      <SkillsStep
        skills={ skills }
        changeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.Skills, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderJobTitleStep = () => {
    return (
      <JobTitleStep
        jobTitle={ jobTitle }
        positionId={ positionId }
        prioritizedTitles={ prioritizedTitles }
        excludedTitles={ excludedTitles }
        jobTitleChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.JobTitle, changedData)
        }
        prioritizedTitlesChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.PrioritizedTitles, changedData)
        }
        excludedTitlesChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.ExcludedTitles, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderEducationStep = () => {
    return (
      <EducationStep
        selectedDegrees={ degrees }
        selectedFieldsOfStudies={ fieldsOfStudy }
        degreesChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.Degrees, changedData)
        }
        fieldsOfStudiesChangeHandler={
          (changedData) => onChange(EditPositionPreferencesFormikKeys.FieldsOfStudy, changedData)
        }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const onPositionFeedbackComplete = () => {
    dispatch(setSnackbar({
      title: translate(STRING_KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUCCESS_SNACKBAR_TITLE),
      description: translate(STRING_KEYS.MATCH_TUNE.POSITION_FEEDBACK_STEP.SUCCESS_SNACKBAR_DESCRIPTION),
      variant: AppSnackbarVariant.Success,
      anchor: AnchorDirectionsEnum.BottomCenter,
    }));

    if (dirty) {
      setStep(undefined);
    } else {
      history.push(AppRouting.buildMatchesPageUrl(positionId));
    }
  };

  const renderPositionFeedbackStep = () => {
    return (
      <PositionFeedbackStep
        positionId={ position.id }
        completeStepHandler={ onPositionFeedbackComplete }
        backButtonClickHandler={ () => setStep(undefined) }
      />
    );
  };

  const renderContent = () => {
    switch (step) {
      case MatchTuneStep.Locations:
        return renderLocationsStep();
      case MatchTuneStep.LevelsOfExperience:
        return renderLevelOfExperienceStep();
      case MatchTuneStep.IndustryExperience:
        return renderIndustryExperienceStep();
      case MatchTuneStep.CompanySize:
        return renderCompanySizeStep();
      case MatchTuneStep.Skills:
        return renderSkillsStep();
      case MatchTuneStep.JobTitle:
        return renderJobTitleStep();
      case MatchTuneStep.Education:
        return renderEducationStep();
      case MatchTuneStep.PositionFeedback:
        return renderPositionFeedbackStep();
      default:
        return renderMainScreen();
    }
  };

  return (
    <>
      <PageContainer>
        { renderContent() }
      </PageContainer>
      <AlertSnackBar
        title={ translate(STRING_KEYS.ALERT) }
        description={ translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE) }
        isOpen={ isErrorSnackbarOpen }
        shouldAutoDismiss
        onClose={ () => setIsErrorSnackbarOpen(false) }
      />
    </>
  );
};

export default MatchTunePage;
