import { useTranslation } from 'react-i18next';
import { PerfectButtonVariants } from '../Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../language/keys';
import {
  BellIcon,
  Container,
  ContentContainer,
  Description,
  RefreshButton,
  TextContainer,
  Title,
} from './user-role-changed-banner.styled';

const UserRoleChangedBanner = (): JSX.Element => {
  const { t: translate } = useTranslation();

  const onRefreshButtonClick = () => {
    window.location.reload();
  };

  return (
    <Container>
      <ContentContainer>
        <TextContainer>
          <BellIcon />
          <Title>{ translate(STRING_KEYS.USER_ROLE_CHANGED_BANNER.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.USER_ROLE_CHANGED_BANNER.DESCRIPTION) }</Description>
        </TextContainer>
        <RefreshButton
          variant={ PerfectButtonVariants.Link }
          onClick={ onRefreshButtonClick }
        >
          { translate(STRING_KEYS.USER_ROLE_CHANGED_BANNER.BUTTON) }
        </RefreshButton>
      </ContentContainer>
    </Container>
  );
};

export default UserRoleChangedBanner;
