import { HEADER_HEIGHT } from '../../../../../components/Common/headers/header/header.styled';
import { LARGE_BANNER_HEIGHT_PX } from '../outreach-banner/outreach-banner.styled';
import { THEME } from '../../../../../themes';
import {
  BANNER_HEIGHT as GLOBAL_BANNER_HEIGHT,
} from '../../../../../components/user-role-changed-banner/user-role-changed-banner.styled';

export const calculateOutreachPageHeight = (
  { isBannerVisible, isGlobalBannerVisible }: { isBannerVisible: boolean, isGlobalBannerVisible: boolean },
): string => {
  let additionalHeightSize = isBannerVisible ? LARGE_BANNER_HEIGHT_PX : 0;
  const additionalPaddingSize = isBannerVisible ? THEME.SPACING.BASE : 0;

  if (isGlobalBannerVisible) {
    additionalHeightSize += GLOBAL_BANNER_HEIGHT;
  }

  return `calc(100vh - ${HEADER_HEIGHT + additionalHeightSize}px ${additionalPaddingSize ? `- ${additionalPaddingSize}` : ''} - ${THEME.SPACING.TINY})`;
};
