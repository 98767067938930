import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useUpdatePositionUserAssignMutation,
} from '../../../../store/rtk-query/apis/position/hooks/update-position-user-assign.mutation-hook';
import UserPicker from '../../../../components/Common/user-picker/user-picker.component';
import STRING_KEYS from '../../../../language/keys';
import {
  AssignUserRequestTypeEnum,
} from '../../../../store/rtk-query/apis/position/enums/assign-user-request-type.enum';
import { VerticalDivider } from '../../pages/position-overview-page/position-overview.styled';
import { AVATAR_SIZE_PX } from '../../../../components/Common/user-picker/user-picker.styled';
import { UserPickersContainerProps } from './user-pickers-container';
import { Container } from './user-pickers-container.styled';

const UserPickersContainer = ({
  position,
  openInviteUsersModalHandler,
  showAddUserIcon,
  maxDisplayAvatars,
}: UserPickersContainerProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const [updatePositionUserRoles] = useUpdatePositionUserAssignMutation();

  const handleUserChange = (
    toAdd: string[],
    toRemove: string[],
    userType: 'recruiters' | 'hiringManagers',
  ) => {
    if (toAdd.length > 0) {
      updatePositionUserRoles({
        requestType: AssignUserRequestTypeEnum.Assign,
        positionId: position.id,
        [userType]: toAdd.map((userId) => ({ userId })),
      });
    }
    if (toRemove.length > 0) {
      updatePositionUserRoles({
        requestType: AssignUserRequestTypeEnum.UnAssign,
        positionId: position.id,
        [userType]: toRemove.map((userId) => ({ userId })),
      });
    }
  };

  const onRecruitersChange = (toAdd: string[], toRemove: string[]) => {
    handleUserChange(toAdd, toRemove, 'recruiters');
  };

  const onHiringManagersChange = (toAdd: string[], toRemove: string[]) => {
    handleUserChange(toAdd, toRemove, 'hiringManagers');
  };

  const userPickerRecruiters = useMemo(() => position?.recruiters?.map((user) => user.userId) || [], [position?.recruiters]);
  const userPickerHiringManagers = useMemo(() => position?.hiringManagers?.map((user) => user.userId) || [], [position?.hiringManagers]);

  return (
    <Container>
      <UserPicker
        label={ translate(STRING_KEYS.USER_PICKER.RECRUITER) }
        menuTitle={ translate(STRING_KEYS.USER_PICKER.RECRUITER_MENU_TITLE) }
        selectedUsers={ userPickerRecruiters }
        changeHandler={ onRecruitersChange }
        openInviteUsersModalHandler={ openInviteUsersModalHandler }
        showAddUserIcon={ showAddUserIcon }
        maxDisplayAvatars={ maxDisplayAvatars }
      />

      <VerticalDivider $height={ AVATAR_SIZE_PX } />

      <UserPicker
        label={ translate(STRING_KEYS.USER_PICKER.HIRING_MANAGER) }
        menuTitle={ translate(STRING_KEYS.USER_PICKER.HIRING_MANAGER_MENU_TITLE) }
        selectedUsers={ userPickerHiringManagers }
        changeHandler={ onHiringManagersChange }
        openInviteUsersModalHandler={ openInviteUsersModalHandler }
        showAddUserIcon={ showAddUserIcon }
        maxDisplayAvatars={ maxDisplayAvatars }
      />
    </Container>
  );
};

export default UserPickersContainer;
