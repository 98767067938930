import { createSlice } from '@reduxjs/toolkit';
import { accountQuery } from '../../rtk-query/apis/account/account.toolkit-api';
import { userQuery } from '../../rtk-query/apis/user/user.toolkit-api';
import { positionQuery } from '../../rtk-query/apis/position/position.toolkit-api';
import { onboardingQuery } from '../../rtk-query/apis/onboarding/onboarding.toolkit-api';
import { initialAppState } from './app-state.initial-state';
import { logoutReducer } from './reducers/logout.reducers';
import { setReferrerAndViewIdReducer } from './reducers/referrer-and-view-id.reducers';
import { setFatalErrorReducer } from './reducers/global-error.reducers';
import {
  prepareSubDomainReducer,
  setSubdomainTypeReducer,
  setVerifiedUserSubdomainTypeReducer,
} from './reducers/subdomain.reducers';
import {
  removeInitialLoadingReducer,
  setFronteggLoadingCompletedReducer,
  setPositionsLoadingCompletedReducer,
  setRudderstackLoadingCompletedReducer,
} from './reducers/loadings.reducers';
import {
  getAccountByIdFulfilledExtraReducer,
  getAccountByIdRejectExtraReducer,
} from './extra-reducers/accounts.reducers';
import { getPositionsFulfilledExtraReducer, getPositionsRejectExtraReducer } from './extra-reducers/positions.reducers';
import { getMyUserFulfilledExtraReducer, getMyUserPendingExtraReducer } from './extra-reducers/users.reducers';
import { executeSessionCommandsReducer } from './reducers/execute-session-commands.reducers';
import { setUserIdentifyMethodReducer, setUserJustSignedUpReducer } from './reducers/set-user-identify-method.reducers';
import { setIsOnboardingLogoutReducer } from './reducers/set-onboarding-logout.reducers';
import { loadOnboardingFulfilledExtraReducer } from './extra-reducers/onboarding.reducers';
import {
  socketOutreachConnectedReducer,
  socketOutreachDisconnectedReducer,
  socketTalentAcquisitionConnectedReducer,
  socketTalentAcquisitionDisconnectedReducer,
  socketUserConnectedReducer,
  socketUserDisconnectedReducer,
} from './reducers/socket.reducers';
import { deleteSnackbarReducer, setSnackbarReducer } from './reducers/snackbar.reducers';
import { setLatestAppVersionReducer } from './reducers/app-version.reducers';
import { disableFlashingFaviconReducer, enableFlashingFaviconReducer } from './reducers/favicon.reducers';
import { disableIsUnsavedChangesReducer, enableIsUnsavedChangesReducer } from './reducers/unsaved-changes.reducers';
import { setUserRoleWasChangedReducer } from './reducers/user-role.reducers';

const AppStateSlice = createSlice({
  name: 'app-state',
  initialState: initialAppState,
  reducers: {
    logout: logoutReducer,
    setReferrerAndViewId: setReferrerAndViewIdReducer,
    setFatalError: setFatalErrorReducer,
    setVerifiedUserSubdomainType: setVerifiedUserSubdomainTypeReducer,
    removeInitialLoadings: removeInitialLoadingReducer,
    setSubdomainType: setSubdomainTypeReducer,
    setRudderstackLoadingCompleted: setRudderstackLoadingCompletedReducer,
    setFronteggLoadingCompleted: setFronteggLoadingCompletedReducer,
    setPositionsLoadingCompleted: setPositionsLoadingCompletedReducer,
    prepareSubDomainData: prepareSubDomainReducer,
    executeSessionCommands: executeSessionCommandsReducer,
    setUserIdentifyMethod: setUserIdentifyMethodReducer,
    setUserJustSignedUp: setUserJustSignedUpReducer,
    setIsOnboardingLogout: setIsOnboardingLogoutReducer,
    socketTalentAcquisitionConnected: socketTalentAcquisitionConnectedReducer,
    socketTalentAcquisitionDisconnected: socketTalentAcquisitionDisconnectedReducer,
    socketOutreachConnected: socketOutreachConnectedReducer,
    socketOutreachDisconnected: socketOutreachDisconnectedReducer,
    socketUserConnected: socketUserConnectedReducer,
    socketUserDisconnected: socketUserDisconnectedReducer,
    setSnackbar: setSnackbarReducer,
    deleteSnackbar: deleteSnackbarReducer,
    setLatestAppVersion: setLatestAppVersionReducer,
    enableFlashingFavicon: enableFlashingFaviconReducer,
    disableFlashingFavicon: disableFlashingFaviconReducer,
    enableIsUnsavedChanges: enableIsUnsavedChangesReducer,
    disableIsUnsavedChanges: disableIsUnsavedChangesReducer,
    setUserRoleWasChanged: setUserRoleWasChangedReducer,
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(onboardingQuery.endpoints.loadOnboarding.matchFulfilled, loadOnboardingFulfilledExtraReducer)
      .addMatcher(accountQuery.endpoints.getAccountById.matchRejected, getAccountByIdRejectExtraReducer)
      .addMatcher(accountQuery.endpoints.getAccountById.matchFulfilled, getAccountByIdFulfilledExtraReducer)
      .addMatcher(positionQuery.endpoints.getOpenPositions.matchFulfilled, getPositionsFulfilledExtraReducer)
      .addMatcher(positionQuery.endpoints.getOpenPositions.matchRejected, getPositionsRejectExtraReducer)
      .addMatcher(userQuery.endpoints.getMyUser.matchFulfilled, getMyUserFulfilledExtraReducer)
      .addMatcher(userQuery.endpoints.getMyUser.matchPending, getMyUserPendingExtraReducer);
  },
});

export const {
  setUserRoleWasChanged,
  setLatestAppVersion,
  socketTalentAcquisitionConnected,
  socketTalentAcquisitionDisconnected,
  socketOutreachConnected,
  socketOutreachDisconnected,
  socketUserConnected,
  socketUserDisconnected,
  setIsOnboardingLogout,
  logout,
  setFatalError,
  setReferrerAndViewId,
  setSubdomainType,
  setVerifiedUserSubdomainType,
  setRudderstackLoadingCompleted,
  prepareSubDomainData,
  setFronteggLoadingCompleted,
  removeInitialLoadings,
  executeSessionCommands,
  setPositionsLoadingCompleted,
  setUserIdentifyMethod,
  setUserJustSignedUp,
  setSnackbar,
  deleteSnackbar,
  enableFlashingFavicon,
  disableFlashingFavicon,
  enableIsUnsavedChanges,
  disableIsUnsavedChanges,
} = AppStateSlice.actions;

export default AppStateSlice.reducer;
