import { userQuery } from '../user.toolkit-api';
import { UserResponse } from '../dto/response/invited-users.response';
import { InviteColleagueQueryArguments } from '../dto/query-arguments/invite-colleague.query-arguments';

type UseFillUserInfoResponseType = [
  (queryArguments: InviteColleagueQueryArguments) => Promise<UserResponse[]>,
  AppSelectorType<UserResponse[], string>
];

export const useInviteUserMutation = (): UseFillUserInfoResponseType => {
  const [
    inviteUser,
    {
      data, isLoading, isError, error, isSuccess,
    }] = userQuery.useInviteColleagueMutation();

  const meta = {
    isLoading, isError, error: error as string, isSuccess, data: data || [] as UserResponse[],
  };

  const inviteUserWithUnwrap = (queryArguments: InviteColleagueQueryArguments): Promise<UserResponse[]> => {
    return inviteUser(queryArguments).unwrap();
  };

  return [
    inviteUserWithUnwrap,
    { ...meta },
  ];
};
