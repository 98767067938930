import styled, { keyframes, css } from 'styled-components';
import { THEME } from '../../../../themes';
import { Body2Font, ButtonFont } from '../../../../themes/fonts';

const fadeIn = (position:AnchorPosition) => keyframes`
  from {
    ${position.top ? 'top: 0px' : 'bottom: 0px'};
    opacity: 0;
  }
  to {
    ${position.top ? 'top: 10px' : 'bottom: 55px'};
    opacity: 1;
  }
`;

const fadeOut = (position:AnchorPosition) => keyframes`
  from {
    ${position.top ? 'top: 10px' : 'bottom: 55px'};
    opacity: 1;
  }
  to {
    ${position.top ? 'top: 0px' : 'bottom: 0px'};
    opacity: 0;
  }
`;

const animationOpen = (position: AnchorPosition) => css`
  animation: ${fadeIn(position)} 0.5s both;
`;

const animationClose = (position: AnchorPosition) => css`
  animation: ${fadeOut(position)} 0.5s cubic-bezier(.25,.46,.45,.94) both;
`;

type SnackBarContainerProps = {
  backgroundColor?: string,
  isOpen?: boolean,
  isClose?: boolean,
  position: AnchorPosition,
}

export const SnackBarContainer = styled.div<SnackBarContainerProps>`
  max-width: 1000px;
  min-width: 480px;
  visibility: ${({ isOpen }) => isOpen ? 'visible' : 'hidden'};
  background-color: ${(props) => props.backgroundColor};
  padding:${THEME.SPACING.TINO};
  border-radius: ${THEME.RADIUS.CIRCLE} ;
  display: flex;
  align-items:  center;
  justify-content: flex-start;
  gap: ${THEME.SPACING.MICRO};
  ${Body2Font};
  ${({ isOpen, position }) => isOpen && animationOpen(position)};
  ${({ isClose, position }) => isClose && animationClose(position)};
  position: absolute;
  z-index: ${THEME.Z_INDEX.HIGHEST + 1000}; // TODO [z-index] Fix z-index issues to ensure proper layering across applications
  height: fit-content;
  ${(props) => (props.position.top ? 'top: -100px' : 'bottom: -100px')};
  ${(props) => {
    if (props.position.center) {
      return 'left: 50%;';
    }
    
    if (props.position.left) {
      return 'left: 3rem;';
    }

    return 'right: 3rem;';
  }}
  transform: ${(props) => (props.position.center ? 'translateX(-50%)' : null)};
`;

export const ContentContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  flex: 1;
  flex-wrap: wrap;
  min-width: 0; // fix flex sizing for ellipsis
`;

export const SnackBarImage = styled.div<{color?: string, size: string}>`
  height: ${(props) => props.size};
  width: ${(props) => props.size};
  color: ${(props) => props.color};
  fill: ${(props) => props.color};
`;
const addEllipsis = css`
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
`;
export const SnackBarTitle = styled.div<{color?: string}>`
  ${Body2Font};
  ${addEllipsis};
  color: ${(props) => props.color};
  line-height: 1.71;
  font-weight: bold;
  letter-spacing: 0.1px;
`;

export const SnackBarDescription = styled.div<{color?: string}>`
  color: ${(props) => props.color};
  opacity: ${THEME.OPACITY.MED};
  ${addEllipsis};
  ${Body2Font};
  line-height: 1.71;
  flex: 1;
  text-align: left;
`;

export const SnackBarActionButton = styled.button<{color?: string}>`
  ${ButtonFont};
  all: unset;
  cursor: pointer;
  color: ${(props) => props.color};
  ${addEllipsis};
  padding: ${THEME.SPACING.MICRO};
  font-weight: bold;
  :hover {
    opacity: 0.8;
  }
  line-height: 1.71;
`;
