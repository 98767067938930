/* eslint-disable @typescript-eslint/no-unused-vars,react-hooks/exhaustive-deps */
import { useParams } from 'react-router';
import {
  useCallback, useEffect, useMemo, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import ConversationHeader from '../../components/outreach/conversation-header/conversation-header.component';
import Conversation from '../../components/outreach/conversation/conversation.component';
import { createFullName } from '../../../../utils';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { useMyUserSelector } from '../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { MessageTypeEnum } from '../../../../store/rtk-query/apis/outreach/enums/message-type.enum';
import { MessageDirectionEnum } from '../../../../store/rtk-query/apis/outreach/enums/message-direction.enum';
import MatchSidePanel from '../../components/outreach/match-side-panel/match-side-panel.component';
import { usePageFilters } from '../../../../hooks/use-page-filters.hooks';
import { getMatchByIdQuery } from '../../../../store/rtk-query/apis/match/hooks/get-match-by-id.query-hook';
import {
  ANALYTICS_EVENTS,
  EVENT_GROUP,
  PRODUCT_AREA,
} from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { useGetPositionByIdFromPositionsQuerySelector } from '../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { AlertSnackBar } from '../../../../components/Common/CustomSnackBar/AlertSnackBar';
import STRING_KEYS from '../../../../language/keys';
import { OutreachEmail } from '../../components/outreach/outreach-editor/emails-input/emails-input';
import { useGetMessageSuggestionMutation } from '../../../../store/rtk-query/apis/outreach/hooks/get-message-suggestion.query-hook';
import { MessageSuggestionTypeEnum } from '../../../../store/rtk-query/apis/outreach/enums/message-suggestion-type.enum';
import { ConversationPageSizeEnum, useConversationPageSize } from '../../hooks/conversations-page-size.hook';
import { useShouldLockFeature } from '../../../../hooks/should-lock-feature.hook';
import { useSendMessageMutation } from '../../../../store/rtk-query/apis/outreach/hooks/send-message.query-hook';
import { useIsSuperUser } from '../../../../hooks/superuser';
import SetupGenAiBanner from '../../components/outreach/setup-gen-ai-banner/setup-gen-ai-banner.component';
import { SocialLinksType } from '../../components/outreach/match-side-panel/social-links/social-links.enums';
import { SequenceModeEnum } from '../../../../store/rtk-query/apis/sequence/enums/sequence-mode.enum';
import { ScheduledMessageTypeEnum } from '../../../../store/rtk-query/apis/outreach/enums/scheduled-message-type.enum';
import { ScheduledMessageStatusEnum } from '../../../../store/rtk-query/apis/outreach/enums/scheduled-message-status.enum';
import { useGetPositionSequencePreferencesSelector } from '../../../../store/rtk-query/apis/sequence/selectors/position-sequence-preferences.selector';
import { setSequenceMode } from '../../../../store/slices/outreach-state/outreach-state.toolkit-slice';
import { useMarkMessageAsSentMutation } from '../../../../store/rtk-query/apis/outreach/hooks/mark-message-as-sent.query-hook';
import { SendMessageQueryArguments } from '../../../../store/rtk-query/apis/outreach/dto/query-arguments/send-message-query.arguments';
import { MessageTriggerTypeEnum } from '../../../../store/rtk-query/apis/outreach/enums/message-trigger-type.enum';
import { outreachQuery } from '../../../../store/rtk-query/apis/outreach/outreach.toolkit-api';
import {
  EmailSenderIntegrationStatusEnum,
  useGetEmailSenderIntegrationStatus,
} from '../../../../store/rtk-query/apis/outreach/hooks/get-email-sender.query-hook';
import OutreachEditor from '../../components/outreach/outreach-editor/outreach-editor.component';
import { SendMessageHandlerArguments } from '../../components/outreach/outreach-editor/outreach-editor';
import ConversationsArchivedChip
  from '../../components/outreach/conversations-archive-chip/conversations-archive-chip.component';
import {
  ConversationArchiveReasonEnum,
} from '../../../../store/rtk-query/apis/outreach/enums/conversation-archive-reason.enum';
import { UpgradePlanDialog } from '../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import PageLoader from '../../components/page-loader/page-loader.component';
import {
  ArchivedConversationContainer,
  ConversationContainer,
  ConversationHeaderWrapper,
  ConversationWrapper,
  OutreachPageContentWrapper,
} from './conversation.styled';

const ConversationsPage = (): JSX.Element => {
  const { t: translate } = useTranslation();
  const pageSize = useConversationPageSize();
  const { analyticsPageEvent } = useAnalyticsContext();
  const { positionId, conversationId } = useParams<MatchUrlParams & { conversationId: string }>();
  const history = useHistory();
  const dispatch = useDispatch();
  const { data: user } = useMyUserSelector();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const isLocked = useShouldLockFeature();
  const isSuperUser = useIsSuperUser();
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const [messageSuggestionType, setMessageSuggestionType] = useState<MessageSuggestionTypeEnum | null>(null);
  const [errorMessage, setErrorMessage] = useState<{ title?: string, message?: string } | null>(null);
  const { data: preferences, isLoading: isGetPositionPreferencesLoading } = useGetPositionSequencePreferencesSelector(positionId);
  const emailSenderStatus = useGetEmailSenderIntegrationStatus();

  const [getConversationByIdQuery, {
    data: conversation,
    isLoading: isConversationLoading,
    isFetching: isConversationFetching,
  }] = outreachQuery.useLazyGetConversationByIdQuery();

  useEffect(() => {
    getConversationByIdQuery({ conversationId, positionId });
  }, [conversationId]);

  const { data: position } = useGetPositionByIdFromPositionsQuerySelector(conversation?.positionId || '');

  const {
    getFiltersByKey,
    deleteFiltersByKey,
    isFilterExists,
  } = usePageFilters();

  const showDetails = !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS).length;
  const isStepSelected = !!getFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.CONVERSATION_GROUP)[0];

  const [sendMessage, {
    isLoading: isSendMessageLoading,
  }] = useSendMessageMutation();

  const [markMessageAsSent, {
    isLoading: isMarkMessageAsSentLoading,
  }] = useMarkMessageAsSentMutation();

  const isAutoMode = useMemo(
    () => preferences?.sequencePreferences?.mode === SequenceModeEnum.Auto || preferences?.sequencePreferences?.mode === SequenceModeEnum.Custom,
    [preferences?.sequencePreferences?.mode]);

  const senderUserId = useMemo(() => {
    const primarySender = preferences?.sequencePreferences?.senders?.find((sender) => {
      return sender.isPrimarySender;
    });
    if (isAutoMode && primarySender) {
      return primarySender.user.id;
    }

    return user.id;
  }, [user, preferences]);

  const {
    data: match,
    isFetching: isMatchFetching,
    isLoading: isMatchLoading,
  } = getMatchByIdQuery(conversation?.positionId, conversation?.matchId);

  const [getMessageSuggestion, {
    isLoading: isMessageSuggestionLoading,
    isError: isMessageSuggestionFetchError,
    isSuccess: isMessageSuggestionFetchSuccess,
  }] = useGetMessageSuggestionMutation();

  useEffect(() => {
    analyticsPageEvent({
      eventName: ANALYTICS_EVENTS.OUTREACH_PAGE_VIEW,
      pageName: PAGE_NAMES.TALENT_OUTREACH,
      eventGroup: EVENT_GROUP.PAGE_VIEW,
      productArea: PRODUCT_AREA.TALENT_OUTREACH,
      conversationId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationId]);

  useEffect(() => {
    if (isMessageSuggestionFetchError) {
      setMessageSuggestionType(null);
      setErrorMessage({
        title: translate(STRING_KEYS.ALERT),
        message: translate(STRING_KEYS.GLOBAL_ERROR_MESSAGE),
      });
    }
  }, [isMessageSuggestionFetchError]);

  useEffect(() => {
    if (messageSuggestionType) {
      getMessageSuggestion({ positionId: position.id, matchId: match.id, messageSuggestionType });
    }
  }, [messageSuggestionType]);

  useEffect(() => {
    if (isMessageSuggestionFetchSuccess) {
      setMessageSuggestionType(null);
    }
  }, [isMessageSuggestionFetchSuccess]);

  const replyToMessage = conversation?.messageHistory?.[conversation?.messageHistory.length - 1];

  const latestOutboundMessage = useMemo(() => {
    if (!conversation?.messageHistory) {
      return undefined;
    }

    return conversation?.messageHistory.slice().reverse().find((message) => message.messageDirection === MessageDirectionEnum.Outbound);
  }, [conversation?.messageHistory]);

  const onSendMessage = async (args: SendMessageHandlerArguments, handler: (request: SendMessageQueryArguments) => void) => {
    const {
      emails: selectedEmails, subject, onSuccess, bodyPlanText, bodyHtml,
    } = args;
    if (isLocked) {
      setIsUpgradeDialogOpen(true);

      return;
    }

    if (!conversation) {
      return;
    }

    await handler({
      conversationId,
      positionId: conversation?.positionId,
      matchId: conversation?.matchId,
      senderUserId,
      recipient: selectedEmails,
      subject: replyToMessage ? undefined : subject,
      html: bodyHtml,
      text: bodyPlanText,
      inReplyToPrimaryId: replyToMessage?.id,
      type: handler === sendMessage ? MessageTypeEnum.Email : MessageTypeEnum.LinkedinManual,
      triggerType: handler === markMessageAsSent ? MessageTriggerTypeEnum.SuggestedFollowup : undefined,
      userInfo: {
        id: user.id,
        fullName: `${user.firstName} ${user.lastName}`,
        profileImage: user.profileImage,
      },
    });

    onSuccess();
  };

  const engagementPanelCloseHandler = () => {
    if (isFilterExists(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS, 'true')) {
      deleteFiltersByKey(AppRouting.URL_PARAMS.OUTREACH.SHOW_DETAILS);
    }
  };

  const trackSocialLinkClick = (socialChannel: string) => {
    const analyticsTrackEventPayload = {
      eventName: ANALYTICS_EVENTS.MATCH_CLICK_ON_SOCIAL,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: PAGE_NAMES.TALENT_OUTREACH,
      pagePositionId: conversation?.positionId,
      pagePositionName: conversation?.position?.jobTitle,
      matchId: conversation?.matchId,
      matchTalentId: match?.talent?.id,
      matchEngagementStatus: match?.engagement?.status,
      componentElementType: socialChannel,
    };

    analyticsTrackEvent(analyticsTrackEventPayload);
  };

  const onDialogCloseHandler = () => {
    setIsUpgradeDialogOpen(false);
  };

  const linkedinLink = useMemo(
    () => match?.talent?.socialLinks.find((socialLink) => socialLink.type === SocialLinksType.LinkedIn),
    [match],
  );

  const onAlertActionButtonClick = () => {
    if (linkedinLink) {
      AppRouting.openInNewTab(linkedinLink.url);
    }
  };

  const emailsInput = useMemo<OutreachEmail[]>(() => {
    let emails = [];
    if (isLocked) {
      return [];
    }
    if (latestOutboundMessage?.recipient) {
      emails.push(latestOutboundMessage.recipient);
    }
    if (match?.contactDetails?.emails.length && !latestOutboundMessage?.recipient) {
      emails = [...match?.contactDetails?.emails || []];
    }

    return emails.map((email, index) => ({
      value: email,
      selected: index === 0,
    }));
  }, [isLocked, latestOutboundMessage, match?.contactDetails?.emails]);

  const shouldDisableSendButton = () => {
    return emailSenderStatus !== EmailSenderIntegrationStatusEnum.Active || isSendMessageLoading || isMarkMessageAsSentLoading;
  };

  const onMessageSuggestionButtonClick = (suggestionType: MessageSuggestionTypeEnum) => {
    if (isLocked) {
      setIsUpgradeDialogOpen(true);

      return;
    }
    setMessageSuggestionType(suggestionType);
  };
  const showDetailsPanel = useMemo(() => {
    if (isAutoMode) {
      if (isStepSelected) {
        if (pageSize === ConversationPageSizeEnum.Small || pageSize === ConversationPageSizeEnum.Medium) {
          return showDetails;
        }

        return true;
      }
    }

    if (pageSize !== ConversationPageSizeEnum.Small) {
      return true;
    }

    return showDetails;
  }, [isAutoMode, pageSize, showDetails, isStepSelected]);

  const renderMessages = useCallback(() => {
    if (isConversationLoading || isConversationFetching || isMatchLoading || isMatchFetching) {
      return (<PageLoader />);
    }

    if (conversation?.messageHistory?.length || conversation?.nextScheduledMessage?.status === ScheduledMessageStatusEnum.Failed) {
      return (
        <ConversationWrapper>
          <Conversation conversation={ conversation } isLocked={ isLocked || isSuperUser } />
        </ConversationWrapper>
      );
    }

    return null;

    // TODO: message suggestions are removed for now. We can remove the code after we see that it's not coming back
    // let suggestionOptions = suggestions;
    // if (match.networkedTalents?.length) {
    //   suggestionOptions = suggestionOptions.concat(InsiderNetworkSuggestion);
    // }
    //
    // return (
    //   <ConversationWrapper>
    //     <MessageSuggestionsWrapper>
    //       <ConversationMessageSuggestions
    //         messageSuggestionClickHandler={ onMessageSuggestionButtonClick }
    //         loadingState={ { isLoading: isMessageSuggestionLoading, selectedType: messageSuggestionType } }
    //         suggestionOptions={ suggestionOptions }
    //       />
    //     </MessageSuggestionsWrapper>
    //   </ConversationWrapper>
    // );
  }, [isMatchFetching, isMatchLoading, isConversationLoading, isConversationFetching, conversation, isMessageSuggestionLoading, messageSuggestionType]);

  // const getTextEditorSubject = () => {
  //   return conversation?.nextScheduledMessage?.payload?.subject || conversation?.nextOpenLinkedinTask?.payload?.subject || messageSuggestion?.subject;
  // };
  //
  // const getTextEditorMessage = () => {
  //   return conversation?.nextScheduledMessage?.payload?.html || conversation?.nextOpenLinkedinTask?.payload?.html || messageSuggestion?.body;
  // };

  const onAutomateFollowupsButtonClick = () => {
    dispatch(setSequenceMode(SequenceModeEnum.Auto));
    history.push(AppRouting.buildCampaignsPreferencesPageUrl(positionId));
  };

  const sendAsReply = useMemo(() => {
    if (preferences?.sequencePreferences?.mode === SequenceModeEnum.Custom) {
      return conversation?.nextOpenLinkedinTask?.sendAsReply || conversation?.nextScheduledMessage?.sendAsReply;
    }

    return false;
  }, [conversation, preferences]);

  const renderTextEditor = () => {
    if (conversation?.isArchived) {
      return (
        <ArchivedConversationContainer>
          <ConversationsArchivedChip archiveReason={ conversation?.archiveReason || ConversationArchiveReasonEnum.Manual } />
        </ArchivedConversationContainer>
      );
    }

    if (
      conversation?.nextScheduledMessage?.type === ScheduledMessageTypeEnum.Scheduled &&
      conversation?.nextScheduledMessage?.status === ScheduledMessageStatusEnum.Failed
    ) {
      return undefined;
    }

    return (
      <OutreachEditor
        defaultEmails={ emailsInput }
        sendMessageHandler={ (args) => onSendMessage(args, sendMessage) }
        markMessageAsSentHandler={ (args) => onSendMessage(args, markMessageAsSent) }
        alertActionButtonHandler={ onAlertActionButtonClick }
        disableSendButton={ shouldDisableSendButton() }
        isSendButtonLoading={ isSendMessageLoading }
        automateFollowupsButtonClickHandler={ onAutomateFollowupsButtonClick }
        isAutoMode={ isAutoMode }
        conversation={ conversation }
        sendAsReply={ sendAsReply }
      />
    );
  };
  const renderConversationContent = () => {
    if (isConversationLoading || isConversationFetching || isGetPositionPreferencesLoading || isMatchFetching || isMatchLoading) {
      return <PageLoader />;
    }
    if (!preferences.contentPreferences) {
      return <SetupGenAiBanner clickHandler={ () => history.push(AppRouting.buildPositionContentPreferencesPageUrl(positionId)) } />;
    }

    return (
      <>
        <ConversationContainer>
          { renderMessages() }
        </ConversationContainer>
        { renderTextEditor() }
      </>
    );
  };

  return (
    <>
      <OutreachPageContentWrapper>
        <ConversationHeaderWrapper>
          <ConversationHeader
            linkedinLink={ linkedinLink?.url }
            isLoading={ isConversationLoading || isConversationFetching }
            isAutoMode={ isAutoMode }
            url={ conversation?.match?.talent?.profileImage || '' }
            fullName={ createFullName(conversation?.match?.talent?.firstName, conversation?.match?.talent?.lastName) }
            talentLocation={ {
              country: conversation?.match?.talent?.location?.country,
              city: conversation?.match?.talent?.location?.city,
            } }
            positionName={ conversation?.position?.jobTitle || '' }
            isArchived={ conversation?.isArchived }
          />
        </ConversationHeaderWrapper>
        { renderConversationContent() }
      </OutreachPageContentWrapper>
      {
        match && (
          <MatchSidePanel
            isLoading={ isConversationFetching || isConversationLoading || isMatchFetching || isMatchLoading }
            isOpen={ showDetailsPanel }
            onClose={ engagementPanelCloseHandler }
            match={ match }
            position={ position }
            isAutoMode={ isAutoMode }
            onSocialLinkClick={ trackSocialLinkClick }
            isStepSelected={ isStepSelected }
          />
        )
      }
      <AlertSnackBar
        title={ errorMessage?.title }
        description={ errorMessage?.message }
        isOpen={ !!errorMessage }
        shouldAutoDismiss
        onClose={ () => setErrorMessage(null) }
      />
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ onDialogCloseHandler }
        />
      ) }
    </>
  );
};

export default ConversationsPage;
