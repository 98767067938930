import { Trans, useTranslation } from 'react-i18next';
import { useEffect, useMemo, useState } from 'react';
import STRING_KEYS from '../../../language/keys';
import NotificationDialog from '../notification-dialog/notification-dialog.component';
import { IntercomManager } from '../../../services/intercom-manager';
import { useGetPositionByIdFromPositionsQuerySelector } from '../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { useResumeCampaignQuery } from '../../../store/rtk-query/apis/sequence/hooks/resume-campaign.query-hook';
import {
  ActionIcon,
  Container,
  Description,
  Icon,
  SecondaryActionIcon,
  Title,
} from './campaign-error-notification.styled';
import { useCampaignErrorNotification } from './campaign-error-notification.hooks';

const CampaignErrorNotification = ({ onErrorHandler }: CampaignErrorNotificationProps): JSX.Element | null => {
  const { t: translate } = useTranslation();
  const [resumeCampaign, { isError }] = useResumeCampaignQuery();
  const [isNotificationOpen, setIsNotificationOpen] = useState(true);
  const { deleteAccountAcknowledgement, campaignErrorAcknowledgement } = useCampaignErrorNotification();

  useEffect(() => {
    if (isError) {
      onErrorHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isError]);

  const { data: relatedPosition } = useGetPositionByIdFromPositionsQuerySelector(campaignErrorAcknowledgement?.positionId ?? '');

  const CampaignDescriptionComponent = useMemo(() => {
    return (
      <Description>
        <Trans
          i18nKey={ STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.DESCRIPTION }
          values={ { positionName: relatedPosition?.jobTitle ?? '' } }
        />
      </Description>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [relatedPosition?.jobTitle]);

  const dismissAcknowledgementAndCloseNotification = () => {
    setIsNotificationOpen(false);
    deleteAccountAcknowledgement();
  };

  const onActionButtonClick = () => {
    resumeCampaign({ positionId: relatedPosition?.id });
    dismissAcknowledgementAndCloseNotification();
  };

  const onSecondaryActionButtonClick = () => {
    IntercomManager.showChat();
  };

  if (!campaignErrorAcknowledgement) {
    return null;
  }

  return (
    <>
      <NotificationDialog
        isOpen={ isNotificationOpen }
        onClose={ dismissAcknowledgementAndCloseNotification }
        closeTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.CLOSE_BUTTON_TEXT) }
        actionTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.ACTION_BUTTON_TEXT) }
        secondaryActionTitle={ translate(STRING_KEYS.CAMPAIGN_ERROR_NOTIFICATION.SECONDARY_ACTION_BUTTON_TEXT) }
        ActionIcon={ ActionIcon }
        SecondaryActionIcon={ SecondaryActionIcon }
        onAction={ onActionButtonClick }
        onSecondaryAction={ onSecondaryActionButtonClick }
        showDivider
      >
        <Container>
          <Icon />
          <Title>{ translate(STRING_KEYS.OUTREACH_PAGE.CAMPAIGN_ON_HOLD.TITLE) }</Title>
          { CampaignDescriptionComponent }
        </Container>
      </NotificationDialog>
    </>
  );
};

export default CampaignErrorNotification;
