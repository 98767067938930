import styled from 'styled-components';
import RelevanceMenuButton
  from '../../../application/components/match-tune/steps/level-of-experience-step/relevance-menu-button/relevance-menu-button.component';
import {
  ArrowDownIcon,
} from '../../../application/components/match-tune/steps/level-of-experience-step/relevance-menu-button/relevance-menu-button.styled';

export const RelevanceMenuButtonCustom = styled(RelevanceMenuButton)`
  ${ArrowDownIcon} {
    width: 32px;
    height: 32px;
  }
`;
