import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import { currentTimeOfDayText, diffInHours } from '../../../../utils/dates';
import STRING_KEYS from '../../../../language/keys';
import { TimeOfDay } from '../../../../enums/date.enum';
import { useGetMyAccountSelector } from '../../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { PricingPlanEnum } from '../../../../enums/user-plan.enum';
import { useDaysLeftPlan } from '../../../../hooks/days-left-plan.hook';
import { UpgradePlanDialog } from '../../../../components/dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import {
  HeaderContainer,
  ContainerBackground,
  HeaderContent,
  ContentContainer,
  Title,
  Description,
  MorningIcon,
  AfternoonIcon,
  EveningIcon,
  TimeOfDayContainer,
  StyledHeader,
} from './homepage-header.styled';
import DailyStreak from './daily-streak/daily-streak.component';
import TrialWillEndHeaderBanner from './trial-will-end-header-banner/trial-will-end-header-banner.component';
import TrialEndedHeaderBanner from './trial-ended-header-banner/trial-ended-header-banner.component';

const TIMES_OF_DAY = {
  [TimeOfDay.Morning]: MorningIcon,
  [TimeOfDay.Afternoon]: AfternoonIcon,
  [TimeOfDay.Evening]: EveningIcon,
};

const HomepageHeader = ({ isHeaderSticky, disabled }: HomepageHeaderProps): JSX.Element => {
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);
  const { t: translate } = useTranslation();
  const { data: { firstName, dailyStreak } } = useGetMyUserQuery();
  const timeOfDay = currentTimeOfDayText();
  const daysLeftToEndPlan = useDaysLeftPlan();
  const { data: { plan, trialEndedAt } } = useGetMyAccountSelector();

  const onUpgradeDialogClose = () => {
    setIsUpgradeDialogOpen(false);
  };

  const onUpgradeDialogOpen = () => {
    setIsUpgradeDialogOpen(true);
  };

  const TitleIcon = useMemo(() => TIMES_OF_DAY[timeOfDay], [timeOfDay]);

  const getInfoContainer = () => {
    const shouldShowTrialWillEndBanner = plan.pricingPlan === PricingPlanEnum.TRIAL && daysLeftToEndPlan <= 3;
    const shouldShowTrialEndedBanner = trialEndedAt && plan.pricingPlan === PricingPlanEnum.TRIAL && Math.ceil(diffInHours(new Date(trialEndedAt), new Date()) / 24) <= 3;

    if (shouldShowTrialWillEndBanner) {
      return (
        <TrialWillEndHeaderBanner
          actionButtonClickHandler={ onUpgradeDialogOpen }
          daysLeft={ daysLeftToEndPlan }
        />
      );
    }

    if (shouldShowTrialEndedBanner) {
      return (
        <TrialEndedHeaderBanner
          actionButtonClickHandler={ onUpgradeDialogOpen }
        />
      );
    }

    return <DailyStreak dailyStreak={ dailyStreak } />;
  };

  if (disabled) {
    return <></>;
  }

  return (
    <>
      <HeaderContainer>
        <ContainerBackground />
        <StyledHeader isHeaderSticky={ isHeaderSticky } />
        <HeaderContent>
          <ContentContainer>
            <Title>
              <TimeOfDayContainer>
                {
                  translate(STRING_KEYS.HOMEPAGE.HEADER.TITLE, {
                    timeOfDay: translate(STRING_KEYS.TIME_OF_DAY[timeOfDay]),
                  })
                }
                <TitleIcon />
              </TimeOfDayContainer>
              { `, ${firstName}` }
            </Title>
            <Description>{translate(STRING_KEYS.HOMEPAGE.HEADER.DESCRIPTION)}</Description>
          </ContentContainer>
          <ContentContainer>
            { getInfoContainer() }
          </ContentContainer>
        </HeaderContent>
      </HeaderContainer>
      { isUpgradeDialogOpen && (
        <UpgradePlanDialog
          isDialogOpen={ isUpgradeDialogOpen }
          onCloseDialog={ onUpgradeDialogClose }
        />
      )}
    </>
  );
};
export default HomepageHeader;
