import { useLocation, useParams } from 'react-router';
import { useEffect, useMemo } from 'react';
import { Redirect, useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PositionSelectionGroup from '../position-selection-group/position-selection-group.component';
import HeaderToolbar from '../../../../components/Common/headers/header/header-toolbar/header-toolbar.component';
import Header from '../../../../components/Common/headers/header/header.component';
import { useGetPositionByIdQuery } from '../../../../store/rtk-query/apis/position/hooks/get-position-by-id.query-hook';
import { PAGE_TITLES } from '../../../../consts';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PageName } from '../../../../enums/page-name.enum';
import { PositionState } from '../../../../enums/position-state.enum';
import { setSnackbar } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import STRING_KEYS from '../../../../language/keys';
import {
  AnchorDirectionsEnum,
  AppSnackbarVariant,
} from '../../../../components/Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import { HTTP_CODES } from '../../../../consts/api';
import { useAppState } from '../../../../store/selectors';
import { HEADER_HEIGHT } from '../../../../components/Common/headers/header/header.styled';
import { BANNER_HEIGHT } from '../../../../components/user-role-changed-banner/user-role-changed-banner.styled';
import {
  Body, LayoutContainer, LayoutHeader, SelectionGroupContainer,
} from './position-layout.styled';

const PositionLayout = ({ children }: PositionLayoutProps): JSX.Element | null => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const { positionId } = useParams<{ positionId: string }>();
  const location = useLocation();
  const history = useHistory();
  const pageName = AppRouting.getPageName(location.pathname);
  const {
    userRoleWasChanged,
  } = useAppState();

  const globalHeaderHeight = userRoleWasChanged ? HEADER_HEIGHT + BANNER_HEIGHT :  HEADER_HEIGHT;

  const {
    data: position, isLoading, isError, error,
  } = useGetPositionByIdQuery(positionId);

  const isClosedPosition = useMemo(() => {
    return position.state === PositionState.Closed;
  }, [position]);

  useEffect(() => {
    if (position?.id && !isLoading) {
      document.title = `${position.jobTitle}${PAGE_TITLES.CUSTOM_PAGE_SUFFIX}`;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, position?.id]);

  if (position.id && position.hasReachedMatchTuneThreshold && pageName === PageName.Match) {
    history.replace(AppRouting.buildMatchTunePagePath(positionId));
  }

  if (isClosedPosition) {
    dispatch(setSnackbar({
      title: translate(STRING_KEYS.HOMEPAGE.ERRORS.CLOSED_POSITION_TITLE),
      description: translate(STRING_KEYS.HOMEPAGE.ERRORS.CLOSED_POSITION_DESCRIPTION),
      variant: AppSnackbarVariant.Error,
      anchor: AnchorDirectionsEnum.BottomCenter,
    }));
    history.replace(AppRouting.buildHomePagePath());
  }

  if (isError) {
    if (error.error.serverError.statusCode === HTTP_CODES.FORBIDDEN) {
      dispatch(setSnackbar({
        title: translate(STRING_KEYS.GLOBAL_ERROR.FORBIDDEN.TITLE),
        description: translate(STRING_KEYS.GLOBAL_ERROR.FORBIDDEN.DESCRIPTION),
        variant: AppSnackbarVariant.Error,
        anchor: AnchorDirectionsEnum.BottomCenter,
      }));
    }

    return <Redirect to={ AppRouting.ROUTES.HOME } />;
  }

  return (
    <LayoutContainer globalHeaderHeight={ globalHeaderHeight }>
      <Header>
        <LayoutHeader>
          <HeaderToolbar positionId={ positionId } />
          <SelectionGroupContainer>
            <PositionSelectionGroup
              positionId={ positionId }
            />
          </SelectionGroupContainer>
        </LayoutHeader>
      </Header>
      <Body globalHeaderHeight={ globalHeaderHeight }>
        { children }
      </Body>
    </LayoutContainer>
  );
};

export default PositionLayout;
