import { PermissionPlaceholder } from '../enums/permission-placeholder.enum';
import { UserRoleName } from '../enums/user-role-name.enum';

/**
 * Determines if the current user is allowed to perform invitation-related actions.
 *
 * @param resolvedPermissionPlaceholders - A list of resolved permission placeholders or a wildcard.
 * @param userRoleName - The role name of the user.
 * @returns `true` if the user can perform invitation actions, otherwise `false`.
 */
export const canDoInvitationActions = (
  resolvedPermissionPlaceholders: PermissionPlaceholder[] | PermissionPlaceholder.Wildcard,
  userRoleName: UserRoleName,
): boolean => {
  // If the resolved permissions are a wildcard, grant full access
  if (resolvedPermissionPlaceholders === PermissionPlaceholder.Wildcard) {
    return true;
  }
  if (Array.isArray(resolvedPermissionPlaceholders)) {
    const canNotDoAction = resolvedPermissionPlaceholders.some((placeholder) => {
      return placeholder === PermissionPlaceholder.Collaborator && userRoleName !== UserRoleName.Collaborator;
    });

    return !canNotDoAction;
  }

  return false;
};
