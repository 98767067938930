import { isEqual, round } from 'lodash';
import { FC } from 'react';
import i18n from 'i18next';
import {
  ICONS_MAPPING_LIST, MATCH_STATUSES,
} from '../consts';
import { MatchEmploymentHistoryRecord } from '../@types/api';
import { DateObject, ExperienceItem } from '../@types/match';
import STRING_KEYS from '../language/keys';

// TODO [refactor] implement logs
// const logger = log.getLogger('UTILS_INDEX');

export enum ScrollLogicalPosition {
  START = 'start',
  END ='end',
  NEAREST = 'nearest',
  CENTER = 'center',
}

/**
 * check if email address is valid
 * more info: https://stackoverflow.com/a/46181
 * @param email
 * @returns bool
 */
export const validateEmail = (email: string): boolean => {
  // eslint-disable-next-line max-len
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  return re.test(String(email).toLowerCase());
};

/**
 * check if a string contains special characters or spaces
 * more info: https://stackoverflow.com/a/32311188/17419255
 * @param stringToCheck String
 * @returns bool
 */
export const validateString = (stringToCheck: string): boolean => {
  const re = /[ `!@#$%^&*()+\-=[\]{};':"\\|,<>/?~]/;

  return !re.test(stringToCheck);
};

/**
 * Remove items from an array
 * @param arr input Array
 * @param itemToRemove
 * @returns Array without the items that were removed
 */
export const removeFromArray = (arr: Array<string>, itemToRemove: string): Array<string> => {
  return arr.filter((item) => {
    return item !== itemToRemove;
  });
};

/**
 * get matches and engagement status returns sorted matches based on engagement status
 * if engagement status TO_REVIEW sorting by rank else by last modified first
 * @param matches Array<Match>
 * @param engagementStatus string
 * @returns Array<Match> || []
 */
export const getSortedMatches = (matches: Array<Match>,
  engagementStatus: string | null | undefined): Array<Match> => {
  if (engagementStatus === MATCH_STATUSES.TO_REVIEW) {
    return matches.slice().sort((matchA, matchB) => {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const DateA: any = new Date(matchA.publishedAt);
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const DateB: any = new Date(matchB.publishedAt);

      return DateA - DateB;
    });
  }

  return matches.slice().sort((matchA, matchB) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DateA: any = new Date(matchA.lastModified);
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const DateB: any = new Date(matchB.lastModified);

    return DateB - DateA;
  });
};

/**
 * get SVG Icon element by pre-defined ids
 * @param iconId string - icon name
 * @returns SVG Icon Element
 */
export const getIconBasedId = (iconId: string): FC | null => {
  if (!iconId) {
    return null;
  }

  const upperIconId = iconId.toUpperCase();

  return ICONS_MAPPING_LIST.find((item) => item.iconId === upperIconId)?.icon || null;
};

/**
 * Get object of the first day of a month
 * @param year number
 * @param month number - calendary month (1 = January, 12 = December)
 * @returns object - date object of the first day of the month
 */
const getStartMonthDate = (year: number, month: number) => {
  return new Date(year, (month || 1) - 1, 1);
};

/**
 * B - A
 * @param dateObjA
 * @param dateObjB
 * @returns
 */
export const getDiffOfDateObjects = (dateObjA: DateObject, dateObjB: DateObject): number => {
  // if one of the employment histories doesn't have a start date, we put it at the end of the list
  if (!dateObjA || !dateObjB) {
    return dateObjB ? 1 : -1;
  }

  const dateA = getStartMonthDate(dateObjA?.year, dateObjA?.month).getTime();
  const dateB = getStartMonthDate(dateObjB?.year, dateObjB?.month).getTime();

  return dateB - dateA;
};

export const isDatesEqual = (dateObjA: DateObject, dateObjB: DateObject): boolean => {
  return dateObjA?.month === dateObjB?.month && dateObjA?.year === dateObjB?.year;
};

export const getCurrentYearMonthObj = (): DateObject => {
  return {
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  };
};

/**
 * get matches and engagement status returns sorted matches based on engagement status
 * if engagement status TO_REVIEW sorting by rank else by last modified first
 * @param employmentHistory Array<MatchEmploymentHistoryRecord>
 * @returns Array<MatchEmploymentHistoryRecord> || []
 */
export const getSortedEmployment = (
  employmentHistory: Array<MatchEmploymentHistoryRecord>,
): Array<MatchEmploymentHistoryRecord> => {
  return employmentHistory.slice().sort((employmentA, employmentB) => {
    if (!employmentA.endDateObject && employmentB.endDateObject) {
      return 1;
    }

    if (employmentA.endDateObject && !employmentB.endDateObject) {
      return -1;
    }

    if (isDatesEqual(employmentB.endDateObject, employmentA.endDateObject)) {
      return getDiffOfDateObjects(
        (employmentB.startDateObject),
        (employmentA.startDateObject),
      );
    }

    return getDiffOfDateObjects(employmentB.endDateObject, employmentA.endDateObject);
  });
};

/**
 * get employmentHistory returns sorted experience by end date
 * @param employmentHistory Array<ExperienceItem>
 * @returns Array<ExperienceItem> || []
 */
export const getSortedEmploymentByEndDate = (
  employmentHistory: Array<ExperienceItem>,
): Array<ExperienceItem> => {
  return employmentHistory.sort((employmentA: ExperienceItem, employmentB: ExperienceItem) => {
    const chapterATotalRoles = employmentA.data.length;
    const chapterBTotalRoles = employmentB.data.length;

    if (chapterATotalRoles <= 0 || chapterBTotalRoles <= 0) {
      // if for any reason we don't get roles in a company (should not happen)
      // we pass it to the beginning of the list
      return -1;
    }

    const chapterAlatestRole = employmentA.data[chapterATotalRoles - 1];
    const chapterBlatestRole = employmentB.data[chapterBTotalRoles - 1];

    if (!chapterBlatestRole.startDateObject || !chapterAlatestRole.startDateObject) {
      // if one of the employment histories doesn't have a start date, we put it at the end of the list
      return !chapterBlatestRole.startDateObject ? 1 : -1;
    }

    return getDiffOfDateObjects(
      // if there is not endData for a role = this is a current role of the talent,
      // so we use "now" as reference
      chapterBlatestRole.endDateObject || getCurrentYearMonthObj(),
      chapterAlatestRole.endDateObject || getCurrentYearMonthObj(),
    );
  });
};

/**
 * Get Comman[& Space]-Seperated-values string of input values.
 * If any value is empty/undefined, it won't be merged in the final string.
 * Input example: ["testA", undefined, "", "testD"]
 * Output example: "testA, testD"
 * @param args list of optional-strings to be merged
 * @returns string of comma-seperated values
 */
export const joinStrings = (...args: (string | undefined | null)[]): string => {
  const finalString = args.reduce((mergedString, value) => {
    if (value && value.trim()) {
      if (mergedString !== '') {
        return `${mergedString}, ${value}`;
      }

      return value;
    }

    return mergedString;
  }, '');

  return finalString || '';
};

// TODO [refactor] Ruderstack fullstory integration
// export const enableSuperUser = (): void => {
//   logger.debug('enable super user mode', {
//     fullstoryMoment: FullstoryManager.getFullStorySessionURL(false),
//   });
//
//   FullstoryManager.shutdown();
// };
// TODO [refactor] Ruderstack fullstory integration
// export const disableSuperUser = (): void => {
//   FullstoryManager.restart();
//
//   logger.debug('super user mode disabled', {
//     fullstoryMoment: FullstoryManager.getFullStorySessionURL(false),
//   });
// };

/**
 * get decimal year number based on months
 * @param  {Number} months number of months (18)
 * @return {Number} decimal year number (1.5 years) */
export const convertMonthToFloatYears = (months: number): number => {
  return round(months * (1 / 12), 1);
};

/**
 * Keep last {length} chars of a string.
 * example:
 *    input: "Perfect Match", length: 3
 *    output: "tch"
 * @param input string to cut
 * @param length max size of output value
 * @returns last characters of the string in the specified length
 */
export const substringFromEnd = (input: string, length: number): string => {
  return input.substring(input.length - length);
};

/**
 * Get Censored token
 * @param token string of input token
 * @param maxLength number of chars to show (from the end of the token)
 * @returns censored token
 */
export const censorToken = (token: string | undefined, maxLength = 5): string | null => {
  if (!token) {
    return null;
  }

  return substringFromEnd(`${token}`, maxLength);
};

/**
 * Get Initials string
 * @param fullName string of full name
 * @returns initials first letter of firstName and first letter of lastName
 */
export const getInitials = (fullName: string): string => {
  const splittedFullName = fullName?.split(' ');
  const initialsFirstName = splittedFullName[0]?.charAt(0) || '';
  const initialsLastName = splittedFullName[1]?.charAt(0) || '';

  return `${initialsFirstName}${initialsLastName}`;
};

/**
 * Get hostname and domain from website
 * example:
 *   input: "https://www.google.com"
 *   output: "google.com"
 * @param websiteURL string of website URL
 * @returns hostname and domain part of the URL
 */
export const getHostnameAndDomainFromWebsite = (websiteURL: string): string => {
  return websiteURL.replace(/.+\/\/|www./g, '');
};

/**
 * Get year from date object
 * example:
 * input: "2011-02-02"
 * output: "2011"
 * @param fullDate string of date
 * @returns year from the date
 */
export const extractYearFromDate = (fullDate: string): number => {
  return new Date(fullDate).getFullYear();
};

/**
 * Checks if the given value is a valid URL (excluding IPv4 addresses).
 * @param value - The string to validate.
 * @returns A boolean indicating whether the value is a valid URL but not an IPv4 address.
 */
export const isUrlWithoutIPv4 = (value: string): boolean => {
  // Regular expression for a valid URL (excluding IPv4)
  const URL_REGEX = /^(https?:\/\/)?([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,6}(:[0-9]{1,5})?(\/.*)?$/i;

  // Regular expression for IPv4 addresses (including optional port numbers)
  const IPV4_REGEX = /^(?:\d{1,3}\.){3}\d{1,3}(:\d{1,5})?$/;

  // Ensure it's a valid URL but not an IPv4 address (even with ports)
  return URL_REGEX.test(value) && !IPV4_REGEX.test(value);
};

/**
 * Run an async promise with a timeout
 * inspired by: https://advancedweb.hu/how-to-add-timeout-to-a-promise-in-javascript/
 * @param func Promise function to execute
 * @param time timeout in miliseconds
 * @returns Promise
 */
export const timeoutPromise = (func: Promise<unknown>, time: number): Promise<unknown> => {
  let timer: NodeJS.Timeout | null = null;

  return Promise.race([
    func,
    new Promise((_resolve, reject) => {
      timer = setTimeout(() => {
        reject(new Error(`Promise timed out after ${time}ms`));
      }, time);
    }),
  ]).finally(() => {
    if (timer) {
      clearTimeout(timer);
    }
  });
};

export const scrollToElementById = (elementId: number | string, block = ScrollLogicalPosition.NEAREST, inline = ScrollLogicalPosition.START): void => {
  const section = document.getElementById(`${elementId}`);

  if (section) {
    section.scrollIntoView({ behavior: 'smooth', block, inline });
  }
};

export const parseUrlValueByKey = (url: string, key: string): string | undefined => {
  const regex = new RegExp(`${key}\\/(.*?)(\\/|$)`);
  const match = url.match(regex);
  if (match) {
    return match[1];
  }

  return undefined;
};

export const isEmptyObject = (obj?: Record<string, unknown>): boolean => {
  return !obj || Object.keys(obj).length === 0;
};

export const scrollToTop = (): void => {
  window.scrollTo({ top: 0, behavior: 'smooth' });
};

export const extractDomainFromUrl = (url: string): string => {
  if (!url) {
    return url;
  }

  const hostname = new URL(url).hostname.replace('www.', '');

  return hostname.slice(hostname.indexOf('.') + 1);
};

export const getOriginFromUrl = (url: string): string => {
  if (!url) {
    return url;
  }

  const parts = url.split('/');
  const protocol = parts[0];
  const hostAndPort = parts[2];

  return `${protocol}//${hostAndPort}`;
};

export const createFullName = (firstName = '', lastName = ''): string => {
  return `${firstName} ${lastName}`.trim();
};

export const escapeRegExp = (str: string): string => {
  if (!str) {
    return str;
  }

  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&'); // $& means the whole matched string
};

export const clone = <T>(item: T): T => {
  return JSON.parse(JSON.stringify(item)) as T;
};

export const getEnumValuesInRange = <T extends string>(enumObj: Record<string, T>, arr: T[]): T[] => {
  if (arr.length <= 1) {
    return arr;
  }

  const enumKeys = Object.values(enumObj) as T[];
  const sortedRange = arr.slice().sort((a, b) => enumKeys.indexOf(a) - enumKeys.indexOf(b));

  const startIndex = enumKeys.indexOf(sortedRange[0]);
  const endIndex = enumKeys.indexOf(sortedRange[sortedRange.length - 1]);

  if (startIndex === -1 || endIndex === -1) {
    return [];
  }

  return enumKeys.slice(startIndex, endIndex + 1);
};

export const stringToCamelCase = (str: string): string => {
  return str
    .replace(/\s(.)/g, (match) => {
      return match.toUpperCase();
    })
    .replace(/\s/g, '')
    .replace(/^(.)/,  (match) => {
      return match.toLowerCase();
    });
};

export const getDiffBetweenTwoObjects = <T>(obj1: T, obj2: T): Partial<T> => {
  const diffFields: Partial<T> = {};

  Object.keys(obj1).forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(obj1, key) && Object.prototype.hasOwnProperty.call(obj2, key)) {
      if (!isEqual(obj1[key as keyof T], obj2[key as keyof T])) {
        diffFields[key as keyof T] = obj2[key as keyof T];
      }
    }
  });

  return diffFields;
};

export const decodeHtml = (html: string): string => {
  const txt = document.createElement('textarea');
  txt.innerHTML = html;

  return txt.value;
};

export const toggleSetValue = <T>(set: Set<T>, value: T): void => {
  if (set.has(value)) {
    set.delete(value);
  } else {
    set.add(value);
  }
};

// output format: X years Y months
export const formatMonths = (totalMonths: number): string | undefined => {
  if (!totalMonths) {
    return undefined;
  }

  const years = Math.floor(totalMonths / 12);
  const months = Math.floor(totalMonths % 12);
  let durationString = '';

  if (years) {
    if (years === 1) {
      durationString = i18n.t(STRING_KEYS.ONE_YEAR);
    } else {
      durationString = i18n.t(STRING_KEYS.X_YEARS, { value: years });
    }
  }

  if (months) {
    if (months === 1) {
      durationString += ` ${i18n.t(STRING_KEYS.ONE_MONTH)}`;
    } else {
      durationString += ` ${i18n.t(STRING_KEYS.X_MONTHS, { value: months })}`;
    }
  }

  return durationString;
};

export const wrapSubstringWithHtmlElement = (text: string, searchString: string, tag: string): string => {
  if (!text || !searchString) {
    return text;
  }

  // Escape special characters in searchString for use in a regular expression
  const escapedSearchString = searchString.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
  // Create a regular expression to find all occurrences of the searchString
  const regex = new RegExp(`(${escapedSearchString})`, 'gi');
  // Replace all occurrences of the searchString with wrapped version

  return text.replace(regex, `<${tag}>$1</${tag}>`);
};
