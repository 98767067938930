import { useTranslation } from 'react-i18next';
import BaseMenu from '../../../Common/perfect-base-menu/perfect-base-menu.component';
import STRING_KEYS from '../../../../language/keys';
import { usePermissionCheck } from '../../../../hooks/use-permissions.hook';
import { PERMISSIONS } from '../../../../consts/permissions.const';
import {
  useResendInviteUserMutation,
} from '../../../../store/rtk-query/apis/user/hooks/resend-invite-user.mutation-hook';
import { useDeleteUserByIdMutation } from '../../../../store/rtk-query/apis/user/hooks/delete-user-by-id.mutation-hook';
import { RemoveIcon, ResendIcon, StyledMenuItem } from './actions-menu.styled';

export const ActionsMenu = ({
  anchorElement,
  onActionClickHandler,
  handleMenuClose,
  invitePending,
  onResetErrorHandler,
  userId,
  email,
}: ActionsMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { allowed: allowedToDelete } = usePermissionCheck([PERMISSIONS.user.deleteAll]);
  const [resendInviteColleagueAction] = useResendInviteUserMutation();
  const [deleteColleagueAction] = useDeleteUserByIdMutation();

  const onResendClick = () => {
    if (invitePending) {
      onResetErrorHandler();
      resendInviteColleagueAction({ userId, email });
    }
    onActionClickHandler();
  };

  const onDeleteClick = () => {
    if (allowedToDelete) {
      onResetErrorHandler();
      deleteColleagueAction(userId);
    }
    onActionClickHandler();
  };

  return (
    <>
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ handleMenuClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
      >
        <>
          { allowedToDelete && (
            <StyledMenuItem onClick={ onDeleteClick }>
              <>
                <RemoveIcon />
                { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ACTIONS_MENU.REMOVE.TITLE) }
              </>
            </StyledMenuItem>
          )}
        </>

        <StyledMenuItem disabled={ !invitePending } onClick={ onResendClick }>
          <>
            <ResendIcon />
            { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ACTIONS_MENU.RESEND.TITLE) }
          </>
        </StyledMenuItem>
      </BaseMenu>
    </>
  );
};
