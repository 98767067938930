import { useMemo } from 'react';
import { AcknowledgementType } from '../../../enums/acknowledgement-type.enum';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import {
  useDeleteAccountAcknowledgementById,
} from '../../../store/rtk-query/apis/user/hooks/delete-account-aknowledgement.hook';
import { useSeeAcknowledgements } from '../../../hooks/acknowledgements-react-qury.hooks';
import { AcknowledgementResponse } from '../../../@types/api';

type UseCampaignErrorNotificationReturnType = {
  deleteAccountAcknowledgement: () => void;
  campaignErrorAcknowledgement: AcknowledgementResponse | null;
};

export const useCampaignErrorNotification = (): UseCampaignErrorNotificationReturnType => {
  const { data: { acknowledgements, id: userId } } = useMyUserSelector();
  const deleteAccountAcknowledgementById = useDeleteAccountAcknowledgementById();
  const { mutate: seeAcknowledgementMutate } = useSeeAcknowledgements();

  const getCampaignErrorAcknowledgement = () => {
    const campaignErrorAcknowledgements = acknowledgements?.filter((ack) => ack.type === AcknowledgementType.CampaignError);

    if (campaignErrorAcknowledgements?.length > 0) {
      return campaignErrorAcknowledgements?.find((ack) => ack.metadata.senderId === userId) || campaignErrorAcknowledgements?.[0];
    }

    return null;
  };

  const campaignErrorAcknowledgement = useMemo(getCampaignErrorAcknowledgement, [acknowledgements, userId]);

  const deleteAccountAcknowledgement = () => {
    if (campaignErrorAcknowledgement?.id) {
      seeAcknowledgementMutate(campaignErrorAcknowledgement.id);
      deleteAccountAcknowledgementById(campaignErrorAcknowledgement.id);
    }
  };

  return {
    deleteAccountAcknowledgement,
    campaignErrorAcknowledgement,
  };
};
