import { userQuery } from '../user.toolkit-api';
import { FIXED_CACHE_KEYS } from '../user.consts';
import { AssignUserRoleQueryArguments } from '../dto/query-arguments/assign-user-role.query-arguments';

type UseFillUserInfoResponseType = [
  (queryArguments: AssignUserRoleQueryArguments) => void,
  AppSelectorType<void, string>
];

export const useAssignUserRoleMutation = (): UseFillUserInfoResponseType => {
  const [
    assignUserRole,
    {
      data, isLoading, isError, error, isSuccess,
    }] = userQuery.useAssignUserRoleMutation({
    fixedCacheKey: FIXED_CACHE_KEYS.ASSIGN_ROLE_TO_USER,
  });

  const meta = {
    isLoading, isError, error: error as string, isSuccess, data,
  };

  return [
    assignUserRole,
    { ...meta },
  ];
};
