import { Avatar } from '@mui/material';
import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { ASSETS } from '../../../../../assets';
import IconButton from '../../../../Common/Buttons/icon-button/icon-button.component';
import { hexToRgbA } from '../../../../../utils/colors';

export const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${THEME.SPACING.TINY};
  border-radius: ${THEME.RADIUS.CIRCLE};
  cursor: default;
`;

export const ActionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${THEME.SPACING.TINY};
  cursor: default;
`;

export const StyledAvatar = styled(Avatar).attrs({
  style: {
    width: '32px',
    height: '32px',
    display: 'inline-block',
    verticalAlign: 'middle',
  },
})`
`;

export const ActionsMenuButtonIcon = styled(ASSETS.HORI_DOTS)`
  height: 32px;
  width: 16px;
  transform: rotate(-90deg);
`;

export const ActionsMenuButton = styled(IconButton)`
  color: ${THEME.COLORS.CONTENT_A};
  width: 24px;
  height: 24px;
  border-radius: ${THEME.RADIUS.BASE};
  &:hover {
    color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
    span {
      color: ${THEME.COLORS.ACCENT} !important;
    }
  }
`;

export const EmptyActionsMenuButton = styled.div`
  width: 24px;
  height: 24px;
`;
