import React, { useEffect } from 'react';
import { getInitials } from '../../../utils';
import { getRandomCensoredAvatarUrl } from '../../../utils/url';
import {
  Container, FallbackImageContainer, Initials, StyledImage,
} from './styled-avatar.styled';

const StyledAvatar = ({
  image,
  name,
  outlineWidthPx = 1,
  outlineColor,
  borderWidthPx = 8,
  borderColor,
  borderRadiusPx,
  initialsFontSizePx,
  width,
  height,
  className,
  spacing,
}: StyledAvatarProps): JSX.Element => {
  const initials = getInitials(name);
  const [fallbackBackgroundImage, setFallbackBackgroundImage] = React.useState<string>();

  useEffect(() => {
    setFallbackBackgroundImage(getRandomCensoredAvatarUrl());
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);

  return (
    <Container
      className={ className }
      $outlineWidth={ outlineWidthPx }
      $outlineColor={ outlineColor }
      $borderWidth={ borderWidthPx }
      $borderColor={ borderColor }
      $borderRadiusPx={ borderRadiusPx }
      $spacing={ spacing }
      width={ width }
      height={ height }
    >
      {image ? (
        // If an image is provided, display it
        <StyledImage src={ image } alt={ name } title={ name } />
      ) : (
        // Fallback: background image and initials
        <FallbackImageContainer backgroundImage={ fallbackBackgroundImage }>
          <Initials fontSize={ `${initialsFontSizePx}px` }>{initials}</Initials>
        </FallbackImageContainer>
      )}
    </Container>
  );
};

export default StyledAvatar;
