import { CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';

export const enableIsUnsavedChangesReducer: CaseReducer<AppState> = (state) => {
  state.isUnsavedChanges = true;
};

export const disableIsUnsavedChangesReducer: CaseReducer<AppState> = (state) => {
  state.isUnsavedChanges = false;
};
