import { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { isEqual } from 'lodash';
import StepContainer from '../step-container/step-container.component';
import { LevelOfExperience } from '../../../../../../enums/level-of-experience.enum';
import STRING_KEYS from '../../../../../../language/keys';
import LevelOfExperienceContainer
  from '../../../../../../components/level-of-experience-container/level-of-experience-container.component';
import { ANALYTICS_EVENTS } from '../../../../../../consts/analytics';
import { LevelOfExperienceStepProps } from './level-of-experience-step';
import { SubtitleWrapper } from './level-of-experience-step.styled';
import RelevanceMenuButton from './relevance-menu-button/relevance-menu-button.component';

const LevelOfExperienceStep = ({
  selectedRelevantLevelsOfExperience,
  selectedOverallLevelsOfExperience,
  relevantLevelsOfExperienceChangeHandler,
  overallLevelsOfExperienceChangeHandler,
  backButtonClickHandler,
}: LevelOfExperienceStepProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const selectedLevelsOfExperience = selectedRelevantLevelsOfExperience && selectedRelevantLevelsOfExperience?.length > 0 ?
    selectedRelevantLevelsOfExperience : selectedOverallLevelsOfExperience;
  const [selectedLevelsOfExperienceSet, setSelectedLevelsOfExperience] = useState(new Set<LevelOfExperience>(selectedLevelsOfExperience));
  const [isRelevantLevelsOfExperience, setIsRelevantLevelsOfExperience] = useState<boolean>(!!selectedRelevantLevelsOfExperience?.length);

  const isContinueButtonDisabled = useMemo(
    () => (
      !!selectedRelevantLevelsOfExperience?.length === isRelevantLevelsOfExperience &&
      isEqual(Array.from(selectedLevelsOfExperienceSet), selectedLevelsOfExperience)
    ) || !selectedLevelsOfExperienceSet.size,
    [isRelevantLevelsOfExperience, selectedLevelsOfExperience, selectedLevelsOfExperienceSet, selectedRelevantLevelsOfExperience?.length],
  );

  const Subtitle = useMemo(() => (
    <SubtitleWrapper>
      <Trans
        i18nKey={ STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.SUBTITLE }
        values={ {
          relevanceType: translate(
            isRelevantLevelsOfExperience ?
              STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANT_LABEL :
              STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.OVERALL_LABEL,
          ),
        } }
      >
        <RelevanceMenuButton
          isRelevantLevelsOfExperience={ isRelevantLevelsOfExperience }
          relevanceTypeChangeHandler={ setIsRelevantLevelsOfExperience }
        />
      </Trans>
    </SubtitleWrapper>
  ), [isRelevantLevelsOfExperience, translate]);

  const onContinueButtonClick = () => {
    const levelsOfExperience = Array.from(selectedLevelsOfExperienceSet);
    if (isRelevantLevelsOfExperience) {
      // The order is important, since the null that we send will affect the
      // last update of the state to disable the button of Confirm & Match.
      overallLevelsOfExperienceChangeHandler(null);
      relevantLevelsOfExperienceChangeHandler(levelsOfExperience);
    } else {
      relevantLevelsOfExperienceChangeHandler(null);
      overallLevelsOfExperienceChangeHandler(levelsOfExperience);
    }
  };

  return (
    <>
      <StepContainer
        title={ translate(STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.TITLE) }
        subtitle={ Subtitle }
        backButtonHandler={ backButtonClickHandler }
        continueButtonHandler={ onContinueButtonClick }
        isContinueButtonDisabled={ isContinueButtonDisabled }
        eventName={ ANALYTICS_EVENTS.MATCH_TUNE_LEVELS_OF_EXPERIENCE_STEP_VIEWED }
      >
        <LevelOfExperienceContainer
          selectedItems={ selectedLevelsOfExperienceSet }
          changeHandler={ setSelectedLevelsOfExperience }
        />
      </StepContainer>
    </>
  );
};

export default LevelOfExperienceStep;
