import { ApiError } from '../../../../../consts/api';
import { positionQuery } from '../position.toolkit-api';
import { PositionResponse } from '../dto/response/position.response';
import { UpdatePositionUserRolesQueryArguments } from '../dto/query-arguments/update-position-user-roles.query-arguments';

type UseUpdatePositionMutationType = [
  (args: UpdatePositionUserRolesQueryArguments) => void,
  AppSelectorType<PositionResponse, ApiError>
];

export const useUpdatePositionUserAssignMutation = (): UseUpdatePositionMutationType => {
  const [
    updatePosition,
    {
      data, isLoading, isError, error, isSuccess, reset,
    }] = positionQuery.useUpdatePositionUserRolesMutation();

  const meta = {
    isLoading, isError, error: error as ApiError, isSuccess, data, resetState: reset,
  };

  return [
    updatePosition,
    { ...meta },
  ];
};
