import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  Subtitle2Font,
  Title3Font,
} from '../../../../themes/fonts';
import { ASSETS, FLUENT_UI_EMOJIS } from '../../../../assets';
import { HEADER_CONTAINER_HEIGHT_PX } from '../homepage-header/homepage-header.styled';
import { hexToRgbA } from '../../../../utils/colors';
import BaseButton from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.component';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${HEADER_CONTAINER_HEIGHT_PX}px - 150px);
  gap: ${THEME.SPACING.BASE};
`;

export const Title = styled.div`
  ${Title3Font};
  display: flex;
`;

export const Description = styled.div`
  ${Subtitle2Font};
  display: flex;
  width: 400px;
  text-align: center;
  line-height: 1.57;
  letter-spacing: 0.25px;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;

export const CactusIcon = styled(FLUENT_UI_EMOJIS.CACTUS)`
  width: 64px;
  height: 64px;
`;

export const CreatePositionButton = styled(BaseButton)`
  background-color: ${THEME.COLORS.PURE_WHITE};
  color: ${THEME.COLORS.ACCENT};
`;

export const CreatePositionButtonIcon = styled(ASSETS.FILLED_PLUS)`
  fill: ${THEME.COLORS.ACCENT};
  height: 24px;
  width: 24px;
`;
