import styled from 'styled-components';
import { THEME } from '../../../../../themes';
import { CaptionFont } from '../../../../../themes/fonts';

export const Container = styled.div`
  ${CaptionFont};
`;

export const DisconnectLink = styled.span`
  cursor: pointer;
  color: ${THEME.COLORS.ACCENT};
`;

export const ContactText = styled.span`
  margin-left: 4px;
`;

export const Underline = styled.span`
  text-decoration: underline;
`;

export const SemiBold = styled.span`
  font-weight: 500;
`;
