import styled  from 'styled-components';

export const LayoutContainer = styled.div<{ globalHeaderHeight: number }>`
  height: 100vh;
  position: relative;
  padding-top: ${({ globalHeaderHeight }) => `${globalHeaderHeight}px`};
`;

export const Body = styled.div<{ globalHeaderHeight: number }>`
  height: ${({ globalHeaderHeight }) => `calc(100vh - ${globalHeaderHeight}px)`};
  display: flex;
  flex-direction: column;
`;

export const LayoutHeader = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const SelectionGroupContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
`;
