import { createSlice } from '@reduxjs/toolkit';
import { userQuery } from '../../rtk-query/apis/user/user.toolkit-api';
import { initialUserPermissions } from './user-permissions-state.initial-state';
import { addUserPermissionsFulfilledExtraReducer } from './extra-reducers/user-permissions.reducers';

const userPermissionsSlice = createSlice({
  name: 'user-permissions',
  initialState: initialUserPermissions,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addMatcher(userQuery.endpoints.getMyUser.matchFulfilled, addUserPermissionsFulfilledExtraReducer);
  },
});

export default userPermissionsSlice.reducer;
