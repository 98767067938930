import styled from 'styled-components';
import { THEME } from '../../../themes';
import { hexToRgbA } from '../../../utils/colors';

// Main container for the avatar
export const Container = styled.div<{
  $outlineWidth?: number;
  $outlineColor?: string;
  $borderWidth?: number;
  $borderColor?: string;
  $borderRadiusPx?: number;
  $spacing?: number;
  width: number;
  height: number;
}>`
  position: relative;
  display: inline-block;
  width: ${({ width }) => `${width}px`};
  height: ${({ height }) => `${height}px`};

  /* Dynamic outline */
  outline: ${({ $outlineWidth, $outlineColor }) => $outlineWidth === 0 ?
    'none' :
    `${$outlineWidth}px solid ${
      $outlineColor || hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)
    }`};

  /* Dynamic border */
  border: ${({ $borderWidth }) => $borderWidth === 0 ? 'none' : `${$borderWidth}px solid ${THEME.COLORS.PURE_WHITE}`};

  /* Adjust border-radius if provided (this is for flexibility) */
  border-radius: ${({ $borderRadiusPx }) => $borderRadiusPx != null ? `${$borderRadiusPx}px` : '50%'};

  overflow: hidden; /* Ensures image doesn't overflow out of the circle */
  
  flex-shrink: 0;
  margin-inline-start: ${({ $spacing }) => $spacing ? `-${$spacing}px` : '0px'};
`;

// Styled component for the image to fit inside the circle
export const StyledImage = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover; /* Ensures the image covers the container without stretching */
  border-radius: 50%; /* Ensures the image is also circular */
`;

// Container for the fallback (initials + background image)
export const FallbackImageContainer = styled.div<{ backgroundImage?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-image: ${({ backgroundImage }) => `url(${backgroundImage})`};
  background-size: cover;
  background-position: center;
  background-color: ${THEME.COLORS.LIGHT_GREY}; /* Fallback color if no image */
`;

// Style for initials (centered text)
export const Initials = styled.span<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '24px'};
  font-weight: bold;
  color: ${THEME.COLORS.PURE_WHITE}; /* White initials by default */
  text-transform: uppercase; /* Make initials uppercase */
`;
