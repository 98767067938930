import { useTranslation } from 'react-i18next';
import BaseMenu from '../../../Common/perfect-base-menu/perfect-base-menu.component';
import { PerfectMenuSize } from '../../../Common/perfect-base-menu/perfect-base-menu.enums';
import { Divider } from '../../../Common/Divider';
import STRING_KEYS from '../../../../language/keys';
import { UserRoleName } from '../../../../enums/user-role-name.enum';
import { RoleMenuItem } from './roles-menu-item/role-menu-item.component';
import {
  AdminIcon, Description, ManagerIcon, MemberIcon, MenuHeader, Title,
} from './roles-menu.styled';
import { RoleMenuProps } from './roles-menu';

export const RolesMenu = ({
  anchorElement,
  onSelectRoleHandler,
  handleMenuClose,
}: RoleMenuProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <>
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ handleMenuClose }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
        size={ PerfectMenuSize.Custom }
      >
        <MenuHeader>
          <Title>{ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.HEADER.TITLE) }</Title>
          <Description>{ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.HEADER.DESCRIPTION) }</Description>
        </MenuHeader>
        <Divider />
        <RoleMenuItem
          onClick={ () => onSelectRoleHandler(UserRoleName.Admin) }
          Icon={ <AdminIcon /> }
          title={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.ADMIN.TITLE) }
          description={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.ADMIN.DESCRIPTION) }
        />
        <Divider />
        <RoleMenuItem
          onClick={ () => onSelectRoleHandler(UserRoleName.Creator) }
          Icon={ <ManagerIcon /> }
          title={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.CREATOR.TITLE) }
          description={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.CREATOR.DESCRIPTION) }
        />
        <Divider />
        <RoleMenuItem
          onClick={ () => onSelectRoleHandler(UserRoleName.Collaborator) }
          Icon={ <MemberIcon /> }
          title={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.COLLABORATOR.TITLE) }
          description={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU.COLLABORATOR.DESCRIPTION) }
        />
      </BaseMenu>
    </>
  );
};
