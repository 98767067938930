import { CaseReducer, PayloadAction } from '@reduxjs/toolkit';
import { UserPermissionsState, initialUserPermissions } from '../user-permissions-state.initial-state';
import { UserInfoResponse } from '../../../rtk-query/apis/user/dto/response/user-info.response';

export const addUserPermissionsFulfilledExtraReducer: CaseReducer<UserPermissionsState, PayloadAction<UserInfoResponse>> = (state, { payload }) => {
  return {
    ...initialUserPermissions,
    permissions: payload.userPermissions,
  };
};
