import { useTranslation } from 'react-i18next';
import { forwardRef, Ref } from 'react';
import STRING_KEYS from '../../../../language/keys';
import {
  Placeholder, SelectedRole, ButtonContainer, ArrowIcon, CloseIcon, IconContainer,
} from './roles-menu-button.styled';
import { RolesMenuButtonProps } from './roles-menu-button';
import { ButtonVariant } from './roles-menu-button.enums';

export const RolesMenuButton = forwardRef((
  props: RolesMenuButtonProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    isActive, selectedRole, onClickOpenRoleMenuHandler, variant = ButtonVariant.Outlined, disabled,
  } = props;
  const { t: translate } = useTranslation();

  const renderSelectedRoleOrPlaceholder = (): JSX.Element => {
    if (selectedRole && !isActive) {
      return <SelectedRole variant={ variant }>{translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLE_NAME[selectedRole])}</SelectedRole>;
    }
    const placeholderKey = isActive ?
      STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU_BUTTON.PLACEHOLDER.ACTIVE : STRING_KEYS.INVITE_YOUR_TEAMMATES.ROLES_MENU_BUTTON.PLACEHOLDER.REGULAR;

    return <Placeholder variant={ variant } isActive={ isActive }>{ translate(placeholderKey) }</Placeholder>;
  };

  const onClickOpenRoleMenu = (): void => {
    if (disabled) {
      return;
    }

    onClickOpenRoleMenuHandler();
  };

  return (
    <ButtonContainer
      variant={ variant }
      isActive={ isActive }
      onClick={ onClickOpenRoleMenu }
      ref={ ref }
      disabled={ disabled }
    >
      {renderSelectedRoleOrPlaceholder()}
      <IconContainer>
        { isActive ? <CloseIcon /> : <ArrowIcon /> }
      </IconContainer>
    </ButtonContainer>
  );
});

RolesMenuButton.displayName = 'RolesMenuButton';
