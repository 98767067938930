/* eslint-disable react/jsx-props-no-spreading */
import { Checkbox } from '@mui/material';
import { hexToRgbA } from '../../../utils/colors';
import { THEME } from '../../../themes';
import { PerfectCheckboxProps } from './perfect-checkbox';

const PerfectCheckbox = ({
  checked, clickHandler, sx, disabled,
}: PerfectCheckboxProps): JSX.Element => {
  return (
    <Checkbox
      checked={ checked }
      onClick={ clickHandler }
      disabled={ disabled }
      sx={ {
        ...sx,
        color: hexToRgbA(THEME.COLORS.CONTENT_PRIMARY, THEME.OPACITY.LOW),
        '&.Mui-checked': {
          color: THEME.COLORS.ACCENT,
        },
      } }
    />
  );
};

export default PerfectCheckbox;
