import styled from 'styled-components';
import MenuItem from '../../../Common/perfect-base-menu/menu-item/menu-item.component';
import { THEME } from '../../../../themes';
import { hexToRgbA } from '../../../../utils/colors';
import { ASSETS } from '../../../../assets';

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM};
  height: unset;
  max-width: unset;
  gap: ${THEME.SPACING.BASE};
  :hover,
  :focus {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
    color: inherit;
  }
`;

export const RemoveIcon = styled(ASSETS.TRASH_BASKET)`
  fill: currentColor;
  width: 24px;
  height: 32px;
`;

export const ResendIcon = styled(ASSETS.UNREAD_EMAIL)`
  fill: currentColor;
  width: 24px;
  height: 32px;
`;
