import { createApi } from '@reduxjs/toolkit/query/react';
import { axiosBaseQuery } from '../../utils/base-query';
import { sortPositionsByState } from '../../../../utils/positions';
import { PositionState } from '../../../../enums/position-state.enum';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import { socketTalentAcquisitionDisconnected, socketTalentAcquisitionConnected } from '../../../slices/app-state/app-state.toolkit-slice';
import { matchQuery } from '../match/match.toolkit-api';
import { CreatePositionResponse } from '../../../../modules/create-position/@types/create-position-response';
import { PositionAssistantTypeEnum } from '../../../../enums/position-assistant-type.enum';
import { PositionAssistantTaskEnum } from '../../../../enums/position-assistant-task.enum';
import { PositionAssistantMilestoneEnum } from '../../../../enums/position-assistant-milestone.enum';
import {
  closePositionQuery,
  createPositionQuery,
  getPositionAnalyticsQuery,
  getPositionByIdQuery,
  getPositionInsightsQuery,
  getPositionRegions,
  getOpenPositionsQuery,
  getPositionStatisticsQuery,
  linkPositionToAtsQuery,
  updatePositionAtsIntegrationQuery,
  updatePositionQuery,
  updatePositionUserRolesQuery,
  createPositionFeedbackQuery,
  getSimilarTitles,
  skipMatchTuneQuery,
  getPositionOverviewQuery, skipPositionAssistantQuery,
} from './position.toolkit-queries';
import { REDUCER_PATH } from './position.consts';
import { PositionResponse } from './dto/response/position.response';
import { getPositionsResponseTransformer } from './transformers/position-response.transformer';
import { CreatePositionRequest } from './dto/request/create-position.request';
import { matchPublishedPositionsCacheWebsocketListener, matchPublishedPositionCacheWebsocketListener } from './websocket-listeners/match-published.wbsocket-listeners';
import {
  matchFlowFinishedPositionCacheWebsocketListeners,
  matchFlowFinishedPositionsCacheWebsocketListeners,
} from './websocket-listeners/match-flow-finished.websocket-listeners';
import {
  matchFlowFailedPositionCacheWebsocketListeners,
  matchFlowFailedPositionsCacheWebsocketListeners,
} from './websocket-listeners/match-flow-failed.websocket-listeners';
import { getPositionByIdResponseTransformer } from './transformers/position-by-id-response.transformer';
import { PositionStatisticsResponse } from './dto/response/position-statistics.response';
import { updatePositionUserRoles } from './position.toolkit-api-utils';
import { PositionRegionsResponse } from './dto/response/position-regions.response';
import { CreatePositionFeedbackQueryArguments } from './dto/query-arguments/create-position-feedback.query-arguments';
import { SimilarTitlesResponse } from './dto/response/similar-titles.response';
import { GetSimilarTitlesQueryArguments } from './dto/query-arguments/get-similar-titles.query-arguments';
import { PositionOverviewResponse } from './dto/response/position-overview.response';
import { AssistantOption } from './dto/response/position-assistant.response';

export const positionQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  endpoints: (builder) => ({
    createPosition: builder.mutation<CreatePositionResponse, CreatePositionRequest>({
      query: createPositionQuery,
    }),
    getPositionOverview: builder.query<PositionOverviewResponse, string>({
      query: getPositionOverviewQuery,
    }),
    getOpenPositions: builder.query<PositionResponse[], void>({
      query: getOpenPositionsQuery,
      transformResponse: getPositionsResponseTransformer,
      async onCacheEntryAdded(
        arg,
        { updateCachedData: positions, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(SocketEventName.MatchFlowFinishedSuccessfully, (socketPayload) => matchFlowFinishedPositionsCacheWebsocketListeners(socketPayload, positions));
          socket.on(SocketEventName.MatchPublished, (socketPayload) => matchPublishedPositionsCacheWebsocketListener(socketPayload, positions));
          socket.on(SocketEventName.MatchFlowFailed, (socketPayload) => matchFlowFailedPositionsCacheWebsocketListeners(socketPayload, positions));
        }
      },
    }),
    getPositionById: builder.query<PositionResponse, string>({
      query: getPositionByIdQuery,
      transformResponse: getPositionByIdResponseTransformer,
      async onCacheEntryAdded(
        arg,
        { updateCachedData: position, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.TalentAcquisition,
          onSocketConnected: () => {
            dispatch(socketTalentAcquisitionConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketTalentAcquisitionDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(SocketEventName.MatchPublished, (socketPayload) => matchPublishedPositionCacheWebsocketListener(socketPayload, position));
          socket.on(SocketEventName.MatchFlowFinishedSuccessfully, (socketPayload) => matchFlowFinishedPositionCacheWebsocketListeners(socketPayload, position));
          socket.on(SocketEventName.MatchFlowFailed, (socketPayload) => matchFlowFailedPositionCacheWebsocketListeners(socketPayload, position));
        }
      },
    }),
    // TODO [refactor] should add return value type and transformers if needed
    getPositionInsights: builder.query({
      query: getPositionInsightsQuery,
    }),
    /** @deprecated - use overview api */
    getPositionStatistics: builder.query<PositionStatisticsResponse, string>({
      query: getPositionStatisticsQuery,
    }),
    // TODO [refactor] should add return value type and transformers if needed
    getPositionAnalytics: builder.query({
      query: getPositionAnalyticsQuery,
    }),
    getPositionRegions: builder.query<PositionRegionsResponse, void>({
      query: getPositionRegions,
    }),
    getSimilarTitles: builder.query<SimilarTitlesResponse, GetSimilarTitlesQueryArguments>({
      query: getSimilarTitles,
    }),
    // TODO [refactor] should add return value type and transformers if needed
    closePosition: builder.mutation({
      query: closePositionQuery,
      async onQueryStarted(
        { positionId }, { dispatch, queryFulfilled },
      ) {
        const dispatchResult = dispatch(
          positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
            const foundPosition = draft.find((position) => position.id === positionId);
            if (foundPosition) {
              foundPosition.state = PositionState.Closed;
              sortPositionsByState(draft);
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatchResult.undo();
        }
      },
    }),
    skipPositionAssistant: builder.mutation({
      query: skipPositionAssistantQuery,
      async onQueryStarted(
        { positionId, name, type }, { dispatch, queryFulfilled },
      ) {
        const dispatchResult = dispatch(
          positionQuery.util.updateQueryData('getPositionOverview', positionId, (draft) => {
            let assistants: AssistantOption<string>[] = type === PositionAssistantTypeEnum.Task ? draft.assistant?.tasks : draft.assistant?.milestones;
            assistants = assistants?.filter((assistant) => assistant.name !== name) || [];
            if (type === PositionAssistantTypeEnum.Task) {
              draft.assistant.tasks = [...assistants] as AssistantOption<PositionAssistantTaskEnum>[];
            } else {
              draft.assistant.milestones = [...assistants] as AssistantOption<PositionAssistantMilestoneEnum>[];
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          dispatchResult.undo();
        }
      },
    }),
    linkPositionToAts: builder.mutation({
      query: linkPositionToAtsQuery,
    }),
    updatePositionAtsIntegration: builder.mutation({
      query: updatePositionAtsIntegrationQuery,
    }),
    updatePosition: builder.mutation({
      query: updatePositionQuery,
      async onQueryStarted(
        { positionId, anonymizeTalentDetails }, { dispatch, queryFulfilled },
      ) {
        const toReviewMatchesDispatchResult = dispatch(
          matchQuery.util.updateQueryData('getPendingReviewMatches', { positionId }, (draft) => {
            draft.results = [];
            draft.totalCount = 0;
            draft.page = 0;
            draft.limit = 0;
          }),
        );
        const positionDispatchResult = dispatch(
          positionQuery.util.updateQueryData('getPositionById', positionId, (position) => {
            position.matches = [];
            position.hasReachedMatchTuneThreshold = false;

            if (position.anonymizeTalentDetails !== undefined) {
              position.anonymizeTalentDetails = anonymizeTalentDetails;
            }
          }),
        );
        const positionsDispatchResult = dispatch(
          positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
            const foundPosition = draft.find((position) => position.id === positionId);
            if (foundPosition) {
              foundPosition.matches = [];
              foundPosition.hasReachedMatchTuneThreshold = false;
            }
          }),
        );

        try {
          const { data: updatedPosition } = await queryFulfilled;
          dispatch(
            positionQuery.util.updateQueryData('getPositionById', positionId, (draft) => {
              draft.hasReachedMatchTuneThreshold = updatedPosition.hasReachedMatchTuneThreshold;
              draft.anonymizeTalentDetails = updatedPosition.anonymizeTalentDetails;
            }),
          );
          dispatch(
            positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
              const foundPosition = draft.find((position) => position.id === positionId);
              if (foundPosition) {
                foundPosition.hasReachedMatchTuneThreshold = updatedPosition.hasReachedMatchTuneThreshold;
              }
            }),
          );
        } catch (e) {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
          toReviewMatchesDispatchResult?.undo();
        }
      },
    }),
    updatePositionUserRoles: builder.mutation({
      query: updatePositionUserRolesQuery,
      async onQueryStarted(
        {
          positionId, recruiters, hiringManagers, requestType,
        }, { dispatch, queryFulfilled },
      ) {
        let positionDispatchResult;
        let positionsDispatchResult;
        if (recruiters || hiringManagers) {
          positionDispatchResult = dispatch(
            positionQuery.util.updateQueryData('getPositionById', positionId, (position) => updatePositionUserRoles(position, requestType, recruiters, hiringManagers)),
          );
          positionsDispatchResult = dispatch(
            positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
              const foundPosition = draft.find((position) => position.id === positionId);
              if (foundPosition) {
                updatePositionUserRoles(foundPosition, requestType, recruiters, hiringManagers);
              }
            }),
          );
        }

        try {
          await queryFulfilled;
        } catch {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
        }
      },
    }),
    createPositionFeedback: builder.mutation<void, CreatePositionFeedbackQueryArguments>({
      query: createPositionFeedbackQuery,
    }),
    skipMatchTune: builder.mutation<void, string>({
      query: skipMatchTuneQuery,
      async onQueryStarted(
        positionId, { dispatch, queryFulfilled },
      ) {
        const positionDispatchResult = dispatch(
          positionQuery.util.updateQueryData('getPositionById', positionId, (position) => {
            position.hasReachedMatchTuneThreshold = false;
          }),
        );
        const positionsDispatchResult = dispatch(
          positionQuery.util.updateQueryData('getOpenPositions', undefined, (draft) => {
            const foundPosition = draft.find((position) => position.id === positionId);
            if (foundPosition) {
              foundPosition.hasReachedMatchTuneThreshold = false;
            }
          }),
        );

        try {
          await queryFulfilled;
        } catch {
          positionDispatchResult?.undo();
          positionsDispatchResult?.undo();
        }
      },
    }),
  }),
});
