/* eslint-disable react/jsx-props-no-spreading */
import { Link } from 'react-router-dom';
import { useState } from 'react';
import UserProfile from '../../user-profile/user-profile.component';
import { AppRouting } from '../../../../services/appRoutingResolver';
import UserRoleChangedBanner from '../../../user-role-changed-banner/user-role-changed-banner.component';
import { useAppState } from '../../../../store/selectors';
import Portal from '../../Portal/portal.commponent';
import InviteColleagueModal from '../../../dialogs/invite-colleague-modal/invite-colleague-dialog.component';
import { HeaderProps } from './header';
import {
  HeaderContainer,
  HeaderContentContainer,
  LeftContainer,
  PerfectIcon,
  RightContainer,
} from './header.styled';

const CONTAINER_ID = 'header_global_portal';

const Header = ({ children, isHeaderSticky, ...otherProps }: HeaderProps): JSX.Element => {
  const {
    userRoleWasChanged,
  } = useAppState();
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);

  return (
    <>
      <Portal containerId={ CONTAINER_ID } isGlobalPortal removeAfterUnmount={ false }>
        <HeaderContainer { ...otherProps }>
          { userRoleWasChanged && <UserRoleChangedBanner /> }
          <HeaderContentContainer isHeaderSticky={ isHeaderSticky }>
            <LeftContainer>
              <Link to={ { pathname: AppRouting.ROUTES.HOME } }>
                <PerfectIcon />
              </Link>
            </LeftContainer>
            { children }
            <RightContainer>
              <UserProfile
                onPeopleItemClickHandler={ () => setIsInviteUsersModalOpen(true) }
              />
            </RightContainer>
          </HeaderContentContainer>
        </HeaderContainer>
      </Portal>
      { isInviteUsersModalOpen && (
        <InviteColleagueModal
          isOpen={ isInviteUsersModalOpen }
          closeHandler={ () => setIsInviteUsersModalOpen(false) }
        />
      ) }
    </>
  );
};

export default Header;
