import { Trans, useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useEffect, useState } from 'react';
import STRING_KEYS from '../../../../language/keys';
import { COMPONENT_NAME, EVENT_GROUP } from '../../../../consts/analytics';
import { PAGE_NAMES } from '../../../../consts/pages';
import { CREATE_POSITION_ANALYTICS_EVENTS } from '../../consts/position-analytics.consts';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import {
  PageTitleBold,
} from '../create-position-container/create-position-container.styled';
import { CreatePositionContainer } from '../create-position-container/create-position-container.component';
import { useCreatePositionLevelsOfExperienceSelector } from '../../../../store/slices/create-position-state/selectors/create-position-level-experience.selector';
import { useGetStepInfoComponent } from '../../hooks/create-position-actions.hooks';
import { LevelOfExperience } from '../../../../enums/level-of-experience.enum';
import LevelOfExperienceContainer
  from '../../../../components/level-of-experience-container/level-of-experience-container.component';
import {
  setRelevantLevelsOfExperience,
  setOverallLevelsOfExperience,
} from '../../../../store/slices/create-position-state/create-position-state.toolkit-slice';
import { RelevanceMenuButtonCustom } from './create-position-experience-step.styled';

type CreatePositionExperienceProps = {
  stepNumber: number,
  setContinueButtonEnable: () => unknown,
  setContinueButtonDisable: () => unknown,
  setContinueButtonShow: () => unknown,
  isOnboarding?: boolean,
}

export const CreatePositionExperienceStep = ({
  stepNumber,
  setContinueButtonEnable,
  setContinueButtonDisable,
  setContinueButtonShow,
  isOnboarding,
}: CreatePositionExperienceProps): JSX.Element => {
  const dispatch = useDispatch();
  const { t: translate } = useTranslation();
  const InfoComponent = useGetStepInfoComponent();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { selectedRelevantLevelsOfExperience, selectedOverallLevelsOfExperience } = useCreatePositionLevelsOfExperienceSelector();
  const selectedLevelsOfExperience = selectedRelevantLevelsOfExperience.length > 0 ? selectedRelevantLevelsOfExperience : selectedOverallLevelsOfExperience;
  const [selectedLevelsOfExperienceSet, setSelectedLevelsOfExperience] = useState(new Set<LevelOfExperience>(selectedLevelsOfExperience));
  const [isRelevantLevelsOfExperience, setIsRelevantLevelsOfExperience] = useState<boolean>(true);

  useEffect(() => {
    if (selectedLevelsOfExperience?.length) {
      dispatch(setContinueButtonEnable());
      dispatch(setContinueButtonShow());
    } else {
      dispatch(setContinueButtonDisable());
    }
  }, [dispatch, selectedLevelsOfExperience, setContinueButtonDisable, setContinueButtonEnable, setContinueButtonShow]);

  const onLevelOfExperienceClick = (itemId: LevelOfExperience) => {
    const trackEventPayload = {
      eventName: CREATE_POSITION_ANALYTICS_EVENTS.NEW_POSITION_INSERT_PARAMETER_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      stepNumber,
      pageName: PAGE_NAMES.EXPERIENCE_PAGE_VIEWED,
      componentName: COMPONENT_NAME.EXPERIENCE_LEVEL,
      componentMetadata: itemId,
    };

    analyticsTrackEvent(trackEventPayload);
  };

  const updateLevelOfExperienceState = (isRelevant: boolean, selectedLevelsOfExperienceUpdated: LevelOfExperience[]): void => {
    if (isRelevant) {
      dispatch(setOverallLevelsOfExperience([]));
      dispatch(setRelevantLevelsOfExperience(Array.from(selectedLevelsOfExperienceUpdated)));
    } else {
      dispatch(setRelevantLevelsOfExperience([]));
      dispatch(setOverallLevelsOfExperience(Array.from(selectedLevelsOfExperienceUpdated)));
    }
  };

  const onIsRelevantLevelsOfExperienceChange = (isRelevant: boolean) => {
    setIsRelevantLevelsOfExperience(isRelevant);

    const levelsOfExperience = Array.from(selectedLevelsOfExperienceSet);

    updateLevelOfExperienceState(isRelevant, levelsOfExperience);
  };

  const handleSelectedLevelsOfExperience = (selectedLevelsOfExperienceUpdated: Set<LevelOfExperience>): void => {
    setSelectedLevelsOfExperience(selectedLevelsOfExperienceUpdated);

    updateLevelOfExperienceState(isRelevantLevelsOfExperience, Array.from(selectedLevelsOfExperienceUpdated));
  };

  const getPageTitle = () => (
    <Trans
      i18nKey={ STRING_KEYS.ONBOARDING.EXPERIENCE_PAGE.TITLE }
      values={ {
        relevanceType: translate(
          isRelevantLevelsOfExperience ?
            STRING_KEYS.ONBOARDING.EXPERIENCE_PAGE.RELEVANT_LABEL :
            STRING_KEYS.ONBOARDING.EXPERIENCE_PAGE.OVERALL_LABEL,
        ),
      } }
    >
      <PageTitleBold />
      <RelevanceMenuButtonCustom
        isRelevantLevelsOfExperience={ isRelevantLevelsOfExperience }
        relevanceTypeChangeHandler={ onIsRelevantLevelsOfExperienceChange }
      />
    </Trans>
  );

  return (
    <CreatePositionContainer
      title={ getPageTitle() }
      info={ InfoComponent }
      isOnboarding={ isOnboarding }
    >
      <LevelOfExperienceContainer
        selectedItems={ selectedLevelsOfExperienceSet }
        clickHandler={ onLevelOfExperienceClick }
        changeHandler={ handleSelectedLevelsOfExperience }
      />
    </CreatePositionContainer>
  );
};

CreatePositionExperienceStep.defaultProps = {
  isOnboarding: false,
};
