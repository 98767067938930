export const ONBOARDING_ROUTES = {
  REGISTRATION: '/registration',
  LETS_BEGIN: '/lets-begin',
  /** @deprecated - changed to background */
  JOB_DESCRIPTION: '/job-description',
  EXPERIENCE: '/experience',
  /** @deprecated - changed to background */
  INDUSTRIES: '/industries',
  BACKGROUND: '/background',
  EDUCATION: '/education',
  /** @deprecated - changed to background */
  SKILLS: '/skills',
  EMAIL_VERIFICATION: '/email-verification',
  ONBOARDING_COMPLETED: '/completed',
};

export const ROLES = {
  RECRUITER: 'RECRUITER',
  MANAGER: 'MANAGER',
};

export const ONBOARDING_PAGE_TITLES = {
  REGISTRATION: 'Onboarding - Registration | Perfect',
  LETS_BEGIN: 'Onboarding - Lets Begin | Perfect',
  EXPERIENCE: 'Onboarding - Experience | Perfect',
  INDUSTRIES: 'Onboarding - Industries | Perfect',
  EDUCATION: 'Onboarding - Education | Perfect',
  SKILLS: 'Onboarding - Skills | Perfect',
  JOB_DESCRIPTION: 'Onboarding - Job Description | Perfect',
  EMAIL_VERIFICATION: 'Onboarding - Email Verification | Perfect',
  ONBOARDING_COMPLETED: 'Onboarding - All Done | Perfect',
};

export const PROMO_LOCAL_STORAGE_KEY = 'promo';
