import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../../../language/keys';
import { DisconnectEmailProps } from './disconnect-email';
import {
  ContactText,
  Container, DisconnectLink, Underline,
} from './disconnect-email.styled';

const DisconnectEmail = ({ emailAddress, onClickDisconnectEmail, ableToDisconnect }: DisconnectEmailProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      
      <Trans
        i18nKey={ STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.DESCRIPTION }
        values={ { userEmailAddress: emailAddress } }
      />
      { ableToDisconnect ?
        <DisconnectLink onClick={ onClickDisconnectEmail }><Underline>{translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.LINK)}</Underline></DisconnectLink> :
        <ContactText>{translate(STRING_KEYS.CAMPAIGN_PAGE.DISCONNECT_EMAIL.CONTACT_TO_DISCONNECT)}</ContactText> }
    </Container>
  );
};

export default DisconnectEmail;
