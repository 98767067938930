import styled from 'styled-components';
import { THEME } from '../../themes';
import { FLUENT_UI_EMOJIS } from '../../assets';
import { hexToRgbA } from '../../utils/colors';
import { ButtonFont, Subtitle1Font } from '../../themes/fonts';
import BaseButton from '../Common/Buttons/perfect-base-button/perfect-base-button.component';

export const BANNER_HEIGHT = 56;

export const Container = styled.div`
  height: ${BANNER_HEIGHT}px;
  width: 100%;
  z-index: ${THEME.Z_INDEX.HIGHEST};
  background: ${THEME.COLORS.ACCENT};
`;

export const ContentContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  padding-left: ${THEME.SPACING.MEDIUM};
  padding-right: ${THEME.SPACING.LOOSE};
`;

export const TextContainer = styled.div`
  display: flex;
  height: 100%;
  align-items: center;
  justify-content: space-between;
  gap: ${THEME.SPACING.TINY};
`;

export const Title = styled.span`
  ${Subtitle1Font};
  color: ${THEME.COLORS.PURE_WHITE};
`;

export const Description = styled.span`
  ${Subtitle1Font};
  color: ${hexToRgbA(THEME.COLORS.PURE_WHITE, THEME.OPACITY.MED)};
`;

export const RefreshButton = styled(BaseButton)`
  ${ButtonFont};
  color: ${THEME.COLORS.PURE_WHITE};
`;

export const BellIcon = styled(FLUENT_UI_EMOJIS.BELL)`
  width: 24px;
  height: 24px;
`;
