import { useSelector } from 'react-redux';
import { RootReducerState } from '../../../index';
import { CompleteStepPayload } from '../../../../modules/onboarding/@types/onboarding-step-complete';

export const useCreateOnboardingLevelOfExperienceSelector = (): Partial<CompleteStepPayload> => {
  return useSelector<RootReducerState, Partial<CompleteStepPayload>>(({ createPosition }: RootReducerState) => {
    return {
      levelsOfExperience: createPosition.selectedRelevantLevelsOfExperience,
      overallLevelsOfExperience: createPosition.selectedOverallLevelsOfExperience,
    };
  });
};
