/* eslint-disable react/jsx-props-no-spreading, react/prop-types */
import {
  forwardRef, Ref, useCallback, useEffect, useImperativeHandle, useRef, useState,
} from 'react';
import { usePopper } from 'react-popper';
import useClickOutsideListener from '../../../hooks/use-click-outside-listener.hooks';
import Portal from '../Portal/portal.commponent';
import { Keymap } from '../../../consts/keyboard_keys';
import {
  Menu,
  MenuContainer,
  TitleContainer,
} from './perfect-base-menu.styled';
import { BaseMenuProps } from './perfect-base-menu';
import { PerfectMenuSize, PerfectMenuVariants } from './perfect-base-menu.enums';
import { ITEM_ROLE } from './perfect-base-menu.consts';

const BaseMenu = forwardRef((
  props: BaseMenuProps,
  ref: Ref<HTMLDivElement | undefined>,
) => {
  const {
    isOpen,
    size = PerfectMenuSize.Medium,
    variant = PerfectMenuVariants.Regular,
    anchorElement,
    placement,
    onClose,
    children,
    isSubMenu = false,
    Title,
    disabledArrowNavigation,
    popperModifiers,
    ...otherProps
  } = props;

  const [currentFocus, setCurrentFocus] = useState<NonDocumentTypeChildNode | undefined>();
  const [popperRef, setPopperRef] = useState<HTMLElement | undefined>();
  const [menuContainerRef, setMenuContainerRef] = useState<HTMLElement | undefined>();
  const [isMenuOpen, setIsMenuOpen] = useState(isOpen || false);
  const popperDirection = useRef<string | null>(null);
  const popperPosition = useRef<string | null>(null);

  const combineRefs = useRef<HTMLDivElement>();
  useImperativeHandle(ref, () => combineRefs.current);

  const popper = usePopper(
    anchorElement,
    popperRef,
    {
      placement,
      modifiers: [
        {
          name: 'offset',
          options: {
            offset: [0, isSubMenu ? 0 : 6],
          },
        },
        ...(popperModifiers || []),
      ],
    },
  );

  const { styles: popperStyles, attributes, update: updatePopper } = popper;

  // update menu position when dom change
  useEffect(() => {
    if (updatePopper) {
      const observer = new MutationObserver(() => {
        updatePopper();
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true,
      });

      return () => {
        observer.disconnect();
      };
    }

    return () => undefined;
    // Only when isMenuOpen changed
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMenuOpen]);

  const setMenuRef = (element: HTMLDivElement) => {
    setPopperRef(element);
    combineRefs.current = element;
  };

  const handleOnAnchorClick = useCallback(() => {
    if (isOpen === undefined) {
      setIsMenuOpen(true);
    }
  }, [isOpen]);

  useEffect(() => {
    if (anchorElement) {
      anchorElement.onclick = handleOnAnchorClick;
    }
  }, [anchorElement, handleOnAnchorClick]);

  useEffect(() => {
    if (popperRef) {
      const list = popperRef?.firstChild;
      if (Title) {
        setMenuContainerRef(list?.lastChild as HTMLElement);
      } else {
        setMenuContainerRef(list?.firstChild as HTMLElement);
      }
    }
  }, [Title, popperRef]);

  useEffect(() => {
    setIsMenuOpen(isOpen);
  }, [isOpen]);

  useEffect(() => {
    async function update() {
      if (updatePopper && isOpen) {
        await updatePopper();
      }
    }
    update();
  }, [anchorElement, updatePopper, isOpen]);

  useEffect(() => {
    const [side, position] = placement?.split('-') || [];
    popperDirection.current = side;
    popperPosition.current = position === undefined ? 'center' : position;
  }, [placement]);

  const clickOutsideHandler = (event: MouseEvent) => {
    if (
      !isMenuOpen ||
      (anchorElement && anchorElement.contains(event.target as Node))
    ) {
      return;
    }
    if (isOpen === undefined) {
      setIsMenuOpen(false);
    }
    onClose?.(event);
  };

  useClickOutsideListener(
    clickOutsideHandler,
    combineRefs.current,
  );

  const getNextElement = (isArrowDown: boolean): HTMLElement => {
    if (!currentFocus) {
      return (isArrowDown ? menuContainerRef?.firstChild : menuContainerRef?.lastChild) as HTMLElement;
    }
    let nextElement = isArrowDown ? currentFocus?.nextElementSibling : currentFocus?.previousElementSibling;
    while (nextElement && nextElement?.getAttribute('role') !== ITEM_ROLE) {
      nextElement = isArrowDown ? nextElement?.nextElementSibling : nextElement?.previousElementSibling;
    }

    if (!nextElement) {
      return (isArrowDown ? menuContainerRef?.firstChild : menuContainerRef?.lastChild) as HTMLElement;
    }

    return nextElement as HTMLElement;
  };

  const handleKeyDown = useCallback(
    (event) => {
      if (disabledArrowNavigation) {
        return;
      }

      // TODO - improve navigation
      event.stopPropagation();
      event.preventDefault();

      const nextFocus = (isArrowDown: boolean) => {
        const nextElement = getNextElement(isArrowDown);
        if (!nextElement) {
          anchorElement?.focus();
          setCurrentFocus(undefined);
        } else if (nextElement) {
          const focusItem = nextElement;
          (focusItem as HTMLElement).focus();
          setCurrentFocus(focusItem);
        }
      };

      switch (event.keyCode) {
        case Keymap.ArrowDown:
          nextFocus(true);
          break;
        case Keymap.ArrowUp:
          nextFocus(false);
          break;
        case Keymap.ArrowLeft:
          setCurrentFocus(undefined);
          onClose?.();
          break;
        case Keymap.Escape:
          setCurrentFocus(undefined);
          onClose?.();
          break;
        default:
          break;
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentFocus, onClose, menuContainerRef],
  );

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener('keydown', handleKeyDown, false);
    } else {
      setCurrentFocus(undefined);
      document.removeEventListener('keydown', handleKeyDown);
    }

    return () => document.removeEventListener('keydown', handleKeyDown);
  }, [isMenuOpen, handleKeyDown]);

  const renderMenuList = () => (
    <Menu role="menu" size={ size } isDense={ variant === PerfectMenuVariants.Dense }>
      { Title && <TitleContainer onClick={ (event) => event.stopPropagation() }>{ Title }</TitleContainer> }
      <div role="list">{ children }</div>
    </Menu>
  );

  const renderMenu = () => (
    <MenuContainer
      onKeyDown={ handleKeyDown }
      ref={ setMenuRef }
      style={ popperStyles.popper }
      { ...attributes.popper }
      { ...otherProps }
    >
      { renderMenuList() }
    </MenuContainer>
  );

  const renderMenuInPortal = () => (
    <Portal containerId="menu-container" isGlobalPortal >{renderMenu()}</Portal>
  );

  if (!isMenuOpen) {
    return <></>;
  }

  return (
    (!isSubMenu ? renderMenuInPortal() : renderMenu())
  );
});

BaseMenu.displayName = 'BaseMenu';

export default BaseMenu;
