import { useSelector } from 'react-redux';
import { selectPermissions } from '../store/selectors/user-permissions.selectors';
import { PermissionPlaceholder } from '../enums/permission-placeholder.enum';

type ResolvedPermissions = {
  userPermission: string;
  placeholders: PermissionPlaceholder[] | PermissionPlaceholder.Wildcard; // Resolved placeholders like [MEMBER]
}

interface PermissionResult {
  allowed: boolean;
  resolvedPermissions: ResolvedPermissions;
}

/**
 * A custom hook to check if a user has the required permissions.
 *
 * @param requiredPermissions - List of permissions to check, e.g., ['app:user:*:role:[COLLABORATOR]:/invite', 'app:user:*:role:*:/invite']
 * @returns - An object with `allowed` (boolean) and `resolvedPermissions` (details of matched permissions with placeholders)
 */
export const usePermissionCheck = (requiredPermissions: string[]): PermissionResult => {
  const userPermissions = useSelector(selectPermissions);

  let allowed = false;
  let resolvedPermissions: ResolvedPermissions = {} as ResolvedPermissions;

  requiredPermissions.forEach((requiredPermission) => {
    userPermissions.some((userPermission) => {
      const regex = new RegExp(
        `^${requiredPermission.replace(/:/g, '\\:').replace(/\*/g, '.*')}$`,
      );

      if (regex.test(userPermission)) {
        allowed = true;

        // Extract placeholders like [MEMBER], [ADMIN] from the user permission
        const placeholderRegex = /\[([^\]]+)\]/g;
        const matches: RegExpExecArray[] = [];
        let match = placeholderRegex.exec(userPermission);

        while (match !== null) {
          matches.push(match);
          match = placeholderRegex.exec(userPermission); // Explicitly assign here
        }

        const matchedPlaceholders = matches.map<PermissionPlaceholder>((m) => m[1] as PermissionPlaceholder);
        const placeholders = matchedPlaceholders.length ? matchedPlaceholders : PermissionPlaceholder.Wildcard;

        resolvedPermissions = { userPermission, placeholders };

        return true; // Break the loop since a match was found
      }

      return false; // Continue to the next userPermission
    });
  });

  return { allowed, resolvedPermissions };
};
