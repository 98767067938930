import styled from 'styled-components';
import { THEME } from '../../../../themes';
import { HEADER_CONTAINER_HEIGHT_PX } from '../../components/homepage-header/homepage-header.styled';

export const USER_AVATAR_SIZE_PX = 40;

export const Container = styled.div`
  height: 100vh;
  overflow-y: scroll;
`;

export const BodyContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: calc(100vh - ${HEADER_CONTAINER_HEIGHT_PX}px);
`;

export const PositionsContainer = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  gap: ${THEME.SPACING.MEDIUM};
`;

export const BodyWrapper = styled.div`
  width: ${THEME.BREAKPOINTS.WIDTH.SMALL}px;
  height: 100%;
`;
