import styled, { css } from 'styled-components';
import { hexToRgbA } from '../../../../utils/colors';
import { THEME } from '../../../../themes';
import { TextEllipsis } from '../../../../themes/text';
import { CaptionFont, Subtitle1Font } from '../../../../themes/fonts';
import { ASSETS } from '../../../../assets';
import { ButtonVariant } from './roles-menu-button.enums';

const DisabledVariantCss = css<{ variant: ButtonVariant, isActive?: boolean }>`
  width: 128px;
  height: 32px;
  color: ${THEME.COLORS.TRUE_BLACK};
  cursor: unset;
  & svg {
    visibility: hidden;
  }
`;

const ButtonFilledVariantCss = css<{ variant: ButtonVariant, isActive?: boolean }>`
  width: 128px;
  height: 32px;
  background-color: ${({ isActive }) => isActive ? hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER) : THEME.COLORS.LIGHT_GREY};
  color: ${({ isActive }) => isActive ? THEME.COLORS.ACCENT : THEME.COLORS.TRUE_BLACK};
  & svg {
    visibility: ${({ isActive }) => isActive ? 'visible' : 'hidden'};
  }
  &:hover {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
    color: ${THEME.COLORS.ACCENT};
    & svg {
      visibility: visible;
    }
  }
`;

const ButtonDefaultVariantCss = css<{ variant: ButtonVariant, isActive?: boolean }>`
  border: ${({ isActive }) => isActive ? 'unset' : `1px solid ${hexToRgbA(THEME.COLORS.PRIMARY_DARK, THEME.OPACITY.GHOST)}`};
  background-color: ${({ isActive }) => isActive ? hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER) : THEME.COLORS.PURE_WHITE};
  color: ${({ isActive }) => isActive ? THEME.COLORS.ACCENT : hexToRgbA(THEME.COLORS.TRUE_BLACK, THEME.OPACITY.LOW)};
  width: 140px;
  height: 49px;
`;

const ButtonTitleVariant = css<{ variant: ButtonVariant }>`
  ${({ variant }) => {
    if (variant === ButtonVariant.Filled) {
      return css`
      ${CaptionFont};
  `;
    }

    return css`
      ${Subtitle1Font};
    `;
  }}
`;

export const ButtonContainer = styled.div<{ isActive?: boolean, variant: ButtonVariant, disabled?: boolean }>`
  padding: 0 ${THEME.SPACING.BASE};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: ${THEME.RADIUS.BASE};
  gap: ${THEME.SPACING.TINY};
  cursor: pointer;
  ${({ variant, disabled }) => {
    if (disabled) {
      return DisabledVariantCss;
    }
    
    if (variant === ButtonVariant.Filled) {
      return ButtonFilledVariantCss;
    }
    
    return ButtonDefaultVariantCss;
  }}
`;

export const SelectedRole = styled.div<{ variant: ButtonVariant }>`
  ${TextEllipsis};
  ${ButtonTitleVariant};
  font-weight: bold;
  color: ${THEME.COLORS.TRUE_BLACK};
`;

export const Placeholder = styled.div<{ isActive?: boolean, variant: ButtonVariant }>`
  ${TextEllipsis};
  ${ButtonTitleVariant};
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
`;
export const ArrowIcon = styled(ASSETS.ARROW_DOWN)`
  fill: currentColor;
  width: 20px;
  height: 20px;
`;

export const CloseIcon = styled(ASSETS.CLOSE)`
  fill: ${THEME.COLORS.ACCENT};;
  width: 10px;
`;
