import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { CreatePositionState } from '../create-position-state.initial-state';
import { LevelOfExperience } from '../../../../enums/level-of-experience.enum';

export const setRelevantLevelsOfExperienceReducer: CaseReducer<CreatePositionState, PayloadAction<LevelOfExperience[]>> = (state, { payload }) => {
  state.selectedRelevantLevelsOfExperience = payload;
};

export const setOverallLevelsOfExperienceReducer: CaseReducer<CreatePositionState, PayloadAction<LevelOfExperience[]>> = (state, { payload }) => {
  state.selectedOverallLevelsOfExperience = payload;
};

export const resetExperienceLevelsReducer: CaseReducer<CreatePositionState, PayloadAction<undefined>> = (state) => {
  state.selectedRelevantLevelsOfExperience = [];
};
