import { ONBOARDING_ROUTES } from '../consts/onboarding.const';
import { OnboardingStep } from '../onboarding.enums';

export const buildOnboardingStepPathFromOnboardingStep = (step: string): string => {
  switch (step) {
    default:
    case OnboardingStep.Registration: {
      return ONBOARDING_ROUTES.REGISTRATION;
    }
    case OnboardingStep.LetsBegin:
    case OnboardingStep.TransitionWelcomeUser: {
      return ONBOARDING_ROUTES.LETS_BEGIN;
    }
    case OnboardingStep.Experience: {
      return ONBOARDING_ROUTES.EXPERIENCE;
    }
    case OnboardingStep.Background: {
      return ONBOARDING_ROUTES.BACKGROUND;
    }
    case OnboardingStep.Education: {
      return ONBOARDING_ROUTES.EDUCATION;
    }
    case OnboardingStep.EmailVerification: {
      return ONBOARDING_ROUTES.EMAIL_VERIFICATION;
    }
    case OnboardingStep.OnboardingCompleted: {
      return ONBOARDING_ROUTES.ONBOARDING_COMPLETED;
    }
  }
};

export const prepareOnboardingStepForNavigating = (step: string): string => {
  const route = buildOnboardingStepPathFromOnboardingStep(step);
  const queryParams = window.location.search; // keep query params while navigating

  return `${route}${queryParams}`;
};
