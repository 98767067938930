import axios, {
  AxiosError, AxiosRequestConfig, AxiosResponse,
} from 'axios';
import log from 'loglevel';
import TrackingIdManager from '../services/trackingIdManager';
import AnalyticsIdManager from '../services/analyticsIdManager';
import { CONFIG } from '../config';
import { ApiError, API_HEADERS } from '../consts/api';
import { censorToken } from '../utils';
import { InternalServerErrorCode } from '../enums/internal-server-error-code.enum';

const logger = log.getLogger('AXIOS_INSTANCE');

const axiosInstance = axios.create({
  baseURL: CONFIG.BASE_URL,
  timeout: CONFIG.HTTP_TIMEOUT_SECONDS * 1000,
});

let tokenExpirationTime = '';

// Interfaces

interface CustomAxiosRequestConfig extends AxiosRequestConfig {
  metadata: {
    startTime?: Date,
    endTime?: Date,
  }
}

// Helper Functions

const calculateResponseTime = (requestConfig: CustomAxiosRequestConfig): number => {
  const endTime = requestConfig.metadata.endTime?.getTime();
  const startTime = requestConfig.metadata.startTime?.getTime();

  if (endTime && startTime) {
    return endTime - startTime;
  }

  return -1;
};

// const printResponseTime = (apiConfig: CustomAxiosRequestConfig): void => {
//   const requestConfig = apiConfig as CustomAxiosRequestConfig;
//   requestConfig.metadata.endTime = new Date();
//
//   const responseDuration = calculateResponseTime(requestConfig);
//
//   logger.debug(
//     'The response time from',
//     apiConfig.url,
//     'is',
//     (responseDuration > -1 ? `${responseDuration}ms` : 'unknown response time'),
//   );
// };

// Debugging

// todo: restore isProduction check & move mock logic to somewhere else
// if (!isProduction()) {
axiosInstance.interceptors.request.use(
  (request) => {
    logger.debug('sending new request to server:', {
      requestInfo: {
        ...request,
        headers: '*** CENSORED ***',
        userTokenEnding: censorToken(request?.headers?.common?.Authorization as string, 7),
        tokenExpirationTime,
      },
    });

    // add timestamp to this request
    const requestConfig = request as CustomAxiosRequestConfig;
    requestConfig.metadata = { startTime: new Date() };

    let appMetadataToken;
    try {
      const appMetadata = {
        appVersion: process.env.REACT_APP_VERSION,
      };
      appMetadataToken = Buffer.from(JSON.stringify(appMetadata)).toString('base64');
      // eslint-disable-next-line no-empty
    } catch (e) {}

    // add current session-tracking-id to this request
    // ATTENTION: DON'T USE COMMON HEADERS !!!
    // (tracking-id may change according to user actions in the app - for example - logout and maybe more in the future)
    // keep it always sync by getting the CURRENT ID directly from the manager
    if (requestConfig.headers) {
      requestConfig.headers[API_HEADERS.SESSION_TRACKING_ID] = TrackingIdManager.getId();
      requestConfig.headers[API_HEADERS.ANALYTICS_ID] = AnalyticsIdManager.getId();

      if (appMetadataToken) {
        requestConfig.headers[API_HEADERS.APP_METADATA_TOKEN] = appMetadataToken;
      }
    }

    return requestConfig;
  },
  (error) => {
    logger.warn('failed to send request to server. error:', error);
  },
);

axiosInstance.interceptors.response.use(
  (response) => {
    // the type is *request* config,
    // because we set the meta-data in the request
    // printResponseTime(response.config as CustomAxiosRequestConfig);

    const axiosResponse = response as AxiosResponse;
    let correlationId; let
      censoredToken;
    try {
      correlationId = axiosResponse?.headers['x-correlation-id'];
    } catch (e) {
      logger.warn('Error while parsing correlationId', e);
    }

    try {
      censoredToken = `******************${censorToken(axiosResponse?.config?.headers?.Authorization as string, 14)}`;
    } catch (e) {
      logger.warn('Error while parsing censoredToken', e);
    }

    logger.debug('got response from server. response information:', {
      responseInfo: {
        url: axiosResponse?.config?.url,
        correlationId,
        userTokenEnding: censoredToken,
        tokenExpirationTime,
      },
    });

    return response;
  },
  (errorResponse: AxiosError) => {
    const apiError = errorResponse as ApiError;

    const { response } = apiError;

    const {
      config,
      data,
      headers,
      status,
    } = response || {};

    let correlationId = '';
    try {
      correlationId = headers?.['x-correlation-id'] || '';
    } catch (e) {
      logger.warn('Error while parsing correlationId', e);
    }

    let censoredToken;
    try {
      if (config?.headers?.Authorization) {
        censoredToken = `********************${censorToken(config?.headers?.Authorization as string, 14)}`;
      }
    } catch (e) {
      logger.warn('Error while parsing censoredToken', e);
    }

    let url;
    if (config?.baseURL) {
      url = `${config?.baseURL}${config?.url}`;
    }

    apiError.isApiError = true;
    apiError.error = {
      serverError: {
        internalErrorCode: data?.internalErrorCode || 9999,
        message: data?.message || '',
        fieldsErrors: data?.fieldsErrors || [],
        timestamp: data?.timestamp || new Date().toISOString(),
        path: data?.path || '',
        statusCode: data?.statusCode || 500,

      },
      errorMetadata: {
        url,
        axiosStatusCode: status || -1,
        axiosErrorMessage: apiError.message,
        responseTime: calculateResponseTime(apiError.config as CustomAxiosRequestConfig),
        correlationId,
        censoredToken: censoredToken || '',
        tokenExpirationTime,
        windowNavigatorOnline: window.navigator?.onLine,
      },
    };

    logger.warn('Got a response error', apiError.error);

    if (apiError.error.serverError.internalErrorCode === InternalServerErrorCode.ACCOUNT_PLAN_EXPIRED ||
      apiError.error.serverError.internalErrorCode === InternalServerErrorCode.PREMIUM_FEATURE_ERROR
    ) {
      window.location.reload();
    }

    return Promise.reject(apiError);
  },
);

export const setApiToken = (token: string, expiration: string): void => {
  axiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
  tokenExpirationTime = expiration;
};

export const clearApiToken = (): void => {
  axiosInstance.defaults.headers.common.Authorization = '';
  tokenExpirationTime = '';
};

export default axiosInstance;
