import { outreachQuery } from '../outreach.toolkit-api';
import { ConversationResponseDto } from '../dto/response/conversation.response';
import { useAppSelector } from '../../../../selectors';

export const conversationByIdSelector = outreachQuery.endpoints.getConversationById.select;

export const useGetConversationByIdSelector = (positionId: string, conversationId = ''): AppSelectorType<ConversationResponseDto> => {
  const {
    data, isLoading, isError, isSuccess, error,
  } = useAppSelector(conversationByIdSelector({ conversationId, positionId }));

  return {
    data: data || {} as ConversationResponseDto,
    error,
    isLoading,
    isError,
    isSuccess,
  };
};
