import { useTranslation } from 'react-i18next';
import { useMemo, useState } from 'react';
import CampaignPageBaseOption from '../campaign-base-option/campaign-base-option.component';
import STRING_KEYS from '../../../../../language/keys';
import { ASSETS } from '../../../../../assets';
import { useGetAccountUsersQuery } from '../../../../../store/rtk-query/apis/user/hooks/get-account-users.query-hook';
import UserMenuItem from '../../../../../components/Common/user-menu-item/user-menu-item.component';
import { useMyUserSelector } from '../../../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import {
  InviteTeammateMenuItem,
  InviteTeammateMenuItemIcon,
} from '../../../../../components/Common/user-picker/user-picker.styled';
import InviteColleagueModal
  from '../../../../../components/dialogs/invite-colleague-modal/invite-colleague-dialog.component';
import StyledAvatar from '../../../../../components/Common/styled-avatar/styled-avatar.component';
import {
  Container,
  Icon,
  MenuTitle,
  MenuTitleContainer,
  SelectItemValue,
  StyledSelect,
  StyledSelectItem,
  UserAvatarWrapper,
} from './campaign-auto-mode-email-sender-section.styled';

const CampaignPageAutoModeEmailSenderSection = ({ selectedUserId, changeHandler }: CampaignPageAutoModeEmailSenderSectionProps): JSX.Element => {
  const { t: translate } = useTranslation();
  const { data: users } = useGetAccountUsersQuery();
  const { data: currentUser } = useMyUserSelector();
  const [isInviteUsersModalOpen, setIsInviteUsersModalOpen] = useState(false);

  const PrimarySenderSelectStartAdornment = useMemo(() => {
    const selectedUser = users?.find((user) => user.id === selectedUserId);

    if (!selectedUser) {
      return undefined;
    }

    return (
      <UserAvatarWrapper>
        <StyledAvatar
          image={ selectedUser.profilePictureUrl }
          name={ selectedUser.fullName || '' }
          initialsFontSizePx={ 12 }
          borderWidthPx={ 0 }
          outlineWidthPx={ 0 }
          width={ 40 }
          height={ 40 }
        />
      </UserAvatarWrapper>
    );
  }, [users, selectedUserId]);

  const selectUsersMenuItems = useMemo(() => users.map((user) => (
    <StyledSelectItem
      key={ user.id }
      value={ user.id }
      displayValue={ user.fullName || user.email }
      disabled={ !user.emailConfigurationInfo?.isConfigured }
    >
      <UserMenuItem
        user={ user }
        isCurrentUser={ currentUser.id === user.id }
        subtitleOptions={ {
          showUserVerifiedIndication: true,
          showEmailConfigurationIndication: true,
        } }
      />
    </StyledSelectItem>
  )), [currentUser.id, users]);

  const renderSelectMenuFooter = () => {
    return (
      <InviteTeammateMenuItem onClick={ () => setIsInviteUsersModalOpen(true) } >
        <InviteTeammateMenuItemIcon />
        <div>{ translate(STRING_KEYS.USER_PICKER.INVITE_TEAMMATE_TEXT) }</div>
      </InviteTeammateMenuItem>
    );
  };

  return (
    <>
      <Container>
        <CampaignPageBaseOption
          title={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.TITLE) }
          description={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.DESCRIPTION) }
          Icon={ Icon }
          EndAdornment={ (
            <StyledSelect
              placeholder={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_PLACEHOLDER) }
              LabelIcon={ <ASSETS.LIGHTNING /> }
              isValid={ !!selectedUserId }
              label={ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_LABEL) }
              value={ selectedUserId }
              StartAdornment={ PrimarySenderSelectStartAdornment }
              onChangeHandler={ changeHandler }
              menuProps={ {
                Title: (
                  <MenuTitleContainer>
                    <MenuTitle>{ translate(STRING_KEYS.CAMPAIGN_PAGE.AUTO_MODE_OPTIONS.EMAIL_SENDER.PRIMARY_SENDER_SELECT_MENU_TITLE) }</MenuTitle>
                  </MenuTitleContainer>
                ),
              } }
              formatDisplayValue={ (element: SelectItemProps) => <SelectItemValue>{element.displayValue}</SelectItemValue> }
            >
              { selectUsersMenuItems }
              { renderSelectMenuFooter() }
            </StyledSelect>
          ) }
        />
      </Container>
      { isInviteUsersModalOpen && (
        <InviteColleagueModal
          isOpen={ isInviteUsersModalOpen }
          closeHandler={ () => setIsInviteUsersModalOpen(false) }
        />
      ) }
    </>
  );
};

export default CampaignPageAutoModeEmailSenderSection;
