import { PayloadAction, CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';
import { LOGIN_STATE } from '../app-state.consts';
import { AppRouting } from '../../../../services/appRoutingResolver';

type SessionCommand = {
  commandKey: SessionCommandType,
  date: Date,
  expiration: Date,
}

type CommandsType = {
  commands: SessionCommand[],
}

enum SessionCommandType {
  REFRESH_PAGE = 'REFRESH_PAGE',
  GO_TO_HOME_PAGE = 'GO_TO_HOME_PAGE',
  LOGOUT = 'LOGOUT',
}

export const executeSessionCommandsReducer: CaseReducer<AppState, PayloadAction<CommandsType>> = (state, { payload }) => {
  const { commands } = payload;
  const executeSessionCommand = (command: SessionCommand) => {
    switch (command.commandKey) {
      case SessionCommandType.REFRESH_PAGE:
        window.location.reload();
        break;
      case SessionCommandType.GO_TO_HOME_PAGE:
        window.location.replace(AppRouting.ROUTES.HOME);
        break;
      case SessionCommandType.LOGOUT:
        state.loginState = LOGIN_STATE.LOGGED_OUT;
        break;
      default:
    }
  };
  commands.forEach((command) => {
    executeSessionCommand(command);
  });

  return state;
};
