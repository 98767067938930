import { useTranslation } from 'react-i18next';
import {
  PerfectButtonHoverVariants,
  PerfectButtonSize,
} from '../../../../components/Common/Buttons/perfect-base-button/perfect-base-button.enums';
import STRING_KEYS from '../../../../language/keys';
import { PositionsEmptyProps } from './positions-empty';
import {
  CactusIcon, Container, CreatePositionButton, CreatePositionButtonIcon, Description, Title,
} from './positions-empty.styled';

const PositionsEmpty = ({ allowedCreatePosition, createPositionButtonClickHandler }: PositionsEmptyProps): JSX.Element => {
  const { t: translate } = useTranslation();

  return (
    <Container>
      <CactusIcon />
      <Title>
        {translate(STRING_KEYS.HOMEPAGE.POSITION_EMPTY.TITLE)}
      </Title>
      <Description>
        {translate(STRING_KEYS.HOMEPAGE.POSITION_EMPTY.DESCRIPTION)}
      </Description>
      { allowedCreatePosition && (
        <CreatePositionButton
          StartIcon={ CreatePositionButtonIcon }
          onClick={ createPositionButtonClickHandler }
          size={ PerfectButtonSize.Large }
          hoverVariant={ PerfectButtonHoverVariants.Grow }
          disabled={ !allowedCreatePosition }
        >
          { translate(STRING_KEYS.CREATE_POSITION.CREATE_POSITION_OPEN_BUTTON) }
        </CreatePositionButton>
      )}
    </Container>
  );
};
export default PositionsEmpty;
