import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import log from 'loglevel';
import { useDispatch } from 'react-redux';
import { useMergeLinkUi } from '../../../hooks/queries';
import STRING_KEYS from '../../../language/keys';
import {
  ACTION_SOURCE_LOCATION, ANALYTICS_EVENTS, EVENT_GROUP, PRODUCT_AREA,
} from '../../../consts/analytics';
import { PAGE_NAMES } from '../../../consts/pages';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { IndeterminateLinearLoader } from '../../Common/Loaders/IndeterminateLinearLoader';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import { setSnackbar } from '../../../store/slices/app-state/app-state.toolkit-slice';
import { AnchorDirectionsEnum, AppSnackbarVariant } from '../../Common/CustomSnackBar/app-snackbar/app-snackbar.enums';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useCreateCustomAtsIntegrationMutation } from '../../../store/rtk-query/apis/ats-integration/hooks/create-custom-ats-integration.mutation-hook';
import {
  BodyContainer,
  HeaderContainer,
  HeaderIcon,
  LoaderWrapper,
} from './ats-initialization.styled';
import EnterApiKey from './enter-api-key/enter-api-key.component';
import { CustomAtsIntegrationType } from './ats-initialization.enums';
import AtsInitializationMainScreen from './ats-intialization-main-screen/ats-intialization-main-screen.component';

const INVALID_API_KEY_ERROR = 32013;

const COMPONENT_ELEMENT_TYPE = {
  CLOSE: 'close',
  SYNC_TO_ATS_COMEET: 'sync_to_ats_comeet',
  SYNC_TO_ATS_GREENHOUSE: 'sync_to_ats_greenhouse',
  SYNC_TO_ATS_ASHBY: 'sync_to_ats_ashby',
  SYNC_TO_ATS_OTHER: 'sync_to_ats_other',
};

const eventPayloadAdditionalProperties = {
  [PRODUCT_AREA.HOMEPAGE]: {
    pageName: PAGE_NAMES.HOME_PAGE,
    actionSourceLocation: ACTION_SOURCE_LOCATION.POSITION_CARD,
  },
  [PRODUCT_AREA.MATCH_PAGE]: {
    pageName: PAGE_NAMES.MATCH_PAGE,
    actionSourceLocation: ACTION_SOURCE_LOCATION.ENGAGEMENT_MODAL,
  },
};

const logger = log.getLogger('ATS_INITIALIZATION_DIALOG');

export const AtsInitializationDialog = ({
  closeDialogHandler,
  productArea,
  isDialogOpen,
}: AtsInitializationProps):JSX.Element => {
  const { t: translate } = useTranslation();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const { data: { id: accountId } } = useGetMyAccountSelector();
  const [twoWaySyncApproved, setTwoWaySyncApproved] = useState<boolean>(true);
  const { open, isReady: isMergeReady, error: mergeError } = useMergeLinkUi(twoWaySyncApproved, isDialogOpen, closeDialogHandler);
  const [customIntegrationType, setCustomIntegrationType] = useState<CustomAtsIntegrationType | undefined>();
  const dispatch = useDispatch();
  const [
    createCustomAtsIntegration,
    {
      isError: isCreateCustomAtsIntegrationError,
      error: createCustomAtsIntegrationError,
      isLoading: isCreateCustomAtsIntegrationLoading,
      isSuccess: isCreateCustomAtsIntegrationSuccess,
    },
  ] = useCreateCustomAtsIntegrationMutation();

  const showAlert = (descriptionTranslationKey: string) => {
    dispatch(setSnackbar({
      title: translate(STRING_KEYS.ALERT),
      description: translate(descriptionTranslationKey),
      variant: AppSnackbarVariant.Error,
      anchor: AnchorDirectionsEnum.BottomCenter,
    }));
  };

  useEffect(() => {
    if (isCreateCustomAtsIntegrationError) {
      showAlert(createCustomAtsIntegrationError?.error.serverError.internalErrorCode === INVALID_API_KEY_ERROR ?
        STRING_KEYS.ATS_INITIALIZATION_DIALOG.ENTER_API_KEY_SCREEN.INVALID_API_KEY_ERROR_MESSAGE :
        STRING_KEYS.GLOBAL_ERROR_MESSAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateCustomAtsIntegrationError]);

  const sendClickEvent = (componentElementType: string) => {
    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_ATS_PROMO,
      eventGroup: EVENT_GROUP.CLICK,
      productArea,
      componentElementType,
      componentName: 'ats_modal_promo',
    };
    analyticsTrackEvent(payload);
  };

  useEffect(() => {
    if (mergeError) {
      logger.error('Error when tyring to open merge initialization modal', mergeError);
      showAlert(STRING_KEYS.GLOBAL_ERROR_MESSAGE);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mergeError]);

  useEffect(() => {
    if (isCreateCustomAtsIntegrationSuccess) {
      closeDialogHandler();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCreateCustomAtsIntegrationSuccess]);

  useEffect(() => {
    if (isDialogOpen) {
      const payload = {
        eventName: ANALYTICS_EVENTS.ATS_PROMO_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
        productArea,
        ...(eventPayloadAdditionalProperties[productArea] || {}),
      };
      analyticsTrackEvent(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDialogOpen]);

  const onContinueWithOtherButtonClick = () => {
    open();
    closeDialogHandler();
    sendClickEvent(COMPONENT_ELEMENT_TYPE.SYNC_TO_ATS_OTHER);
  };

  const onCloseDialogHandler = () => {
    closeDialogHandler();
    sendClickEvent(COMPONENT_ELEMENT_TYPE.CLOSE);
  };

  const onCreateCustomIntegrationButtonClick = (integrationType: CustomAtsIntegrationType) => {
    let componentElementType;
    switch (integrationType) {
      case CustomAtsIntegrationType.Comeet:
        componentElementType = COMPONENT_ELEMENT_TYPE.SYNC_TO_ATS_COMEET;
        break;
      case CustomAtsIntegrationType.Greenhouse:
        componentElementType = COMPONENT_ELEMENT_TYPE.SYNC_TO_ATS_GREENHOUSE;
        break;
      case CustomAtsIntegrationType.Ashby:
        componentElementType = COMPONENT_ELEMENT_TYPE.SYNC_TO_ATS_ASHBY;
        break;
      default:
        break;
    }

    if (componentElementType) {
      sendClickEvent(componentElementType);
    }

    setCustomIntegrationType(integrationType);
  };

  const onCustomAtsIntegrationContinueButtonClick = (token: string) => {
    if (customIntegrationType) {
      createCustomAtsIntegration({
        integrationType: customIntegrationType,
        token,
        twoWaySyncApproved,
        accountId,
      });
    }
  };

  const renderBody = () => {
    if (customIntegrationType) {
      return (
        <EnterApiKey
          integrationType={ customIntegrationType }
          isCreateCustomAtsIntegrationLoading={ isCreateCustomAtsIntegrationLoading }
          continueButtonClickHandler={ onCustomAtsIntegrationContinueButtonClick }
          backButtonClickHandler={ () => setCustomIntegrationType(undefined) }
        />
      );
    }

    return (
      <AtsInitializationMainScreen
        twoWaySyncApproved={ twoWaySyncApproved }
        isMergeReady={ isMergeReady }
        continueWithOtherButtonClickHandler={ onContinueWithOtherButtonClick }
        twoWaySyncApprovedChangeHandler={ setTwoWaySyncApproved }
        createCustomAtsIntegrationHandler={ onCreateCustomIntegrationButtonClick }
      />
    );
  };

  return (
    <Dialog
      isOpen={ isDialogOpen }
      onClose={ onCloseDialogHandler }
      widthSize={ DialogWidthSize.Large }
    >
      <HeaderContainer>
        <HeaderIcon />
      </HeaderContainer>
      <BodyContainer>
        <>
          { !isMergeReady && (
            <LoaderWrapper>
              <IndeterminateLinearLoader loaderHeight="4px" />
            </LoaderWrapper>
          )}
        </>
        { renderBody() }
      </BodyContainer>
    </Dialog>
  );
};
