import { PositionResponse, PositionUserRole } from './dto/response/position.response';
import { AssignUserRequestTypeEnum } from './enums/assign-user-request-type.enum';

export const updatePositionUserRoles = (
  position: PositionResponse,
  requestType: AssignUserRequestTypeEnum,
  recruiters?: PositionUserRole[],
  hiringManagers?: PositionUserRole[],
): PositionResponse => {
  if (position) {
    if (recruiters) {
      if (requestType === AssignUserRequestTypeEnum.Assign) {
        // Add recruiters
        position.recruiters = [
          ...position.recruiters,
          ...recruiters.filter((newRecruiter) => !position.recruiters.some((existing) => existing.userId === newRecruiter.userId),
          )];
      } else if (requestType === AssignUserRequestTypeEnum.UnAssign) {
        // Remove recruiters
        position.recruiters = position.recruiters.filter((existing) => !recruiters.some((toRemove) => toRemove.userId === existing.userId),
        );
      }
    }

    if (hiringManagers) {
      if (requestType === AssignUserRequestTypeEnum.Assign) {
        // Add hiring managers
        position.hiringManagers = [
          ...position.hiringManagers,
          ...hiringManagers.filter((newManager) => !position.hiringManagers.some((existing) => existing.userId === newManager.userId),
          )];
      } else if (requestType === AssignUserRequestTypeEnum.UnAssign) {
        // Remove hiring managers
        position.hiringManagers = position.hiringManagers.filter((existing) => !hiringManagers.some((toRemove) => toRemove.userId === existing.userId),
        );
      }
    }
  }

  return position;
};
