import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import {
  disableIsUnsavedChanges,
  enableIsUnsavedChanges,
} from '../../store/slices/app-state/app-state.toolkit-slice';
import STRING_KEYS from '../../language/keys';

const useUnsavedChanges = ({ isDirty, warningMessageTranslationKey }: UnsavedChangesHookProps): null => {
  const { t: translate } = useTranslation();
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    if (isDirty) {
      dispatch(enableIsUnsavedChanges());
    } else {
      dispatch(disableIsUnsavedChanges());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty]);

  useEffect(() => {
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      if (isDirty) {
        event.preventDefault();
        event.returnValue = ''; // Required for browser confirmation dialog
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    
    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isDirty]);

  useEffect(() => {
    // Handle in-app navigation
    const unblock = history.block((nextLocation): false | undefined => {
      const currentPathname = location.pathname;
      const nextPathname = nextLocation.pathname;

      // Allow navigation if only query parameters are changing
      if (currentPathname === nextPathname) {
        return undefined;
      }

      if (isDirty) {
        const translatedMessage = translate(warningMessageTranslationKey ?? STRING_KEYS.UNSAVED_CHANGES);

        // eslint-disable-next-line no-alert
        const confirmLeave = window.confirm(translatedMessage);
        if (!confirmLeave) {
          return false; // Prevent navigation
        }
      }

      dispatch(disableIsUnsavedChanges());

      return undefined;
    });

    return () => {
      unblock();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDirty, warningMessageTranslationKey]);

  return null;
};

export default useUnsavedChanges;
