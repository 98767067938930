/* eslint-disable react/jsx-props-no-spreading */
import {
  Children, cloneElement,
  forwardRef,
  isValidElement, PropsWithChildren, ReactElement,
  Ref, useEffect,
  useState,
} from 'react';
import { Container } from './base-selection-group.styled';
import { BaseSelectionGroupVariant } from './base-selection-group.enums';
import { BaseSelectionGroupProps } from './base-selection-group';
import { SelectionGroupItemProps } from './selection-group-item/selection-group-item';

const BaseSelectionGroup = forwardRef((
  props: BaseSelectionGroupProps,
  ref: Ref<HTMLDivElement>,
) => {
  const {
    variant = BaseSelectionGroupVariant.Round,
    children,
    defaultSelectedIndex = 0,
    clickHandler,
    ...otherProps
  } = props;

  const [selectedIndex, setSelectedIndex] = useState(defaultSelectedIndex);

  useEffect(() => {
    if (Children.count(children) > defaultSelectedIndex + 1) {
      setSelectedIndex(0);
    }

    return setSelectedIndex(defaultSelectedIndex);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultSelectedIndex]);

  const onSelectionChange = (newValue: string) => {
    setSelectedIndex((prevValue) => {
      const indexValue = Children.toArray(children).findIndex((child) => {
        const item = child as ReactElement<PropsWithChildren<SelectionGroupItemProps>>;

        return item.props.value === newValue;
      });

      if (prevValue !== indexValue) {
        const canContinue = clickHandler?.(newValue);

        if (!canContinue) {
          return prevValue; // Prevent tab switch
        }
      }

      return indexValue;
    });
  };

  const childrenWithProps = () => {
    return Children.map(
      children,
      (child, index) => {
        const item = child as ReactElement<PropsWithChildren<SelectionGroupItemProps>>;

        // Checking isValidElement is the safe way and avoids a TS error too.
        if (isValidElement(item)) {
          const active = index === selectedIndex;

          return cloneElement(item, {
            variant,
            onClickHandler: onSelectionChange,
            active,
            disabled: item.props.disabled,
            activeElementIndex: selectedIndex,
          });
        }

        return item;
      });
  };

  return (
    <Container
      ref={ ref }
      variant={ variant }
      { ...otherProps }
    >
      { childrenWithProps() }
    </Container>
  );
});

BaseSelectionGroup.displayName = 'BaseSelectionGroup';

export default BaseSelectionGroup;
