import {
  SyntheticEvent, useEffect, useRef, useState,
} from 'react';
import { Trans, useTranslation } from 'react-i18next';
import STRING_KEYS from '../../../language/keys';
import { validateEmail } from '../../../utils';
import { useGetAccountUsersQuery } from '../../../store/rtk-query/apis/user/hooks/get-account-users.query-hook';
import { useInviteUserMutation } from '../../../store/rtk-query/apis/user/hooks/invite-user.mutation-hook';
import { useResendInviteUserMutation } from '../../../store/rtk-query/apis/user/hooks/resend-invite-user.mutation-hook';
import { useAnalyticsContext } from '../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../consts/analytics';
import Dialog from '../base-dialog/base-dialog.component';
import { DialogWidthSize } from '../base-dialog/base-dialog.enums';
import { UserRoleName } from '../../../enums/user-role-name.enum';
import { PERMISSIONS } from '../../../consts/permissions.const';
import { usePermissionCheck } from '../../../hooks/use-permissions.hook';
import { PermissionPlaceholder } from '../../../enums/permission-placeholder.enum';
import { useDeleteUserByIdMutation } from '../../../store/rtk-query/apis/user/hooks/delete-user-by-id.mutation-hook';
import { useAssignUserRoleMutation } from '../../../store/rtk-query/apis/user/hooks/assign-user-role.mutation-hook';
import InvitedColleaguesList from './InvitedColleaguesList/invited-colleagues-list.component';
import {
  BodyContainer,
  Description,
  EmailContainer,
  EmailExist,
  EmailInput,
  EmailNotValid,
  EmailRow,
  HeaderContainer,
  HeaderTitle,
  ModalContentWrapper,
  StyledButton,
  StyledButtonWrapper,
  SubTitle,
  Title,
} from './invite-colleague-dialog.styled';
import { RolesMenu } from './roles-menu/roles-menu.component';
import { RolesMenuButton } from './roles-menu-button/roles-menu-button.container';

type InviteColleagueModalProps = {
  isOpen: boolean;
  closeHandler: () => void;
}

const ELEMENT_TYPES = {
  invite_button: 'invite_button',
  close_button: 'close_button',
};

const InviteColleagueModal = ({ isOpen, closeHandler }: InviteColleagueModalProps): JSX.Element => {
  const [emailInput, setEmailInput] = useState<string>('');
  const [emailInputError, setEmailInputError] = useState<boolean>(false);
  const [emailInputExists, setEmailInputExists] = useState<boolean>(false);

  const [selectedRole, setSelectedRole] = useState<UserRoleName | undefined>();

  const [roleMenuAnchorElement, setRoleMenuAnchorElement] = useState(null);
  const ref = useRef(null);

  const onRoleMenuSelectionClickHandler = () => roleMenuAnchorElement ? setRoleMenuAnchorElement(null) : setRoleMenuAnchorElement(ref.current);
  const onCloseRoleMenuSelection = () => setRoleMenuAnchorElement(null);

  const onSelectRoleHandler = (value: UserRoleName) => {
    setSelectedRole(value);
    onCloseRoleMenuSelection();
  };

  const { t: translate } = useTranslation();
  const [, { isError: errorResendInviteColleague, isLoading: isLoadingResendInviteColleague }] = useResendInviteUserMutation();
  const [, { isError: errorDeleteColleague, isLoading: isLoadingDeleteColleague }] = useDeleteUserByIdMutation();
  const [, { isError: errorAssignRole, isLoading: isLoadingAssignRole }] = useAssignUserRoleMutation();
  const { analyticsTrackEvent } = useAnalyticsContext();

  const { allowed: allowedInvite, resolvedPermissions } = usePermissionCheck([PERMISSIONS.user.inviteAll, PERMISSIONS.user.inviteCollaborator]);

  const inviteButtonDisable = emailInput === '' ||  !selectedRole || !allowedInvite;

  useEffect(() => {
    if (resolvedPermissions.userPermission !== PermissionPlaceholder.Wildcard) {
      setSelectedRole(UserRoleName.Collaborator);
    }
  }, [resolvedPermissions.userPermission]);

  useEffect(() => {
    if (isOpen) {
      const payload = {
        eventName: ANALYTICS_EVENTS.INVITE_TEAMMATE_MODAL_SHOWN,
        eventGroup: EVENT_GROUP.IMP,
      };
      analyticsTrackEvent(payload);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  const {
    data: inviteColleagueUsers,
    isLoading: isLoadingGetColleagueUsers,
    error: errorGetInviteColleagueRequest,
    refetch: refetchInvitedUsers,
  } = useGetAccountUsersQuery();

  const [
    inviteUser,
    { error: errorSendInviteColleague, isLoading: isLoadingSendInviteColleague },
  ] = useInviteUserMutation();

  const resetEmailInput = () => {
    setEmailInput('');
  };

  const handleInviteClick = async () => {
    setEmailInputExists(false);
    setEmailInputError(false);
    if (emailInput === '' || !validateEmail(emailInput) || !selectedRole) {
      setEmailInputError(true);
    } else if (inviteColleagueUsers
      .find((colleague) => colleague.email === emailInput)) {
      setEmailInputExists(true);
    } else {
      await inviteUser({
        email: emailInput,
        userRoleName: selectedRole || UserRoleName.Collaborator,
      });
      refetchInvitedUsers?.();
      resetEmailInput();
    }

    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_INVITE_TEAMMATE_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      componentElementType: ELEMENT_TYPES.invite_button,
    };
    analyticsTrackEvent(payload);
  };

  const handleResetErrors = () => {
    setEmailInputExists(false);
    setEmailInputError(false);
  };

  const handleEmailInputChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;

    setEmailInput(target.value);
  };

  const defineErrors = () => {
    if (emailInputError) {
      return (
        <EmailNotValid >
          {
            translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.EMAIL_NOT_VALID)
          }
        </EmailNotValid>
      );
    }

    if (emailInputExists && emailInput.length > 0) {
      return (
        <EmailExist>
          <Trans
            defaults={ STRING_KEYS.INVITE_YOUR_TEAMMATES.EMAIL_EXISTS }
            values={ {
              firstName: emailInput,
            } }
            components={ { bold: <strong /> } }
          />
        </EmailExist>
      );
    }

    return <></>;
  };

  const defineServerError = () => {
    if (errorGetInviteColleagueRequest) {
      return (
        <EmailNotValid >
          {errorGetInviteColleagueRequest}
        </EmailNotValid>
      );
    }

    if (errorSendInviteColleague) {
      return (
        <EmailNotValid >
          {errorSendInviteColleague}
        </EmailNotValid>
      );
    }

    if (errorResendInviteColleague) {
      return (
        <EmailNotValid >
          {errorResendInviteColleague}
        </EmailNotValid>
      );
    }

    if (errorDeleteColleague) {
      return (
        <EmailNotValid >
          {errorDeleteColleague}
        </EmailNotValid>
      );
    }

    if (errorAssignRole) {
      return (
        <EmailNotValid >
          {errorAssignRole}
        </EmailNotValid>
      );
    }

    return null;
  };

  const onCloseButtonClick = () => {
    closeHandler();

    const payload = {
      eventName: ANALYTICS_EVENTS.CLICKED_ON_INVITE_TEAMMATE_MODAL,
      eventGroup: EVENT_GROUP.CLICK,
      componentElementType: ELEMENT_TYPES.close_button,
    };
    analyticsTrackEvent(payload);
  };

  return (
    <Dialog
      isOpen={ isOpen }
      onClose={ onCloseButtonClick }
      widthSize={ DialogWidthSize.Large }
    >
      <HeaderContainer>
        <HeaderTitle>
          { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_YOUR_TEAMMATES) }
        </HeaderTitle>
      </HeaderContainer>

      <BodyContainer>
        <EmailContainer>
          <div>
            <Title>
              { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_TITLE) }
            </Title>
            <SubTitle>
              { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_SUBTITLE) }
            </SubTitle>
          </div>
          <EmailRow>
            <EmailInput
              placeholder={ translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.PLACEHOLDER) }
              onChange={ handleEmailInputChange }
              value={ emailInput }
              isError={ emailInputError }
            />
            { resolvedPermissions.placeholders === PermissionPlaceholder.Wildcard && (
              <RolesMenuButton
                isActive={ !!roleMenuAnchorElement }
                selectedRole={ selectedRole }
                onClickOpenRoleMenuHandler={ onRoleMenuSelectionClickHandler }
                ref={ ref }
              />
            )}
            <StyledButtonWrapper>
              <StyledButton onClick={ handleInviteClick } isDisabled={ inviteButtonDisable }>
                {
                  translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_BUTTON)
                }
              </StyledButton>
            </StyledButtonWrapper>
          </EmailRow>
          <Description>
            { translate(STRING_KEYS.INVITE_YOUR_TEAMMATES.INVITE_A_TEAMMATE_DESCRIPTION) }
          </Description>
        </EmailContainer>
        <ModalContentWrapper>
          {defineErrors()}
          {defineServerError()}

          <InvitedColleaguesList
            inviteColleagueUsers={ inviteColleagueUsers }
            isLoadingColleagueUsers={ isLoadingGetColleagueUsers }
            isLoadingSendInviteColleague={ isLoadingSendInviteColleague }
            isLoadingResendInviteColleague={ isLoadingResendInviteColleague }
            isLoadingDeleteColleague={ isLoadingDeleteColleague }
            isLoadingAssignRole={ isLoadingAssignRole }
            onResetError={ handleResetErrors }
          />
        </ModalContentWrapper>
      </BodyContainer>
      <RolesMenu
        handleMenuClose={ onCloseRoleMenuSelection }
        onSelectRoleHandler={ onSelectRoleHandler }
        anchorElement={ roleMenuAnchorElement || undefined }
      />
    </Dialog>
  );
};

export default InviteColleagueModal;
