import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Mentions } from '../../../components/Common/perfect-text-editor/decorators/mention-decorator/mention-list/mention-list';
import STRING_KEYS from '../../../language/keys';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { useGetMyAccountSelector } from '../../../store/rtk-query/apis/account/selectors/get-account-by-id.selector';
import { useGetConversationByIdSelector } from '../../../store/rtk-query/apis/outreach/selectors/get-conversation-by-id.selector';
import { isEmptyObject } from '../../../utils';

export const useConversationMentionsOptions = (positionId: string, conversationId: string): Mentions[] | undefined => {
  const { t: translate } = useTranslation();
  const {
    data: conversation,
    isLoading: isLoadingConversation,
  } = useGetConversationByIdSelector(positionId, conversationId);
  const { data: user } = useMyUserSelector();
  const { data: account } = useGetMyAccountSelector();

  return useMemo<Mentions[] | undefined>(() => {
    if (isLoadingConversation || isEmptyObject(conversation) || !user || !account) {
      return undefined;
    }

    const talent = {
      key: STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.TALENT,
      mentionInfo: [
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_FIRST_NAME),
          value: conversation.match.talent.firstName,
        },
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_LAST_NAME),
          value: conversation.match.talent.lastName,
        },
      ],
    };

    const sender = {
      key: STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS_TITLE.SENDER,
      mentionInfo: [
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.SENDER_NAME),
          value: `${user.firstName} ${user.lastName}`,
        },
        {
          displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.ACCOUNT_COMPANY_NAME),
          value: account.displayName,
        },
      ],
    };

    if (conversation.match.talent.currentJob?.companyName) {
      talent.mentionInfo.push({
        displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_CURRENT_COMPANY_NAME),
        value: conversation.match.talent.currentJob.companyName,
      });
    }

    if (conversation.match.talent.totalMonthsRelevantTenure) {
      talent.mentionInfo.push({
        displayValue: translate(STRING_KEYS.OUTREACH_PAGE.EDITOR.MENTIONS.MENU_ITEMS.TALENT_YEARS_EXPERIENCE),
        value: Math.floor(conversation.match.talent.totalMonthsRelevantTenure / 12).toString(), // Convert to years and get round down number as string
      });
    }

    return [
      talent,
      sender,
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversation, user, account]);
};
