import styled from 'styled-components';
import { THEME } from '../../../../themes';
import {
  OverlineFont, Body2Font, Subtitle2Font, CaptionFont,
} from '../../../../themes/fonts';
import BaseButton from '../../Buttons/perfect-base-button/perfect-base-button.component';
import { PricingPlanEnum } from '../../../../enums/user-plan.enum';
import { ASSETS } from '../../../../assets';

const getPlanBackgroundColor = (plan: string) => {
  switch (plan) {
    case PricingPlanEnum.SCALE:
    case PricingPlanEnum.TRIAL:
      return THEME.COLORS_RGB.SECONDARY_012;
    case PricingPlanEnum.LITE:
    default:
      return THEME.COLORS_RGB.ACCENT_012;
  }
};

export const UserGroupIcon = styled(ASSETS.USER_GROUP)`
  height: 24px;
`;

export const StyledUserMenu = styled.div`
  min-width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${THEME.COLORS.SURFACE_PRIMARY};
  cursor: auto;
  overflow: hidden;
`;

export const CurrentPlan = styled.div<{ pricingPlan: string }>`
  border-radius: ${THEME.RADIUS.ROUND};
  background-color: ${({ pricingPlan }) => getPlanBackgroundColor(pricingPlan)};
  border: 1px solid ${THEME.COLORS_RGB.SECONDARY_005};
  width: 95%;
  padding: ${THEME.SPACING.TINO};
  margin-bottom: ${THEME.SPACING.BASE};
  display: flex;
  justify-content: space-between;
  align-items: center;
}
`;

export const CurrentPlanLabel = styled.div`
  ${OverlineFont};
  font-weight: 500;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const PlanDescriptionContainer = styled.div`
  display: flex;
  align-items: center;
  gap: ${THEME.SPACING.MICRO};
`;

export const CurrentPlanTypeLabel = styled.div`
  ${Body2Font};
  font-weight: bold;
  color: ${THEME.COLORS.PRIMARY_DARK};
`;

export const CurrentPlanDaysLeft = styled.div`
  ${CaptionFont};
  opacity: ${THEME.OPACITY.MED};
  color: ${THEME.COLORS.CONTENT_A};
`;

export const UserDetailsContainer = styled.div`
  display: flex;
  gap: ${THEME.SPACING.BASE};
  align-items: center;
  width: 100%;
  padding: ${THEME.SPACING.TINO};
`;

export const CurrentUserFullName = styled.div`
  ${Subtitle2Font};
  line-height: 1.71;
  word-break: break-all;
`;

export const CurrentUserEmail = styled.div`
  ${Body2Font};
  word-break: break-all;
`;

export const MuiAvatarSX = {
  width: 32,
  height: 32,
};

export const GoToProButton = styled(BaseButton)`
  color: ${THEME.COLORS.ACCENT};
`;
