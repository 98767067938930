import { createApi } from '@reduxjs/toolkit/query/react';
import { v4 } from 'uuid';
import { axiosBaseQuery } from '../../utils/base-query';
import { resetConversationsSort } from '../../../slices/outreach-state/outreach-state.toolkit-slice';
import socketManager from '../../../../services/socket-io-manager';
import { SocketNamespace } from '../../../../enums/socket-namespace.enum';
import {
  socketOutreachConnected,
  socketOutreachDisconnected,
} from '../../../slices/app-state/app-state.toolkit-slice';
import { SocketEventName } from '../../../../enums/socket-event-name.enum';
import {
  sequenceCreationCompletedConversationsWebsocketListener,
  sequenceCreationCompletedConversationWebsocketListener,
} from '../sequence/websocket-listeners/sequence-creation-completed.websocket-listener';
import { sequenceQuery } from '../sequence/sequence.toolkit-api';
import { REDUCER_PATH } from './outreach.consts';
import {
  approveSignature,
  archiveConversationQuery,
  createGoogleEmailSenderQuery,
  createMicrosoftEmailSenderQuery,
  deleteEmailSenderQuery,
  getConversationByPositionIdAndIdQuery,
  getConversationsByPositionIdQuery,
  getEmailSenderQuery,
  getMessageSuggestionQuery,
  getSignatureExtractionProcessQuery,
  getSignatureQuery,
  initializeSignatureProcess,
  markConversationMessageAsSeen,
  markMessageAsSentQuery,
  sendMessageQuery,
} from './outreach.toolkit-queries';
import { EmailSenderResponseDto } from './dto/response/email-sender.response';
import { ConversationsMinimizedResponseDto } from './dto/response/conversation-minimized.response';
import { ConversationResponseDto } from './dto/response/conversation.response';
import { ConversationByIdQueryArguments } from './dto/query-arguments/conversation-by-id.query-arguments';
import { SendMessageQueryArguments } from './dto/query-arguments/send-message-query.arguments';
import { MessageResponseDto } from './dto/response/message.response';
import { GetMessageSuggestionQueryArguments } from './dto/query-arguments/get-message-suggestion.query-arguments';
import { MessageSuggestionResponseDto } from './dto/response/message-suggestion.response';
import {
  addMessageInConversationsByConversationIdOptimisticHandler,
  updateMessageInConversationsByMatchIdOptimisticHandler,
  updateMessageAsErrorInConversationsByMatchIdOptimisticHandler,
  addMessageToConversationOptimisticHandler,
  updateMessageInConversationOptimisticHandler,
  updateMessageAsErrorInConversationOptimisticHandler,
} from './optimistic-updates/send-message.optimistic';
import { ReSendMessageQueryArguments } from './dto/query-arguments/re-send-message-query.arguments';
import { GetConversationsByPositionIdQueryArguments } from './dto/query-arguments/get-conversations-by-position-id.query-arguments';
import { ArchiveConversationByIdQueryArguments } from './dto/query-arguments/archive-conversation-by-id.query-arguments';
import { archiveConversationOptimisticHandler, archiveConversationsByIdOptimisticHandler } from './optimistic-updates/archive-conversation.optimistic';
import { CreateEmailSenderQueryArguments } from './dto/query-arguments/create-email-sender.query-arguments';
import { createEmailSenderUpdateSequencePreferencesOptimisticHandler } from './optimistic-updates/create-email-sender.optimistic';
import {
  talentRepliedGetConversationByIdWebsocketListener,
  talentRepliedGetConversationsWebsocketListener,
} from './websocket-listeners/talent-replied.websocket-listener';
import {
  conversationArchivedWebsocketListener,
  conversationsArchivedWebsocketListener,
} from './websocket-listeners/conversation-archived.websocket-listener';
import { DeleteEmailSenderQueryArguments } from './dto/query-arguments/delete-email-sender.query-arguments';
import { EmailSignatureProcessResponse } from './dto/response/email-signature-process.response';
import { MicrosoftEmailSignatureResponse } from './dto/response/microsoft-email-signature.response';
import { EmailSignatureResponse } from './dto/response/email-signature.response';

export const outreachQuery = createApi({
  reducerPath: REDUCER_PATH,
  baseQuery: axiosBaseQuery(),
  tagTypes: ['getEmailSender'],
  endpoints: (builder) => ({
    getConversationsByPositionId: builder.query<ConversationsMinimizedResponseDto, GetConversationsByPositionIdQueryArguments>({
      query: getConversationsByPositionIdQuery,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.Outreach,
          onSocketConnected: () => {
            dispatch(socketOutreachConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketOutreachDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.SequenceCreationCompleted,
            (socketPayload) => {
              sequenceCreationCompletedConversationsWebsocketListener(socketPayload, updateCachedData);
              dispatch(resetConversationsSort());
            },
          );
          socket.on(
            SocketEventName.OutreachTalentReplied,
            (socketPayload) => {
              talentRepliedGetConversationsWebsocketListener(socketPayload, updateCachedData);
              dispatch(resetConversationsSort());
            },
          );
          socket.on(
            SocketEventName.ConversationArchived,
            (socketPayload) => {
              conversationsArchivedWebsocketListener(socketPayload, updateCachedData);
              dispatch(resetConversationsSort());
            },
          );
        }
      },
    }),
    getConversationById: builder.query<ConversationResponseDto, ConversationByIdQueryArguments>({
      query: getConversationByPositionIdAndIdQuery,
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, dispatch },
      ) {
        const socket = socketManager.getSocket({
          namespace: SocketNamespace.Outreach,
          onSocketConnected: () => {
            dispatch(socketOutreachConnected());
          },
          onSocketDisconnected: () => {
            dispatch(socketOutreachDisconnected());
          },
        });
        if (socket) {
          await cacheDataLoaded;
          socket.on(
            SocketEventName.SequenceCreationCompleted,
            (socketPayload) => sequenceCreationCompletedConversationWebsocketListener(socketPayload, arg.conversationId, updateCachedData),
          );
          socket.on(
            SocketEventName.OutreachTalentReplied,
            (socketPayload) => talentRepliedGetConversationByIdWebsocketListener(socketPayload, arg.conversationId, updateCachedData),
          );
          socket.on(
            SocketEventName.ConversationArchived,
            (socketPayload) => {
              conversationArchivedWebsocketListener(socketPayload, arg.conversationId, updateCachedData);
              dispatch(resetConversationsSort());
            },
          );
        }
      },
    }),
    getMessageSuggestion: builder.mutation<MessageSuggestionResponseDto, GetMessageSuggestionQueryArguments>({
      query: getMessageSuggestionQuery,
    }),
    markConversationMessageAsSeen: builder.mutation<void, ConversationByIdQueryArguments>({
      query: markConversationMessageAsSeen,
      async onQueryStarted(
        { conversationId, positionId }, { dispatch, queryFulfilled },
      ) {
        setTimeout(() => {
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              conversation?.messageHistory?.forEach((message) => { message.seen = true; });
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId: positionId || '' }, ({ conversations }) => {
              const foundConversation = conversations.find((conversation) => conversation.id === conversationId);
              if (foundConversation && foundConversation.latestMessage) {
                foundConversation.latestMessage.seen = true;
              }
            }),
          );
        }, 20000);
        await queryFulfilled;
      },
    }),
    getEmailSender: builder.query<EmailSenderResponseDto | undefined, void>({
      query: getEmailSenderQuery,
      providesTags: ['getEmailSender'],
    }),
    deleteEmailSender: builder.mutation<void, DeleteEmailSenderQueryArguments>({
      query: deleteEmailSenderQuery,
      async onQueryStarted(_, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled;
          // Only invalidate the cache if the call was successful
          dispatch(outreachQuery.util.invalidateTags(['getEmailSender']));
        } catch (error) {
          // Do nothing
        }
      },
    }),
    createGoogleEmailSender: builder.mutation<EmailSenderResponseDto, CreateEmailSenderQueryArguments>({
      query: createGoogleEmailSenderQuery,
      async onQueryStarted({ positionId, currentUserId }, { dispatch, queryFulfilled }) {
        const { data: emailSender } = await queryFulfilled;
        dispatch(
          outreachQuery.util.upsertQueryData('getEmailSender', undefined, emailSender),
        );
        dispatch(
          sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId }, (preferences) => {
            createEmailSenderUpdateSequencePreferencesOptimisticHandler(preferences, currentUserId);
          }),
        );
      },
    }),
    getSignature: builder.query<EmailSignatureResponse, void>({
      query: getSignatureQuery,
    }),
    getSignatureExtractionProcess: builder.query<EmailSignatureProcessResponse, void>({
      query: getSignatureExtractionProcessQuery,
    }),
    initializeSignatureProcess: builder.mutation<EmailSignatureProcessResponse, void>({
      query: initializeSignatureProcess,
      async onQueryStarted(
        _, { dispatch, queryFulfilled },
      ) {
        const { data } = await queryFulfilled;
        dispatch(
          outreachQuery.util.updateQueryData('getSignatureExtractionProcess', undefined, () => {
            return data;
          }),
        );
      },
    }),
    approveSignature: builder.mutation<MicrosoftEmailSignatureResponse, void>({
      query: approveSignature,
    }),
    createMicrosoftEmailSender: builder.mutation<EmailSenderResponseDto, CreateEmailSenderQueryArguments>({
      query: createMicrosoftEmailSenderQuery,
      async onQueryStarted({ positionId, currentUserId }, { dispatch, queryFulfilled }) {
        const { data: emailSender } = await queryFulfilled;
        dispatch(
          outreachQuery.util.upsertQueryData('getEmailSender', undefined, emailSender),
        );
        dispatch(
          sequenceQuery.util.updateQueryData('getPositionSequencePreferences', { positionId }, (preferences) => {
            createEmailSenderUpdateSequencePreferencesOptimisticHandler(preferences, currentUserId);
          }),
        );
      },
    }),
    reSendMessage: builder.mutation<MessageResponseDto, ReSendMessageQueryArguments>({
      query: sendMessageQuery,
      async onQueryStarted(
        data, { dispatch, queryFulfilled },
      ) {
        const {
          messageId, conversationId, matchId, positionId,
        } = data;
        dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            addMessageInConversationsByConversationIdOptimisticHandler(data, conversations);
          }),
        );
        try {
          const { data: updatedConversation } = await queryFulfilled;
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageInConversationOptimisticHandler(messageId, updatedConversation, conversation);
            }),
          );
        } catch {
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
              updateMessageAsErrorInConversationsByMatchIdOptimisticHandler(matchId, conversations);
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageAsErrorInConversationOptimisticHandler(messageId, conversation);
            }),
          );
        }
      },
    }),
    sendMessage: builder.mutation<MessageResponseDto, SendMessageQueryArguments>({
      query: sendMessageQuery,
      async onQueryStarted(
        data, { dispatch, queryFulfilled },
      ) {
        const {
          matchId, conversationId, positionId,
        } = data;
        const tempMessageId = v4();
        dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            addMessageInConversationsByConversationIdOptimisticHandler(data, conversations);
          }),
        );
        dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
            addMessageToConversationOptimisticHandler(tempMessageId, data, conversation);
            conversation.nextScheduledMessage = undefined;
            conversation.nextOpenLinkedinTask = undefined;
          }),
        );
        try {
          const { data: updatedConversation } = await queryFulfilled;
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
              updateMessageInConversationsByMatchIdOptimisticHandler(matchId, updatedConversation, conversations);
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageInConversationOptimisticHandler(tempMessageId, updatedConversation, conversation);
            }),
          );
        } catch {
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
              updateMessageAsErrorInConversationsByMatchIdOptimisticHandler(matchId, conversations);
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageAsErrorInConversationOptimisticHandler(tempMessageId, conversation);
            }),
          );
        }
      },
    }),
    markMessageAsSent: builder.mutation<MessageResponseDto, SendMessageQueryArguments>({
      query: markMessageAsSentQuery,
      async onQueryStarted(
        data, { dispatch, queryFulfilled },
      ) {
        const {
          matchId, conversationId, positionId,
        } = data;
        const tempMessageId = v4();
        dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            addMessageInConversationsByConversationIdOptimisticHandler(data, conversations);
          }),
        );
        dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
            addMessageToConversationOptimisticHandler(tempMessageId, data, conversation);
            conversation.nextScheduledMessage = undefined;
            conversation.nextOpenLinkedinTask = undefined;
          }),
        );
        try {
          const { data: updatedConversation } = await queryFulfilled;
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
              updateMessageInConversationsByMatchIdOptimisticHandler(matchId, updatedConversation, conversations);
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageInConversationOptimisticHandler(tempMessageId, updatedConversation, conversation);
            }),
          );
        } catch {
          dispatch(
            outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
              updateMessageAsErrorInConversationsByMatchIdOptimisticHandler(matchId, conversations);
            }),
          );
          dispatch(
            outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
              updateMessageAsErrorInConversationOptimisticHandler(tempMessageId, conversation);
            }),
          );
        }
      },
    }),
    archiveConversation: builder.mutation<void, ArchiveConversationByIdQueryArguments>({
      query: archiveConversationQuery,
      async onQueryStarted(
        { conversationId, positionId }, { dispatch, queryFulfilled },
      ) {
        const positionConversationsPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationsByPositionId', { positionId }, ({ conversations }) => {
            archiveConversationsByIdOptimisticHandler(conversations, conversationId);
          }),
        );
        const conversationPatchResult = dispatch(
          outreachQuery.util.updateQueryData('getConversationById', { conversationId, positionId }, (conversation) => {
            archiveConversationOptimisticHandler(conversation);
          }),
        );
        dispatch(resetConversationsSort());
        try {
          await queryFulfilled;
        } catch {
          positionConversationsPatchResult.undo();
          conversationPatchResult.undo();
        }
      },
    }),
  }),
});
