import { Avatar as MuiAvatar } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import log from 'loglevel';
import { useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import STRING_KEYS from '../../../../language/keys';
import { ReactComponent as LogoutIcon } from '../../../../assets/logout_icon.svg';
import { ReactComponent as SupportIcon } from '../../../../assets/support_chat.svg';
import { Divider } from '../../Divider';
import { useAnalyticsContext } from '../../../../contexts/analytics/useAnalyticsContext';
import { ANALYTICS_EVENTS, EVENT_GROUP } from '../../../../consts/analytics';
import { AppRouting } from '../../../../services/appRoutingResolver';
import { PricingPlanEnum } from '../../../../enums/user-plan.enum';
import {
  PerfectButtonHoverVariants,
  PerfectButtonVariants,
} from '../../Buttons/perfect-base-button/perfect-base-button.enums';
import { logout } from '../../../../store/slices/app-state/app-state.toolkit-slice';
import { useGetMyUserQuery } from '../../../../store/rtk-query/apis/user/hooks/get-my-user.query-hook';
import { useGetAccountByIdQuery } from '../../../../store/rtk-query/apis/account/hooks/get-account-by-id.query-hook';
import { useGetPositionsSelector } from '../../../../store/rtk-query/apis/position/selectors/get-positions.selector';
import { useDaysLeftPlan } from '../../../../hooks/days-left-plan.hook';
import { UpgradePlanDialog } from '../../../dialogs/upgrade-plan-dialog/upgrade-plan-dialog.component';
import UpgradeToScaleDialog
  from '../../../dialogs/info-dialogs/upgrade-to-scale-dialog/upgrade-to-scale-dialog.component';
import { IntercomManager } from '../../../../services/intercom-manager';
import { PerfectMenuSize } from '../../perfect-base-menu/perfect-base-menu.enums';
import BaseMenu from '../../perfect-base-menu/perfect-base-menu.component';
import {
  CurrentPlan,
  CurrentPlanLabel,
  CurrentPlanTypeLabel,
  UserDetailsContainer,
  CurrentUserFullName,
  CurrentUserEmail,
  MuiAvatarSX,
  GoToProButton,
  CurrentPlanDaysLeft,
  PlanDescriptionContainer,
  UserGroupIcon, StyledUserMenu,
} from './user-profile-menu.styled';
import UserMenuItem from './user-menu-item/user-menu-item.component';

const ANALYTICS_COMPONENT_NAME = 'log_out_menu';

const logger = log.getLogger('USER_PROFILE_MENU');

function UserProfileMenu({ onPeopleItemClickHandler, anchorElement, onCloseButtonClickHandler }: UserProfileMenuProps): JSX.Element {
  const [isUpgradeDialogOpen, setIsUpgradeDialogOpen] = useState(false);

  const {
    data: {
      firstName, profileImage, lastName, email, accountId,
    },
  } = useGetMyUserQuery();
  const { data: positionsList } = useGetPositionsSelector();
  const { t: translate } = useTranslation();
  const dispatch = useDispatch();
  const location = useLocation();
  const { positionId } = useParams<MatchUrlParams>();
  const { analyticsTrackEvent } = useAnalyticsContext();
  const fullName = `${firstName || ''} ${lastName || ''}`;
  const { data: { plan } } = useGetAccountByIdQuery(accountId);
  const differenceInDaysToEndPlanDate = useDaysLeftPlan();

  const currentPosition = positionsList.find((position) => position.id === positionId);

  const handleLogoutClick = () => {
    logger.debug('user clicked on logout button in his profile menu');

    // TODO figure out how to get talent id here and how to get match status here
    const trackEventPayload = {
      eventName: ANALYTICS_EVENTS.USER_LOGOUT_CLICKED,
      eventGroup: EVENT_GROUP.CLICK,
      pageName: AppRouting.getPageName(location.pathname)?.toString(),
      pagePositionId: positionId,
      pagePositionName: currentPosition?.jobTitle,
      pagePositionStatus: currentPosition?.status,
      componentName: ANALYTICS_COMPONENT_NAME,
    };
    analyticsTrackEvent(trackEventPayload);

    dispatch(logout());
  };

  const definePlan = () => {
    if (plan?.pricingPlan) {
      return translate(STRING_KEYS.PRICE_PLAN[plan.pricingPlan] || STRING_KEYS.UNKNOWN_PLAN);
    }

    return null;
  };

  const onUpgradeDialogClose = () => {
    setIsUpgradeDialogOpen(false);
  };

  const renderUpgradeDialog = () => {
    if (!isUpgradeDialogOpen) {
      return undefined;
    }

    if (plan.pricingPlan === PricingPlanEnum.TRIAL) {
      return (
        <UpgradePlanDialog isDialogOpen={ isUpgradeDialogOpen } onCloseDialog={ onUpgradeDialogClose } />
      );
    }

    return (
      <UpgradeToScaleDialog isDialogOpen={ isUpgradeDialogOpen } closeButtonHandler={ onUpgradeDialogClose } />
    );
  };

  const onUpgradeButtonClick = () => {
    setIsUpgradeDialogOpen(true);
  };

  return (
    <>
      <BaseMenu
        isOpen={ !!anchorElement }
        onClose={ onCloseButtonClickHandler }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
        size={ PerfectMenuSize.Large }
      >
        <StyledUserMenu>
          <UserDetailsContainer>
            <MuiAvatar
              alt={ `${firstName}` }
              src={ `${profileImage}` }
              sx={ MuiAvatarSX }
            />
            <div>
              {fullName && (
                <CurrentUserFullName>
                  {fullName}
                </CurrentUserFullName>
              )}
              {email && (
                <CurrentUserEmail>
                  { email }
                </CurrentUserEmail>
              )}
            </div>
          </UserDetailsContainer>
          <CurrentPlan pricingPlan={ plan?.pricingPlan }>
            <div>
              <CurrentPlanLabel>
                { translate(STRING_KEYS.CURRENT_PRODUCT_PLAN)}
              </CurrentPlanLabel>
              <PlanDescriptionContainer>
                <CurrentPlanTypeLabel>
                  { definePlan() }
                </CurrentPlanTypeLabel>
                { plan?.pricingPlan === PricingPlanEnum.TRIAL && (
                  <CurrentPlanDaysLeft>
                    {
                      translate(
                        differenceInDaysToEndPlanDate === 1 ?
                          STRING_KEYS.DAYS_LEFT_ONE : STRING_KEYS.DAYS_LEFT_PLURAL,
                        { count: differenceInDaysToEndPlanDate })
                    }
                  </CurrentPlanDaysLeft>
                ) }
              </PlanDescriptionContainer>
            </div>
            <GoToProButton
              onClick={ onUpgradeButtonClick }
              variant={ PerfectButtonVariants.Ghost }
              hoverVariant={ PerfectButtonHoverVariants.Darkened }
            >
              { translate(STRING_KEYS.UPGRADE) }
            </GoToProButton>
          </CurrentPlan>
          <Divider />
          <UserMenuItem
            title={ translate(STRING_KEYS.MAIN_MENU.ITEMS.PEOPLE) }
            onClick={ onPeopleItemClickHandler }
            Icon={ UserGroupIcon }
          />
          <UserMenuItem
            title={ translate(STRING_KEYS.SUPPORT) }
            onClick={ IntercomManager.showChat }
            Icon={ SupportIcon }
          />
          <UserMenuItem
            title={ translate(STRING_KEYS.LOGOUT) }
            onClick={ handleLogoutClick }
            Icon={ LogoutIcon }
          />
        </StyledUserMenu>
      </BaseMenu>
      { renderUpgradeDialog() }
    </>
  );
}

export default UserProfileMenu;
