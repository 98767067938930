import { CaseReducer } from '@reduxjs/toolkit';
import { AppState } from '../app-state.initial-state';

export const socketTalentAcquisitionConnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.talentAcquisition.connected = true;
};

export const socketTalentAcquisitionDisconnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.talentAcquisition.connected = false;
};

export const socketOutreachConnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.outreach.connected = true;
};

export const socketOutreachDisconnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.outreach.connected = false;
};

export const socketUserConnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.user.connected = true;
};

export const socketUserDisconnectedReducer: CaseReducer<AppState> = (state) => {
  state.sockets.user.connected = false;
};
