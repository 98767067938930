import { MenuItemSelectedVariant } from '../../../../Common/perfect-base-menu/menu-item/menu-item.enum';
import { Description, StyledMenuItem, Title } from './role-menu-item.styled';
import { RoleMenuItemProps } from './role-menu-item';

export const RoleMenuItem = ({
  Icon,
  title,
  description,
  onClick,
}: RoleMenuItemProps): JSX.Element => {
  return (
    <StyledMenuItem
      selectedVariant={ MenuItemSelectedVariant.Icon }
      onClick={ onClick }
    >
      <div>{ Icon }</div>
      <div>
        <Title>{ title }</Title>
        <Description>{ description }</Description>
      </div>
    </StyledMenuItem>
  );
};
