import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MenuItemSelectedVariant } from '../../../../../../../components/Common/perfect-base-menu/menu-item/menu-item.enum';
import STRING_KEYS from '../../../../../../../language/keys';
import {
  ArrowDownIcon,
  Container,
  Text,
  Menu,
  StyledMenuItem,
  MenuItemDescription,
  MenuItemTitle,
  MenuItemWrapper,
  MenuTitle,
} from './relevance-menu-button.styled';
/* eslint-disable react/jsx-props-no-spreading */
const RelevanceMenuButton = (props: RelevanceMenuButtonProps): JSX.Element => {
  const {
    isRelevantLevelsOfExperience,
    relevanceTypeChangeHandler,
    children,
    ...otherProps
  } = props;
  const { t: translate } = useTranslation();
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const onRelevanceTypeChange = (isRelevantLevelsOfExperienceOption: boolean) => {
    relevanceTypeChangeHandler(isRelevantLevelsOfExperienceOption);
    setIsMenuOpen(false);
  };

  const renderMenuItem = (titleKey: string, descriptionKey: string, isRelevantLevelsOfExperienceOption: boolean) => {
    return (
      <StyledMenuItem
        onClick={ () => onRelevanceTypeChange(isRelevantLevelsOfExperienceOption) }
        selected={ isRelevantLevelsOfExperience === isRelevantLevelsOfExperienceOption }
        selectedVariant={ MenuItemSelectedVariant.Icon }
      >
        <MenuItemWrapper>
          <MenuItemTitle>{ translate(titleKey) }</MenuItemTitle>
          <MenuItemDescription>
            { translate(descriptionKey) }
          </MenuItemDescription>
        </MenuItemWrapper>
      </StyledMenuItem>
    );
  };

  return (
    <>
      <Container
        ref={ anchorElementRef }
        onClick={ () => setIsMenuOpen(!isMenuOpen) }
        { ...otherProps }
      >
        <Text isMenuOpen={ isMenuOpen }>
          { children }
        </Text>
        <ArrowDownIcon />
      </Container>
      <Menu
        isOpen={ isMenuOpen }
        onClose={ () => setIsMenuOpen(false) }
        placement="bottom-start"
        anchorElement={ anchorElementRef.current || undefined }
        Title={ <MenuTitle>{ translate(STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.MENU_TITLE) }</MenuTitle> }
      >
        { renderMenuItem(
          STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.RELEVANT_TITLE,
          STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.RELEVANT_DESCRIPTION,
          true) }
        { renderMenuItem(
          STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.OVERALL_TITLE,
          STRING_KEYS.MATCH_TUNE.LEVEL_OF_EXPERIENCE_STEP.RELEVANCE_MENU.OVERALL_DESCRIPTION,
          false) }
      </Menu>
    </>
  );
};

export default RelevanceMenuButton;
