import styled, { css } from 'styled-components';
import { FLUENT_UI_EMOJIS } from '../../../../assets';
import { THEME } from '../../../../themes';
import { CaptionFont, HeadlineFont } from '../../../../themes/fonts';

export const IconCss = css`
    width: 24px;
    height: 24px;
`;

export const MenuHeader = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${THEME.SPACING.TINO};
    padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM};
`;

export const Title = styled.div`
    ${HeadlineFont};
    letter-spacing: 0.15px;
    font-weight: 500;
`;

export const Description = styled.div`
    ${CaptionFont};
    line-height: 1.33;
    letter-spacing: 0.4px;
    opacity: ${THEME.OPACITY.MED};
`;

export const AdminIcon = styled(FLUENT_UI_EMOJIS.CROWN)`
    ${IconCss};
`;

export const MemberIcon = styled(FLUENT_UI_EMOJIS.CARD_INDEX_DIVIDERS)`
    ${IconCss};
`;

export const ManagerIcon = styled(FLUENT_UI_EMOJIS.EYES)`
    ${IconCss};
`;
