import {
  MouseEvent, useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import AvatarsList from '../AvatarsList';
import { useGetAccountUsersQuery } from '../../../store/rtk-query/apis/user/hooks/get-account-users.query-hook';
import { PerfectMenuSize } from '../perfect-base-menu/perfect-base-menu.enums';
import { UserResponse } from '../../../store/rtk-query/apis/user/dto/response/invited-users.response';
import { useMyUserSelector } from '../../../store/rtk-query/apis/user/selectors/get-my-user.selector';
import { THEME } from '../../../themes';
import STRING_KEYS from '../../../language/keys';
import useDebounce from '../../../hooks/debounce';
import UserMenuItem from '../user-menu-item/user-menu-item.component';
import { usePermissionCheck } from '../../../hooks/use-permissions.hook';
import { PERMISSIONS } from '../../../consts/permissions.const';
import {
  AddUserIcon,
  AddUserIconWrapper,
  AssignIcon,
  AVATAR_SIZE_PX,
  Container,
  EmptyStateContainer,
  InviteTeammateMenuItem,
  InviteTeammateMenuItemIcon,
  Label,
  MenuTitle,
  MultipleUsersContainer,
  StyledMenuItem,
  UsersMenu,
} from './user-picker.styled';

const CHANGE_DEBOUNCE_MS = 1000;

const UserPicker = ({
  label,
  menuTitle,
  selectedUsers,
  changeHandler,
  maxDisplayAvatars,
  showAddUserIcon,
  openInviteUsersModalHandler,
}: UserPickerProps): JSX.Element => {
  const [anchorElement, setAnchorElement] = useState<HTMLDivElement | null>(null);
  const anchorElementRef = useRef<HTMLDivElement>(null);
  const { data: users, isLoading: isGetUsersLoading, isSuccess } = useGetAccountUsersQuery();
  const { data: currentUser } = useMyUserSelector();
  const { t: translate } = useTranslation();
  const [internalSelectedUsers, setInternalSelectedUsers] = useState(selectedUsers);
  const [isTouched, setIsTouched] = useState(false);
  const debouncedSelectedUsers = useDebounce(internalSelectedUsers, CHANGE_DEBOUNCE_MS);
  const { allowed: allowedToAssign } = usePermissionCheck([PERMISSIONS.position.assignAll, PERMISSIONS.position.assignAssociated]);
  const { allowed: allowedToUnAssign } = usePermissionCheck([PERMISSIONS.position.unassignAll, PERMISSIONS.position.unassignAssociated]);
  const { allowed: allowedToInvite } = usePermissionCheck([PERMISSIONS.user.inviteCollaborator, PERMISSIONS.user.inviteAll]);

  const selectedUsersSet = useMemo(() => new Set(internalSelectedUsers), [internalSelectedUsers]);
  const usersMapById: Map<string, UserResponse> = useMemo(() => {
    if (!isSuccess) {
      return new Map();
    }

    return new Map(users.map((user) => [user.id, user]));
    // TODO temporary fix, should discus with Din
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [users.length, isSuccess]);

  // Detect changes in selection and calculate toAdd and toRemove
  useEffect(() => {
    if (isTouched) {
      setIsTouched(false);
      const toAdd = debouncedSelectedUsers.filter((id) => !selectedUsers.includes(id));
      const toRemove = selectedUsers.filter((id) => {
        return !debouncedSelectedUsers.includes(id) && usersMapById.has(id);
      });
      if (toAdd.length || toRemove.length) {
        changeHandler(toAdd, toRemove);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedSelectedUsers]);

  useEffect(() => {
    const filteredUsers = selectedUsers?.filter((id) => usersMapById?.has(id));
    setInternalSelectedUsers(filteredUsers);
  }, [selectedUsers, usersMapById]);

  const renderEmptyState = () => {
    return (
      <EmptyStateContainer isActive={ !!anchorElement } >
        <AssignIcon />
        { translate(STRING_KEYS.USER_PICKER.ASSIGN) }
      </EmptyStateContainer>
    );
  };

  const avatarListIcons = useMemo(() => {
    return internalSelectedUsers.filter((userId) => usersMapById.has(userId)).map((userId) => {
      const user = usersMapById.get(userId);

      return {
        userName: user?.fullName || user?.email || '',
        profileImage: user?.profilePictureUrl,
      };
    });
  }, [usersMapById, internalSelectedUsers]);

  const renderAvatars = () => {
    return (
      <MultipleUsersContainer>
        <AvatarsList
          maxNumberInGroup={ maxDisplayAvatars || 11 }
          iconSize={ AVATAR_SIZE_PX }
          iconBorderWidthPx={ 0 }
          iconOutlineWidthPx={ 3 }
          spacing={ 8 }
          iconOutlineColor={ THEME.COLORS.LIGHT_GREY }
          icons={ avatarListIcons }
        />
        { showAddUserIcon && (
          <AddUserIconWrapper>
            <AddUserIcon />
          </AddUserIconWrapper>
        ) }
      </MultipleUsersContainer>
    );
  };

  const renderContent = () => {
    if (!internalSelectedUsers.length) {
      return renderEmptyState();
    }

    return renderAvatars();
  };

  const handleClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      event.stopPropagation();
      anchorElement ? setAnchorElement(null) : setAnchorElement(anchorElementRef?.current);
    },
    [anchorElement]);

  const handleMenuClose = () => setAnchorElement(null);

  const onMenuItemClick = (userId: string) => {
    const checked = selectedUsersSet.has(userId);

    let selectedUsersArray = Array.from(selectedUsersSet);

    if (checked) {
      selectedUsersArray = selectedUsersArray.filter((selectedUserId: string) => selectedUserId !== userId);
    } else {
      selectedUsersArray.push(userId);
    }

    setInternalSelectedUsers(selectedUsersArray);
    setIsTouched(true);
  };

  const onInviteTeammateButtonClick = () => {
    openInviteUsersModalHandler?.();
    setAnchorElement(null);
  };

  const renderMenuItems = (): JSX.Element | undefined => {
    if (isGetUsersLoading) {
      return undefined;
    }

    return (
      <>
        { users.map((user) => {
          const checked = selectedUsersSet.has(user.id);
          const disabled = checked ? !allowedToUnAssign : !allowedToAssign;

          return (
            <StyledMenuItem
              key={ user.id }
              disabled={ disabled }
            >
              <UserMenuItem
                user={ user }
                showCheckbox
                checked={ checked }
                isCurrentUser={ currentUser.id === user.id }
                clickHandler={ () => onMenuItemClick(user.id) }
                subtitleOptions={ { showUserVerifiedIndication: true } }
                disabled={ disabled }
              />
            </StyledMenuItem>
          );
        })}
        <InviteTeammateMenuItem onClick={ onInviteTeammateButtonClick } disabled={ !allowedToInvite }>
          <InviteTeammateMenuItemIcon />
          <div>{ translate(STRING_KEYS.USER_PICKER.INVITE_TEAMMATE_TEXT) }</div>
        </InviteTeammateMenuItem>
      </>
    );
  };

  return (
    <>
      <Container ref={ anchorElementRef } onClick={ (event) => handleClick(event) }>
        <Label>{ label }</Label>
        { renderContent() }
      </Container>
      <UsersMenu
        isOpen={ !!anchorElement }
        anchorElement={ anchorElement || undefined }
        placement="bottom-start"
        onClose={ handleMenuClose }
        Title={ <MenuTitle>{ menuTitle }</MenuTitle> }
        size={ PerfectMenuSize.Large }
      >
        { renderMenuItems() }
      </UsersMenu>
    </>
  );
};

export default UserPicker;
