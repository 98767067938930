import styled from 'styled-components';
import MenuItem from '../../../../Common/perfect-base-menu/menu-item/menu-item.component';
import { THEME } from '../../../../../themes';
import { hexToRgbA } from '../../../../../utils/colors';
import { Body1Font, CaptionFont } from '../../../../../themes/fonts';

export const StyledMenuItem = styled(MenuItem)`
  display: flex;
  padding: ${THEME.SPACING.TINO} ${THEME.SPACING.MEDIUM};
  height: unset;
  max-width: unset;
  gap: ${THEME.SPACING.BASE};
  :hover,
  :focus {
    background-color: ${hexToRgbA(THEME.COLORS.ACCENT, THEME.OPACITY.DIVIDER)};
    color: inherit;
  }
`;

export const Title = styled.div`
  ${Body1Font};
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: 0.44px;
`;

export const Description = styled.div`
  ${CaptionFont};
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.4px;
  color: ${hexToRgbA(THEME.COLORS.CONTENT_A, THEME.OPACITY.MED)};
`;
